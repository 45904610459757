import { Delete, Download, Upload } from '@mui/icons-material'
import {
  Box,
  Button,
  Checkbox,
  Chip,
  DialogActions,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import * as api from 'api'
import {
  DecimalFormat,
  DuplicateQuoteDialog,
  IntFormat,
  PrintPreviousQuoteDialog,
  UnsavedChangesPrompt
} from 'components'
import { Layout } from 'components/_template'
import { ExplanationAccordion } from 'components/_template/accordion'
import {
  Details,
  DetailsForm,
  DetailsHeaderCard,
  DetailsTab
} from 'components/_template/details'
import { showFormErrorsPrompt } from 'components/_template/form/FormErrorsPrompt'
import { useAuthContext } from 'context'
import {
  QuoteAssemblyUnitTypeDto,
  QuoteAssemblyJointDto,
  QuoteAssemblyStyleDto,
  QuoteDto,
  RatesAssemblyAdderDto,
  RatesBlendAdderDto,
  RatesInkDto,
  RatesPaperAdderDto,
  RatesPaperDto,
  RatesScoringAdderDto,
  quoteValidationSchema
} from 'dtos'
import {
  QuoteAssemblyAdderDto,
  QuoteBlendAdderDto,
  QuoteLineItemDto,
  QuotePaperAdderDto,
  QuoteScoringAdderDto
} from 'dtos/quote'
import { QuoteLineItemDesignFileDto } from 'dtos/quoteLineItemDesignFile'
import { Formik, getIn, setNestedObjectValues } from 'formik'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { ReportQuote } from 'pages'
import React, { useEffect, useRef, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { useNavigate, useParams } from 'react-router-dom'
import { format, formats } from 'theme'
import { onlyUnique } from 'utils'
import { toUniqueArrayByKey, unique } from 'utils/onlyUnique'
import { errorHandling } from '../constants'

const DIRTY_UNEDITABLE_MESSAGE = 'Quote cannot be duplicated with unsaved values'
const DUPLICATE_PERMISSION_UNEDITABLE_MESSAGE =
  'Must have "Can duplicate quote" permission'
const EDIT_PERMISSION_UNEDITABLE_MESSAGE = 'Must have "Opportunities add/edit" permission'
const OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE =
  'Quote cannot be edited once opportunity is sold'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

// This method is also used in the Purchase Order Report
export const getPaperLabel = (orderMsf: number) => {
  if (orderMsf > 0 && orderMsf < 5) {
    return 'Msf>0<5'
  } else if (orderMsf >= 5 && orderMsf < 10) {
    return 'Msf>=5<10'
  } else if (orderMsf >= 10 && orderMsf < 15) {
    return 'Msf>=10<15'
  } else if (orderMsf >= 15 && orderMsf < 30) {
    return 'Msf>=15<30'
  } else if (orderMsf >= 30 && orderMsf < 45) {
    return 'Msf>=30<45'
  } else if (orderMsf >= 45 && orderMsf < 60) {
    return 'Msf>=45<60'
  } else if (orderMsf >= 60) {
    return 'Msf>=60'
  } else {
    return ''
  }
}

export default function QuoteDetails() {
  // #region hooks
  const { enqueueSnackbar } = useSnackbar()
  const { id } = useParams()
  const navigate = useNavigate()
  const { CAN_DUPLICATE_QUOTE, OPPORTUNITIES_ADD_EDIT, OPPORTUNITIES_READ_ONLY } =
    useAuthContext()
  // #endregion

  useEffect(() => {
    api
      .getQuoteAssemblyUnitTypes({ page: 0, pageSize: 999 })
      .then(({ value }) => {
        setQuoteAssemblyUnitTypes(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })

    api
      .getQuoteAssemblyJoints({ page: 0, pageSize: 999 })
      .then(({ value }) => {
        setQuoteAssemblyJoints(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })

    api
      .getQuoteAssemblyStyles({ page: 0, pageSize: 999 })
      .then(({ value }) => {
        setQuoteAssemblyStyles(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })

    api
      .getRatesAssemblyAdders({ page: 0, pageSize: 999, includeInactive: false })
      .then(({ value }) => {
        setRatesAssemblyAdders(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })

    api
      .getRatesBlendAdders({ page: 0, pageSize: 999, includeInactive: false })
      .then(({ value }) => {
        setRatesBlendAdders(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })

    api
      .getRatesInks({ page: 0, pageSize: 999, includeInactive: false })
      .then(({ value }) => {
        setRatesInks(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })

    api
      .getRatesPaper({ page: 0, pageSize: 999, includeInactive: false })
      .then(({ value }) => {
        setRatesPaper(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })

    api
      .getRatesPaperAdders({ page: 0, pageSize: 999, includeInactive: false })
      .then(({ value }) => {
        setRatesPaperAdders(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })

    api
      .getRatesScoringAdders({ page: 0, pageSize: 999, includeInactive: false })
      .then(({ value }) => {
        setRatesScoringAdders(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
  }, [])

  useEffect(() => {
    api
      .getQuoteById(id!)
      .then(({ value }) => {
        setInitialValues(value)

        // Once multiple quote line items is supported, this can be removed.
        if (!value.quoteRevision?.quoteLineItem) {
          setInitialValues({
            ...value,
            quoteRevision: {
              ...value.quoteRevision,
              // Because of the way the backend creates new quotes/revisions, which is creating a new revision with no line items, this has to be here.
              //
              // Updated quotationRefNumber for Release 5 on 8/14/2024
              // Maxwell Sommer 8/14/2024
              quoteLineItem: {
                ...new QuoteLineItemDto(),
                quotationRefNumber: value.readonly_displayOpportunityNumber,
                dieCut: 'No Die Cut'
              }
            }
          })
        }
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {})
  }, [id])

  // #region useState
  const [initialValues, setInitialValues] = useState<QuoteDto>(new QuoteDto())
  const [duplicateQuoteDialogOpen, setDuplicateQuoteDialogOpen] = useState<boolean>(false)
  const [printPreviousQuoteDialogOpen, setPrintPreviousQuoteDialogOpen] =
    useState<boolean>(false)
  const [quoteAssemblyUnitTypes, setQuoteAssemblyUnitTypes] = useState<
    QuoteAssemblyUnitTypeDto[]
  >([])
  const [quoteAssemblyJoints, setQuoteAssemblyJoints] = useState<QuoteAssemblyJointDto[]>(
    []
  )
  const [quoteAssemblyStyles, setQuoteAssemblyStyles] = useState<QuoteAssemblyStyleDto[]>(
    []
  )
  const [ratesAssemblyAdders, setRatesAssemblyAdders] = useState<RatesAssemblyAdderDto[]>(
    []
  )
  const [ratesBlendAdders, setRatesBlendAdders] = useState<RatesBlendAdderDto[]>([])
  const [ratesInks, setRatesInks] = useState<RatesInkDto[]>([])
  const [ratesPaper, setRatesPaper] = useState<RatesPaperDto[]>([])
  const [ratesPaperAdders, setRatesPaperAdders] = useState<RatesPaperAdderDto[]>([])

  const [ratesScoringAdders, setRatesScoringAdders] = useState<RatesScoringAdderDto[]>([])

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [dieCutHelpfulText, setDieCutHelpfulText] = useState<string>('')

  const fileUploadInputRef = useRef()

  const onPrintQuote = (quoteId: string, revisionNumber?: number) => {
    setIsLoading(true)
    api
      .getQuoteReportById(quoteId, revisionNumber)
      .then(response => {
        const newWindow = window.open('', '_blank', 'width=800,height=600')

        if (newWindow) {
          newWindow.document.body.innerHTML =
            '<div id="print-quote-report-container"></div>'

          const root = createRoot(
            newWindow.document.getElementById(
              'print-quote-report-container'
            ) as HTMLElement
          )

          root.render(<ReportQuote reportQuoteDto={response.value} />)
        } else {
          console.error('Failed to open a new window.')
        }
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsLoading(false)
        setPrintPreviousQuoteDialogOpen(false)
      })
  }

  useEffect(() => {
    setDieCutHelpfulText('')
  }, [initialValues])

  return (
    <Layout
      title='Quote Details'
      // Alphabetize this list of loading properties
      isLoading={isLoading}
    >
      <DuplicateQuoteDialog
        open={duplicateQuoteDialogOpen}
        onClose={() => {
          setDuplicateQuoteDialogOpen(false)
        }}
        onSave={(values, setSubmitting) => {
          if (
            values.opportunityToUse == 'currentOpportunity' &&
            window.confirm(
              'Would you like to duplicate this revision to the current opportunity? Please note that only the most recent revision will be editable, and any previous revision(s) will become read-only.'
            )
          ) {
            setIsLoading(true)
            api
              .duplicateQuoteRevision(values)
              .then(response => {
                enqueueSnackbar(
                  'Revision duplicated to the current opportunity successfully!',
                  {
                    variant: 'success'
                  }
                )
                setDuplicateQuoteDialogOpen(false)
                navigate(0) // Because the quote id doesn't change, just reload the page to get the most recent values
              })
              .catch((errors: string[]) => {
                errors?.forEach(error => {
                  enqueueSnackbar(error, {
                    variant: 'error'
                  })
                })
              })
              .finally(() => {
                setIsLoading(false)
                setSubmitting(false)
              })
          } else if (
            values.opportunityToUse == 'newOpportunity' &&
            window.confirm(
              'Would you like to duplicate this revision to a new opportunity? Please note that this will not duplicate any previous revision(s).'
            )
          ) {
            setIsLoading(true)
            api
              .duplicateQuoteRevision(values)
              .then(response => {
                enqueueSnackbar(
                  'Revision duplicated to a new opportunity successfully!',
                  {
                    variant: 'success'
                  }
                )
                setDuplicateQuoteDialogOpen(false)
                navigate('/quotes/' + response.value)
              })
              .catch((errors: string[]) => {
                errors?.forEach(error => {
                  enqueueSnackbar(error, {
                    variant: 'error'
                  })
                })
              })
              .finally(() => {
                setIsLoading(false)
                setSubmitting(false)
              })
          } else {
            setSubmitting(false) // If the user clicks "CANCEL" in the confirmation dialog, need to re-enable the form
          }
        }}
        quote={initialValues}
      />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnChange
        validateOnBlur
        validationSchema={quoteValidationSchema}
        onSubmit={(values, formikHelpers) => {
          setIsLoading(true)
          api
            .updateQuote(values)
            .then(({ value }) => {
              enqueueSnackbar('Quote has been saved!', { variant: 'success' })
              // If the quote returned from the update request contains a quoteEndCostAddernot included in the initalValues (compared by id), warn the user that a new end cost was added
              if (
                value.quoteRevision?.quoteLineItem?.quoteEndCostAdders
                  ?.map(updatedEndCostAdder => updatedEndCostAdder.id)
                  .some(
                    updatedEndCostAdderId =>
                      !initialValues.quoteRevision?.quoteLineItem?.quoteEndCostAdders
                        ?.map(originalEndCostAdder => originalEndCostAdder.id)
                        .includes(updatedEndCostAdderId)
                  )
              ) {
                enqueueSnackbar('End Costs Added.', { variant: 'info' })
              }
              setInitialValues(value)
              formikHelpers.resetForm({ values: value })
            })
            .catch((errors: string[]) => {
              errorHandling(errors)
            })
            .finally(() => {
              formikHelpers.setSubmitting(false)
              setIsLoading(false)
            })
        }}
      >
        {({
          dirty,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setErrors,
          setFieldError,
          setFieldTouched,
          setFieldValue,
          setTouched,
          submitForm,
          touched,
          validateForm,
          values
        }) => {
          const clearWhenDirty = (value: any) => {
            return dirty ? 'TBD' : value
          }

          return (
            <>
              <PrintPreviousQuoteDialog
                open={printPreviousQuoteDialogOpen}
                onClose={() => {
                  setPrintPreviousQuoteDialogOpen(false)
                }}
                onPrint={onPrintQuote}
                quote={values}
              />

              <Details
                header={
                  <DetailsHeaderCard
                    title={`Quote Details #${
                      initialValues.readonly_displayOpportunityNumber ?? ''
                    }`}
                  />
                }
                tabs={[{ value: 'quote-builder', label: 'Quote Builder' }]}
                onSubmit={handleSubmit}
              >
                <DetailsTab value='quote-builder'>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <DetailsForm>
                        <Grid container spacing={2}>
                          <Grid item xs={12} container justifyContent='space-between'>
                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#2780E3', fontWeight: 600 }}
                              >
                                Order Identification
                              </Typography>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              sm={8}
                              container
                              spacing={1}
                              justifyContent='flex-end'
                            >
                              <Grid item>
                                <Tooltip
                                  title={
                                    values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                      ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                      : OPPORTUNITIES_ADD_EDIT
                                      ? ''
                                      : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                                  }
                                  placement='bottom'
                                >
                                  <span>
                                    <Button
                                      // disabled={
                                      //   isSubmitting ||
                                      //   !OPPORTUNITIES_ADD_EDIT ||
                                      //   values.opportunityLastStatus
                                      //     ?.specialIdentifier ===
                                      //     'OPPORTUNITY_STATUS_SOLD'
                                      // }
                                      variant='outlined'
                                      onClick={() => {
                                        onPrintQuote(
                                          values.id!,
                                          values.quoteRevision?.revisionNumber
                                        )
                                      }}
                                    >
                                      PRINT CURRENT QUOTE
                                    </Button>
                                  </span>
                                </Tooltip>
                              </Grid>

                              <Grid item>
                                <Tooltip
                                  title={
                                    values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                      ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                      : OPPORTUNITIES_ADD_EDIT
                                      ? ''
                                      : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                                  }
                                  placement='bottom'
                                >
                                  <span>
                                    <Button
                                      disabled={
                                        isSubmitting ||
                                        (values.quoteRevision?.revisionNumber ?? 0) <=
                                          1 ||
                                        !OPPORTUNITIES_ADD_EDIT ||
                                        values.opportunityLastStatus
                                          ?.specialIdentifier ===
                                          'OPPORTUNITY_STATUS_SOLD'
                                      }
                                      variant='outlined'
                                      onClick={() => {
                                        setPrintPreviousQuoteDialogOpen(true)
                                      }}
                                    >
                                      PRINT A PREVIOUS QUOTE
                                    </Button>
                                  </span>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  touched.rfqReceivedDate && errors.rfqReceivedDate
                                )}
                                fullWidth
                                helperText={
                                  touched.rfqReceivedDate && errors.rfqReceivedDate
                                }
                                InputLabelProps={{ shrink: true }}
                                label='RFQ Received Date'
                                name='rfqReceivedDate'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type='date'
                                value={
                                  values.rfqReceivedDate
                                    ? moment
                                        .utc(values.rfqReceivedDate)
                                        .format(formats.dateOnlyField)
                                    : ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12}>
                            <ExplanationAccordion>
                              Print current quote will pull up this quote so the user can
                              print the quote and/or save it to PDF.||Print a previous
                              quote opens a dialog where the user can select which
                              revision they would like to print so the user can print the
                              quote and/or save it to PDF.||If the current/highest quote
                              revision # is Revision #1, the "Print A Previous Quote"
                              button is disabled.||When printing previous quotes there
                              will be a watermark covering the page stating, "Voided
                              quote".||All fields on this card are required.
                            </ExplanationAccordion>
                          </Grid>
                        </Grid>
                      </DetailsForm>
                    </Grid>

                    <Grid item xs={12}>
                      <DetailsForm>
                        <Grid container spacing={2}>
                          <Grid item container justifyContent='space-between'>
                            <Grid item xs={12} sm={6}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#2780E3', fontWeight: 600 }}
                              >
                                Revision Information
                              </Typography>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              sm={6}
                              container
                              spacing={1}
                              justifyContent='flex-end'
                            >
                              <Grid item>
                                <Tooltip
                                  title={
                                    values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                      ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                      : OPPORTUNITIES_ADD_EDIT
                                      ? ''
                                      : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                                  }
                                  placement='bottom'
                                >
                                  <span>
                                    <Button
                                      disabled={
                                        isSubmitting ||
                                        dirty ||
                                        !OPPORTUNITIES_ADD_EDIT ||
                                        values.opportunityLastStatus
                                          ?.specialIdentifier ===
                                          'OPPORTUNITY_STATUS_SOLD'
                                      }
                                      variant='outlined'
                                      onClick={() => {
                                        if (
                                          window.confirm(
                                            'Would you like to create a new revision? Please note that only the most recent revision will be editable, and any previous revision(s) will become read-only.'
                                          )
                                        ) {
                                          setIsLoading(true)
                                          api
                                            .createNewQuoteRevision(values)
                                            .then(response => {
                                              enqueueSnackbar(
                                                'New revision created successfully!',
                                                { variant: 'success' }
                                              )
                                              setInitialValues(response.value)
                                            })
                                            .catch((errors: string[]) => {
                                              errors?.forEach(error => {
                                                enqueueSnackbar(error, {
                                                  variant: 'error'
                                                })
                                              })
                                            })
                                            .finally(() => {
                                              setIsLoading(false)
                                            })
                                        }
                                      }}
                                    >
                                      NEW QUOTE REV
                                    </Button>
                                  </span>
                                </Tooltip>
                              </Grid>

                              <Grid item>
                                <Tooltip
                                  title={
                                    !CAN_DUPLICATE_QUOTE
                                      ? DUPLICATE_PERMISSION_UNEDITABLE_MESSAGE
                                      : dirty
                                      ? DIRTY_UNEDITABLE_MESSAGE
                                      : ''
                                  }
                                  placement='bottom'
                                >
                                  <span>
                                    <Button
                                      disabled={
                                        isSubmitting || dirty || !CAN_DUPLICATE_QUOTE
                                      }
                                      variant='outlined'
                                      onClick={() => {
                                        setDuplicateQuoteDialogOpen(true)
                                      }}
                                    >
                                      DUPLICATE QUOTE REV
                                    </Button>
                                  </span>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(touched.quoteRevision, 'revisionReason') &&
                                    getIn(errors.quoteRevision, 'revisionReason')
                                )}
                                fullWidth
                                helperText={
                                  getIn(touched.quoteRevision, 'revisionReason') &&
                                  getIn(errors.quoteRevision, 'revisionReason')
                                }
                                label='Revision Reason'
                                name='quoteRevision.revisionReason'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.quoteRevision?.revisionReason || ''}
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(touched.quoteRevision, 'quoteValidUntil') &&
                                    getIn(errors.quoteRevision, 'quoteValidUntil')
                                )}
                                fullWidth
                                helperText={
                                  getIn(touched.quoteRevision, 'quoteValidUntil') &&
                                  getIn(errors.quoteRevision, 'quoteValidUntil')
                                }
                                InputLabelProps={{ shrink: true }}
                                label='Quote Valid Until'
                                name='quoteRevision.quoteValidUntil'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type='date'
                                value={
                                  values?.quoteRevision?.quoteValidUntil
                                    ? moment
                                        .utc(values.quoteRevision?.quoteValidUntil)
                                        .format(formats.dateOnlyField)
                                    : ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(touched.quoteRevision, 'paymentTerms') &&
                                    getIn(errors.quoteRevision, 'paymentTerms')
                                )}
                                fullWidth
                                helperText={
                                  getIn(touched.quoteRevision, 'paymentTerms') &&
                                  getIn(errors.quoteRevision, 'paymentTerms')
                                }
                                label='Payment Terms'
                                name='quoteRevision.paymentTerms'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.quoteRevision?.paymentTerms || ''}
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(touched.quoteRevision, 'currency') &&
                                    getIn(errors.quoteRevision, 'currency')
                                )}
                                fullWidth
                                helperText={
                                  getIn(touched.quoteRevision, 'currency') &&
                                  getIn(errors.quoteRevision, 'currency')
                                }
                                label='Currency'
                                name='quoteRevision.currency'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.quoteRevision?.currency || ''}
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(touched.quoteRevision, 'methodOfShipment') &&
                                    getIn(errors.quoteRevision, 'methodOfShipment')
                                )}
                                fullWidth
                                helperText={
                                  getIn(touched.quoteRevision, 'methodOfShipment') &&
                                  getIn(errors.quoteRevision, 'methodOfShipment')
                                }
                                label='Method of Shipment'
                                name='quoteRevision.methodOfShipment'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.quoteRevision?.methodOfShipment || ''}
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(touched.quoteRevision, 'leadTimeForEachOrder') &&
                                    getIn(errors.quoteRevision, 'leadTimeForEachOrder')
                                )}
                                fullWidth
                                helperText={
                                  getIn(touched.quoteRevision, 'leadTimeForEachOrder') &&
                                  getIn(errors.quoteRevision, 'leadTimeForEachOrder')
                                }
                                label='Lead Time for Each Order'
                                name='quoteRevision.leadTimeForEachOrder'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.quoteRevision?.leadTimeForEachOrder || ''}
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12}>
                            <ExplanationAccordion>
                              The "New Quote Rev" and "Duplicate Quote Rev" buttons are
                              disabled if the Quote Builder form's calculated values are
                              TBD because something changed that hasn't been saved yet. ||
                              Pressing "New Quote Rev" ignores all data entered for this
                              revision on this card and all cards below and creates a new
                              revision and assigns a new revision number taking the
                              current revision number and adding 1 (i.e., 10050-2). || The
                              "Duplicate Quote Rev" button may only be pressed by users
                              with "Can duplicate quote" Feature Access. || Pressing
                              "Duplicate Quote Rev" opens the "Duplicate Quote Rev" dialog
                              where users may choose to either "Duplicate this quote rev
                              to current opportunity" or "Duplicate this quote rev to new
                              opportunity." || - Selecting "Duplicate this quote rev to
                              current opportunity" copies all the information including
                              all line items from this quote rev and assigns a new rev
                              number taking the current revision number and adding 1. || -
                              Selecting "Duplicate this quote rev to new opportunity"
                              creates a new Opportunity with the next available
                              Opportunity #. The Opportunity Details, Quote Details, and
                              Design Files (if uploaded) are duplicated to the new
                              Opportunity, but the Activity History is not. || All fields
                              on this card are required.
                            </ExplanationAccordion>
                          </Grid>
                        </Grid>
                      </DetailsForm>
                    </Grid>

                    {/* LINE ITEM CARD */}
                    {/* LINE ITEM CARD */}
                    {/* LINE ITEM CARD */}
                    <Grid item xs={12}>
                      <DetailsForm>
                        <Grid container spacing={2} justifyContent='space-between'>
                          <Grid item xs={12} sm={6}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#2780E3', fontWeight: 600 }}
                            >
                              Line Item Information
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            container
                            spacing={1}
                            justifyContent='flex-end'
                          >
                            <Grid item>
                              <input
                                accept='application/pdf'
                                // @ts-ignore
                                ref={fileUploadInputRef}
                                type='file'
                                hidden
                                onChange={e => {
                                  if (e.target.files && e.target.files.length > 0) {
                                    setFieldValue(
                                      'quoteRevision.quoteLineItem.quoteLineItemDesignFiles',
                                      [
                                        {
                                          ...new QuoteLineItemDesignFileDto(),
                                          fileName: e.target.files[0].name,
                                          fileUpload: e.target.files[0]
                                        }
                                      ]
                                    )
                                  }
                                }}
                              />

                              <Tooltip
                                title={
                                  values.opportunityLastStatus?.specialIdentifier ===
                                  'OPPORTUNITY_STATUS_SOLD'
                                    ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                    : OPPORTUNITIES_ADD_EDIT
                                    ? values?.quoteRevision?.quoteLineItem?.id ==
                                      undefined
                                      ? 'Design Files Can Only Be Added After The Quote Has Been Saved.'
                                      : ''
                                    : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                                }
                                placement='bottom'
                              >
                                <span>
                                  <Button
                                    disabled={
                                      isSubmitting ||
                                      !OPPORTUNITIES_ADD_EDIT ||
                                      values.opportunityLastStatus?.specialIdentifier ===
                                        'OPPORTUNITY_STATUS_SOLD' ||
                                      values?.quoteRevision?.quoteLineItem?.id ==
                                        undefined
                                    }
                                    sx={{ mr: 2 }}
                                    variant='outlined'
                                    onClick={() => {
                                      // @ts-ignore
                                      fileUploadInputRef.current.click()
                                    }}
                                  >
                                    UPLOAD DESIGN FILES
                                  </Button>
                                </span>
                              </Tooltip>
                            </Grid>

                            {values.quoteRevision?.quoteLineItem
                              ?.quoteLineItemDesignFiles &&
                              values.quoteRevision.quoteLineItem.quoteLineItemDesignFiles
                                .length > 0 && (
                                <Grid item container xs={12} justifyContent='flex-end'>
                                  <Grid item>
                                    {values.quoteRevision.quoteLineItem
                                      .quoteLineItemDesignFiles[0].id ? (
                                      <Link
                                        onClick={() => {
                                          api
                                            .downloadQuoteLineItemDesignFile(
                                              values.quoteRevision!.quoteLineItem!
                                                .quoteLineItemDesignFiles![0].fileName!
                                            )
                                            .catch((errors: string[]) => {
                                              errors?.forEach(error => {
                                                enqueueSnackbar(error, {
                                                  variant: 'error'
                                                })
                                              })
                                            })
                                            .finally(() => {})
                                        }}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          flexWrap: 'wrap',
                                          cursor: 'pointer'
                                        }}
                                      >
                                        <Download />

                                        <Typography variant='body2' display='inline'>
                                          {
                                            values.quoteRevision.quoteLineItem
                                              .quoteLineItemDesignFiles[0].fileName
                                          }
                                        </Typography>
                                      </Link>
                                    ) : (
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          flexWrap: 'wrap'
                                        }}
                                      >
                                        <Upload />

                                        <Typography variant='body2' display='inline'>
                                          {
                                            values.quoteRevision.quoteLineItem
                                              .quoteLineItemDesignFiles[0].fileName
                                          }
                                        </Typography>
                                      </div>
                                    )}
                                  </Grid>

                                  <Grid item>
                                    <IconButton
                                      size='small'
                                      onClick={() => {
                                        if (
                                          window.confirm(
                                            'Are you sure you want to delete ' +
                                              values.quoteRevision!.quoteLineItem!
                                                .quoteLineItemDesignFiles![0].fileName +
                                              '?'
                                          )
                                        )
                                          setFieldValue(
                                            'quoteRevision.quoteLineItem.quoteLineItemDesignFiles',
                                            []
                                          )
                                      }}
                                    >
                                      <Delete fontSize='small' />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              )}
                          </Grid>

                          <Grid item xs={12}>
                            <Typography
                              variant='body1'
                              color={
                                Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.specGeneratedOrProvidedBy'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.specGeneratedOrProvidedBy'
                                    )
                                )
                                  ? 'error'
                                  : undefined
                              }
                              sx={
                                Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.specGeneratedOrProvidedBy'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.specGeneratedOrProvidedBy'
                                    )
                                )
                                  ? undefined
                                  : {
                                      color: '#7F7F7F'
                                    }
                              }
                            >
                              <Tooltip
                                title={
                                  values.opportunityLastStatus?.specialIdentifier ===
                                  'OPPORTUNITY_STATUS_SOLD'
                                    ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                    : OPPORTUNITIES_ADD_EDIT
                                    ? ''
                                    : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                                }
                                placement='bottom'
                              >
                                <FormControl
                                  error={Boolean(
                                    getIn(
                                      touched,
                                      'quoteRevision.quoteLineItem.specGeneratedOrProvidedBy'
                                    ) &&
                                      getIn(
                                        errors,
                                        'quoteRevision.quoteLineItem.specGeneratedOrProvidedBy'
                                      )
                                  )}
                                >
                                  Spec Generated or Provided By
                                  <RadioGroup
                                    name='quoteRevision.quoteLineItem.specGeneratedOrProvidedBy'
                                    row
                                    value={
                                      values.quoteRevision?.quoteLineItem
                                        ?.specGeneratedOrProvidedBy
                                    }
                                  >
                                    <FormControlLabel
                                      disabled={
                                        isSubmitting ||
                                        !OPPORTUNITIES_ADD_EDIT ||
                                        values.opportunityLastStatus
                                          ?.specialIdentifier ===
                                          'OPPORTUNITY_STATUS_SOLD'
                                      }
                                      value='Spec Generated'
                                      onClick={e => {
                                        // These radio buttons set the 'specGeneratedOrProvidedBy' value to one of two hard-coded strings
                                        setFieldValue(
                                          'quoteRevision.quoteLineItem.specGeneratedOrProvidedBy',
                                          'Spec Generated'
                                        )
                                      }}
                                      control={
                                        <Radio
                                          disabled={
                                            isSubmitting ||
                                            !OPPORTUNITIES_ADD_EDIT ||
                                            values.opportunityLastStatus
                                              ?.specialIdentifier ===
                                              'OPPORTUNITY_STATUS_SOLD'
                                          }
                                        />
                                      }
                                      label='NorthGate / Internal'
                                    />
                                    <FormControlLabel
                                      disabled={
                                        isSubmitting ||
                                        !OPPORTUNITIES_ADD_EDIT ||
                                        values.opportunityLastStatus
                                          ?.specialIdentifier ===
                                          'OPPORTUNITY_STATUS_SOLD'
                                      }
                                      value='Provided By'
                                      onClick={e => {
                                        // These radio buttons set the 'specGeneratedOrProvidedBy' value to one of two hard-coded strings
                                        setFieldValue(
                                          'quoteRevision.quoteLineItem.specGeneratedOrProvidedBy',
                                          'Provided By'
                                        )
                                      }}
                                      control={<Radio />}
                                      label='Client / External'
                                    />
                                  </RadioGroup>
                                  <FormHelperText>
                                    {getIn(
                                      touched,
                                      'quoteRevision.quoteLineItem.specGeneratedOrProvidedBy'
                                    ) &&
                                      getIn(
                                        errors,
                                        'quoteRevision.quoteLineItem.specGeneratedOrProvidedBy'
                                      )}
                                  </FormHelperText>
                                </FormControl>
                              </Tooltip>
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.skuNumber'
                                  ) &&
                                    getIn(errors, 'quoteRevision.quoteLineItem.skuNumber')
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.skuNumber'
                                  ) &&
                                  getIn(errors, 'quoteRevision.quoteLineItem.skuNumber')
                                }
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position='start'>
                                      {values.readonly_company?.internalExternal
                                        ? 'MC'
                                        : 'JP'}
                                    </InputAdornment>
                                  )
                                }}
                                label='SKU#'
                                name='quoteRevision.quoteLineItem.skuNumber'
                                onBlur={handleBlur}
                                // This field is the only place that manages prepending input adornment, the back-end doesn't touch the prefix
                                onChange={e => {
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.skuNumber',
                                    (values.readonly_company?.internalExternal
                                      ? 'MC'
                                      : 'JP') + e.target.value
                                  )
                                }}
                                // The field value here needs to trim off the input adornment prefix since it is already shown and prepended in the input adornment itself
                                value={
                                  values.quoteRevision?.quoteLineItem?.skuNumber?.substring(
                                    2
                                  ) || ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.quotationRefNumber'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.quotationRefNumber'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.quotationRefNumber'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.quotationRefNumber'
                                  )
                                }
                                label='Quotation Ref #'
                                name='quoteRevision.quoteLineItem.quotationRefNumber'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={
                                  values.quoteRevision?.quoteLineItem
                                    ?.quotationRefNumber || ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.specDate'
                                  ) &&
                                    getIn(errors, 'quoteRevision.quoteLineItem.specDate')
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.specDate'
                                  ) &&
                                  getIn(errors, 'quoteRevision.quoteLineItem.specDate')
                                }
                                InputLabelProps={{ shrink: true }}
                                label='Spec Date'
                                name='quoteRevision.quoteLineItem.specDate'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type='date'
                                value={
                                  values?.quoteRevision?.quoteLineItem?.specDate
                                    ? moment
                                        .utc(values.quoteRevision.quoteLineItem.specDate)
                                        .format(formats.dateOnlyField)
                                    : ''
                                }
                              />
                            </Tooltip>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.customerPartNumber'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.customerPartNumber'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.customerPartNumber'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.customerPartNumber'
                                  )
                                }
                                label='Customer Part Number'
                                name='quoteRevision.quoteLineItem.customerPartNumber'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={
                                  values?.quoteRevision?.quoteLineItem
                                    ?.customerPartNumber || ''
                                }
                              />
                            </Tooltip>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.testRunQuantity'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.testRunQuantity'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.testRunQuantity'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.testRunQuantity'
                                  )
                                }
                                label='Test Run Quantity (Pieces)'
                                name='quoteRevision.quoteLineItem.testRunQuantity'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: IntFormat as any
                                }}
                                value={
                                  values.quoteRevision?.quoteLineItem?.testRunQuantity +
                                  ''
                                }
                              />
                            </Tooltip>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.minimumOrderRunQuantity'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.minimumOrderRunQuantity'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.minimumOrderRunQuantity'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.minimumOrderRunQuantity'
                                  )
                                }
                                label='Minimum Order Run Quantity (Pieces)'
                                name='quoteRevision.quoteLineItem.minimumOrderRunQuantity'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: IntFormat as any
                                }}
                                value={
                                  values?.quoteRevision?.quoteLineItem
                                    ?.minimumOrderRunQuantity || ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.committedQuantity'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.committedQuantity'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.committedQuantity'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.committedQuantity'
                                  )
                                }
                                label='Committed Quantity (Pieces)'
                                name='quoteRevision.quoteLineItem.committedQuantity'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: IntFormat as any
                                }}
                                value={
                                  values?.quoteRevision?.quoteLineItem
                                    ?.committedQuantity || ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.contractDuration'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.contractDuration'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.contractDuration'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.contractDuration'
                                  )
                                }
                                label='Contract Duration (Months)'
                                name='quoteRevision.quoteLineItem.contractDuration'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: IntFormat as any
                                }}
                                value={
                                  values?.quoteRevision?.quoteLineItem
                                    ?.contractDuration || ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.lineItemNotes'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.lineItemNotes'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.lineItemNotes'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.lineItemNotes'
                                  )
                                }
                                label='Line Item Notes'
                                maxRows={4}
                                minRows={4}
                                multiline
                                name='quoteRevision.quoteLineItem.lineItemNotes'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={
                                  values?.quoteRevision?.quoteLineItem?.lineItemNotes ||
                                  ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          {/* <Grid item xs={12} sm={6}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              Job Center:{' '}
                              {clearWhenDirty(
                                values?.quoteRevision?.quoteLineItem?.jobCenter
                              )}
                            </Typography>
                          </Grid> */}

                          <Grid item xs={12}>
                            <ExplanationAccordion>
                              || NorthGate / Internal and Client / External radio buttons
                              are not selected by default, user must select one before
                              saving. || SKU# prefix is MC if the customer is an internal
                              customer (has "Internal" checked on the Details table on the
                              Company Details screen), otherwise the prefix is JP. If a
                              SKU# already exists, the system will prompt you to change
                              it. || SKU (JP# or MC#) is unique across Quote IDs (e.g.,
                              unique to an Opportunity), not Quote Revision IDs. || Notes
                              will show on vendor orders screen and job schedule details.
                              || Upload design file requires a PDF file to upload. One
                              file per line item. If a design file has been uploaded and
                              the user uploads a new design file, the previous one will be
                              overwritten. || All fields on this card are required.
                            </ExplanationAccordion>
                          </Grid>
                        </Grid>
                      </DetailsForm>
                    </Grid>

                    {/* DIMENSIONS CARD */}
                    {/* DIMENSIONS CARD */}
                    {/* DIMENSIONS CARD */}
                    <Grid item xs={12}>
                      <DetailsForm>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            container
                            alignItems='center'
                            justifyContent='space-between'
                          >
                            <Grid item xs={12} sm={6}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#2780E3', fontWeight: 600 }}
                              >
                                Sheet Dimensions
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.grossWidth'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.grossWidth'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.grossWidth'
                                  ) &&
                                  getIn(errors, 'quoteRevision.quoteLineItem.grossWidth')
                                }
                                label='Gross Width'
                                name='quoteRevision.quoteLineItem.grossWidth'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: DecimalFormat as any
                                }}
                                value={
                                  values?.quoteRevision?.quoteLineItem?.grossWidth || ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.grossLength'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.grossLength'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.grossLength'
                                  ) &&
                                  getIn(errors, 'quoteRevision.quoteLineItem.grossLength')
                                }
                                label='Gross Length'
                                name='quoteRevision.quoteLineItem.grossLength'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: DecimalFormat as any
                                }}
                                value={
                                  values?.quoteRevision?.quoteLineItem?.grossLength || ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              Gross SQFT:{' '}
                              {clearWhenDirty(
                                format(
                                  values?.quoteRevision?.quoteLineItem?.grossSquareFeet
                                )
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <Divider />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.netWidth'
                                  ) &&
                                    getIn(errors, 'quoteRevision.quoteLineItem.netWidth')
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.netWidth'
                                  ) &&
                                  getIn(errors, 'quoteRevision.quoteLineItem.netWidth')
                                }
                                label='NET Width'
                                name='quoteRevision.quoteLineItem.netWidth'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: DecimalFormat as any
                                }}
                                value={
                                  values?.quoteRevision?.quoteLineItem?.netWidth || ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.netLength'
                                  ) &&
                                    getIn(errors, 'quoteRevision.quoteLineItem.netLength')
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.netLength'
                                  ) &&
                                  getIn(errors, 'quoteRevision.quoteLineItem.netLength')
                                }
                                label='NET Length'
                                name='quoteRevision.quoteLineItem.netLength'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: DecimalFormat as any
                                }}
                                value={
                                  values?.quoteRevision?.quoteLineItem?.netLength || ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              Net SQFT:{' '}
                              {clearWhenDirty(
                                format(
                                  values?.quoteRevision?.quoteLineItem?.netSquareFeet
                                )
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <Divider />
                          </Grid>

                          <Grid item xs={12}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              MSF per Unit:{' '}
                              {clearWhenDirty(
                                format(values?.quoteRevision?.quoteLineItem?.msfPerUnit)
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.jobCenter'
                                  ) &&
                                    getIn(errors, 'quoteRevision.quoteLineItem.jobCenter')
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.jobCenter'
                                  ) &&
                                  getIn(errors, 'quoteRevision.quoteLineItem.jobCenter')
                                }
                                label='Job Center'
                                name='quoteRevision.quoteLineItem.jobCenter'
                                onBlur={handleBlur}
                                onChange={e => {
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.jobCenter',
                                    e.target.value
                                  )

                                  if (e.target.value === 'Little Jack') {
                                    api.getLittleJackPiecesPerHour().then(({ value }) => {
                                      setFieldValue(
                                        'quoteRevision.quoteLineItem.variableCostsJobCenterPiecePerHour',
                                        value
                                      )
                                    })
                                  } else if (e.target.value === 'Big Jack') {
                                    api.getBigJackPiecesPerHour().then(({ value }) => {
                                      setFieldValue(
                                        'quoteRevision.quoteLineItem.variableCostsJobCenterPiecePerHour',
                                        value
                                      )
                                    })
                                  }
                                }}
                                select
                                value={
                                  values?.quoteRevision?.quoteLineItem?.jobCenter || ''
                                }
                              >
                                <MenuItem value='Little Jack'>Little Jack</MenuItem>
                                <MenuItem value='Big Jack'>Big Jack</MenuItem>
                              </TextField>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  (getIn(touched, 'quoteRevision.quoteLineItem.dieCut') &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.dieCut'
                                    )) ||
                                    dieCutHelpfulText
                                )}
                                fullWidth
                                helperText={
                                  (getIn(touched, 'quoteRevision.quoteLineItem.dieCut') &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.dieCut'
                                    )) ||
                                  dieCutHelpfulText
                                }
                                label='Die Cut'
                                name='quoteRevision.quoteLineItem.dieCut'
                                onBlur={handleBlur}
                                onChange={e => {
                                  if (
                                    values.quoteRevision?.quoteLineItem?.dieCut ===
                                      'Full Die Cut' &&
                                    e.target.value !== 'Full Die Cut'
                                  ) {
                                    window.alert(
                                      'Die Cut has been changed from Full Die Cut. Please verify Gross Width and Gross Length'
                                    )
                                  }

                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.dieCut',
                                    e.target.value
                                  )
                                  setFieldTouched(
                                    'quoteRevision.quoteLineItem.dieCut',
                                    true
                                  )

                                  if (e.target.value === 'No Die Cut') {
                                    setDieCutHelpfulText('')
                                    // Do nothing.
                                  } else if (e.target.value === 'Partial Die Cut') {
                                    setDieCutHelpfulText(
                                      'Please increase the Gross Width or Gross Length by 1" to account for trimming.'
                                    )
                                  } else if (e.target.value === 'Full Die Cut') {
                                    setFieldValue(
                                      'quoteRevision.quoteLineItem.grossWidth',
                                      parseFloat(
                                        (values.quoteRevision?.quoteLineItem
                                          ?.grossWidth ?? 0) + ''
                                      ) + 1
                                    )
                                    setFieldValue(
                                      'quoteRevision.quoteLineItem.grossLength',
                                      parseFloat(
                                        (values.quoteRevision?.quoteLineItem
                                          ?.grossLength ?? 0) + ''
                                      ) + 1
                                    )

                                    setDieCutHelpfulText(
                                      'The entered Gross Width AND Gross Length values have been automatically increased by 1 to account for trimming.'
                                    )
                                  }
                                }}
                                select
                                value={values?.quoteRevision?.quoteLineItem?.dieCut || ''}
                              >
                                <MenuItem value='No Die Cut'>No Die Cut</MenuItem>
                                <MenuItem value='Partial Die Cut'>
                                  Partial Die Cut
                                </MenuItem>
                                <MenuItem value='Full Die Cut'>Full Die Cut</MenuItem>
                              </TextField>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12}>
                            <ExplanationAccordion>
                              || Gross SQFT = (Gross width x gross length) / 144 Note:
                              Rounded to four decimal places. || Net SQFT = (Net width x
                              net length) / 144 Note: Rounded to four decimal places. ||
                              MSF per Unit = ((Gross Width from Sheet Dimensions card /
                              12) * (Gross Length from Sheet Dimensions card / 12)) /
                              1,000. Note: Rounded to four decimal places. || Job Center
                              is auto populated based on Gross dimensions: If Gross Width
                              &gt; 64 "Big Jack" will be auto assigned, else "Little
                              Jack". If Gross Length &gt; 125, "Big Jack" will be auto
                              assigned regardless of Gross Width. Users may manually
                              change the job center. Note that the Labor Cost calculation
                              on the Variable Cost card is impacted by which job center is
                              selected due to differing “Piece Per Hour” values. || When
                              users press Save, if Gross Width is &gt; 64, the Job Center
                              dropdown will error, a message will be displayed saying
                              “Gross Width is greater than 64. Please select Big Jack”,
                              and the system will not save. When users press Save, if
                              Gross Length &gt; 125, the Job Center dropdown will error, a
                              message will be displayed saying “Gross Length is greater
                              than 126. Please select Big Jack”, and the system will not
                              save. || Die Cut dropdown contains options for “No Die Cut”,
                              “Partial Die Cut”, and “Full Die Cut”. “No Die Cut” is
                              selected by default. If user selects “Partial Die Cut”, red
                              text will appear below the dropdown saying, “Please increase
                              the Gross Width or Gross Length by 1” to account for
                              trimming.” If user selects “Full Die Cut”, red text will
                              appear below the dropdown saying, “The entered Gross Width
                              AND Gross Length values have been automatically increased by
                              1 to account for trimming.” When “Full Die Cut” is selected,
                              upon save add 1 from the current values in the Gross Width
                              AND Gross Length fields. || All fields on this card are
                              required.
                            </ExplanationAccordion>
                          </Grid>
                        </Grid>
                      </DetailsForm>
                    </Grid>

                    {/* PAPER CARD */}
                    {/* PAPER CARD */}
                    {/* PAPER CARD */}
                    <Grid item xs={12}>
                      <DetailsForm>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            container
                            alignItems='center'
                            justifyContent='space-between'
                          >
                            <Grid item xs={12} sm={6}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#2780E3', fontWeight: 600 }}
                              >
                                Paper
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                              <Tooltip
                                title={
                                  values.opportunityLastStatus?.specialIdentifier ===
                                  'OPPORTUNITY_STATUS_SOLD'
                                    ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                    : OPPORTUNITIES_ADD_EDIT
                                    ? ''
                                    : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                                }
                                placement='bottom'
                              >
                                <TextField
                                  disabled={
                                    isSubmitting ||
                                    !OPPORTUNITIES_ADD_EDIT ||
                                    values.opportunityLastStatus?.specialIdentifier ===
                                      'OPPORTUNITY_STATUS_SOLD'
                                  }
                                  error={Boolean(
                                    getIn(
                                      touched,
                                      'quoteRevision.quoteLineItem.vendor'
                                    ) &&
                                      getIn(errors, 'quoteRevision.quoteLineItem.vendor')
                                  )}
                                  fullWidth
                                  helperText={
                                    getIn(
                                      touched,
                                      'quoteRevision.quoteLineItem.vendor'
                                    ) &&
                                    getIn(errors, 'quoteRevision.quoteLineItem.vendor')
                                  }
                                  label='Vendor'
                                  name='quoteRevision.quoteLineItem.vendor'
                                  onBlur={handleBlur}
                                  onChange={e => {
                                    // These fields are directly dependent on the vendor field
                                    setFieldValue(
                                      'quoteRevision.quoteLineItem.vendor',
                                      e.target.value
                                    )

                                    setFieldValue(
                                      'quoteRevision.quoteLineItem.boardType',
                                      undefined
                                    )
                                    setFieldValue(
                                      'quoteRevision.quoteLineItem.flute',
                                      undefined
                                    )
                                    setFieldValue(
                                      'quoteRevision.quoteLineItem.ectMullen',
                                      undefined
                                    )
                                    setFieldValue(
                                      'quoteRevision.quoteLineItem.quotePaperAdders',
                                      []
                                    )
                                    setFieldValue(
                                      'quoteRevision.quoteLineItem.blendLiner1',
                                      undefined
                                    )
                                    setFieldValue(
                                      'quoteRevision.quoteLineItem.blendMedium1',
                                      undefined
                                    )
                                    setFieldValue(
                                      'quoteRevision.quoteLineItem.blendLiner2',
                                      undefined
                                    )
                                    setFieldValue(
                                      'quoteRevision.quoteLineItem.blendMedium2',
                                      undefined
                                    )
                                    setFieldValue(
                                      'quoteRevision.quoteLineItem.blendLiner3',
                                      undefined
                                    )
                                    setFieldValue(
                                      'quoteRevision.quoteLineItem.blendMedium3',
                                      undefined
                                    )
                                    setFieldValue(
                                      'quoteRevision.quoteLineItem.blendLiner4',
                                      undefined
                                    )
                                    setFieldValue(
                                      'quoteRevision.quoteLineItem.quoteBlendAdders',
                                      undefined
                                    )
                                  }}
                                  select
                                  value={
                                    values?.quoteRevision?.quoteLineItem?.vendor || ''
                                  }
                                >
                                  {ratesPaper
                                    .map(paper => paper.company)
                                    .map(company => company!.name)
                                    .filter(onlyUnique)
                                    .sort((a, b) => a.localeCompare(b))
                                    .map(companyName => (
                                      <MenuItem key={companyName} value={companyName}>
                                        {companyName}
                                      </MenuItem>
                                    ))}
                                </TextField>
                              </Tooltip>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.boardType'
                                  ) &&
                                    getIn(errors, 'quoteRevision.quoteLineItem.boardType')
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.boardType'
                                  ) &&
                                  getIn(errors, 'quoteRevision.quoteLineItem.boardType')
                                }
                                label='Board'
                                name='board'
                                onBlur={handleBlur}
                                onChange={e => {
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.boardType',
                                    JSON.parse(e.target.value)
                                  )

                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.flute',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.ectMullen',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.quotePaperAdders',
                                    []
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.blendLiner1',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.blendMedium1',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.blendLiner2',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.blendMedium2',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.blendLiner3',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.blendMedium3',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.blendLiner4',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.quoteBlendAdders',
                                    undefined
                                  )
                                }}
                                select
                                value={
                                  values?.quoteRevision?.quoteLineItem?.boardType
                                    ? JSON.stringify(
                                        ratesPaper
                                          .map(paper => paper.boardType)
                                          .filter(
                                            boardType =>
                                              boardType!.id ===
                                              values.quoteRevision?.quoteLineItem
                                                ?.boardType?.id
                                          )[0]
                                      )
                                    : ''
                                }
                              >
                                {toUniqueArrayByKey(
                                  ratesPaper
                                    .filter(
                                      paper =>
                                        values.quoteRevision?.quoteLineItem?.vendor &&
                                        paper.company!.name ===
                                          values.quoteRevision?.quoteLineItem?.vendor
                                    )
                                    .map(paper => paper.boardType),
                                  'name'
                                )
                                  .sort((a, b) => a.sortOrder - b.sortOrder)
                                  .map(boardType => (
                                    <MenuItem
                                      key={boardType!.id}
                                      value={JSON.stringify(boardType)}
                                    >
                                      {boardType!.name}
                                    </MenuItem>
                                  ))}
                              </TextField>
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(touched, 'quoteRevision.quoteLineItem.flute') &&
                                    getIn(errors, 'quoteRevision.quoteLineItem.flute')
                                )}
                                fullWidth
                                helperText={
                                  getIn(touched, 'quoteRevision.quoteLineItem.flute') &&
                                  getIn(errors, 'quoteRevision.quoteLineItem.flute')
                                }
                                label='Flute'
                                name='quoteRevision.quoteLineItem.flute'
                                onBlur={handleBlur}
                                onChange={e => {
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.flute',
                                    e.target.value
                                  )

                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.ectMullen',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.quotePaperAdders',
                                    []
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.blendLiner1',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.blendMedium1',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.blendLiner2',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.blendMedium2',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.blendLiner3',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.blendMedium3',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.blendLiner4',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.quoteBlendAdders',
                                    undefined
                                  )
                                }}
                                select
                                value={values.quoteRevision?.quoteLineItem?.flute || ''}
                              >
                                {ratesPaper
                                  .filter(
                                    paper =>
                                      values.quoteRevision?.quoteLineItem?.vendor &&
                                      values.quoteRevision?.quoteLineItem?.boardType &&
                                      paper.company?.name ===
                                        values.quoteRevision.quoteLineItem.vendor &&
                                      paper.boardType?.id ===
                                        values.quoteRevision.quoteLineItem.boardType.id
                                  )
                                  .map(paper => paper.flute)
                                  .filter(unique)
                                  .sort((a, b) => a!.localeCompare(b!))
                                  .map(flute => (
                                    <MenuItem key={flute} value={flute}>
                                      {flute}
                                    </MenuItem>
                                  ))}
                              </TextField>
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <TextField
                              disabled={
                                isSubmitting ||
                                !OPPORTUNITIES_ADD_EDIT ||
                                values.opportunityLastStatus?.specialIdentifier ===
                                  'OPPORTUNITY_STATUS_SOLD'
                              }
                              error={Boolean(
                                getIn(touched, 'quoteRevision.quoteLineItem.ectMullen') &&
                                  getIn(errors, 'quoteRevision.quoteLineItem.ectMullen')
                              )}
                              fullWidth
                              helperText={
                                getIn(touched, 'quoteRevision.quoteLineItem.ectMullen') &&
                                getIn(errors, 'quoteRevision.quoteLineItem.ectMullen')
                              }
                              label='ECT / Mullen'
                              name='quoteRevision.quoteLineItem.ectMullen'
                              onBlur={handleBlur}
                              onChange={e => {
                                setFieldValue(
                                  'quoteRevision.quoteLineItem.ectMullen',
                                  e.target.value
                                )

                                setFieldValue(
                                  'quoteRevision.quoteLineItem.quotePaperAdders',
                                  []
                                )
                                setFieldValue(
                                  'quoteRevision.quoteLineItem.blendLiner1',
                                  undefined
                                )
                                setFieldValue(
                                  'quoteRevision.quoteLineItem.blendMedium1',
                                  undefined
                                )
                                setFieldValue(
                                  'quoteRevision.quoteLineItem.blendLiner2',
                                  undefined
                                )
                                setFieldValue(
                                  'quoteRevision.quoteLineItem.blendMedium2',
                                  undefined
                                )
                                setFieldValue(
                                  'quoteRevision.quoteLineItem.blendLiner3',
                                  undefined
                                )
                                setFieldValue(
                                  'quoteRevision.quoteLineItem.blendMedium3',
                                  undefined
                                )
                                setFieldValue(
                                  'quoteRevision.quoteLineItem.blendLiner4',
                                  undefined
                                )
                                setFieldValue(
                                  'quoteRevision.quoteLineItem.quoteBlendAdders',
                                  undefined
                                )
                              }}
                              select
                              value={values.quoteRevision?.quoteLineItem?.ectMullen || ''}
                            >
                              {ratesPaper
                                .filter(
                                  paper =>
                                    values.quoteRevision?.quoteLineItem?.vendor &&
                                    values.quoteRevision?.quoteLineItem?.boardType &&
                                    values.quoteRevision?.quoteLineItem?.flute &&
                                    paper.company?.name ===
                                      values.quoteRevision?.quoteLineItem?.vendor &&
                                    paper.boardType?.id ===
                                      values.quoteRevision?.quoteLineItem?.boardType
                                        ?.id &&
                                    paper.flute ===
                                      values.quoteRevision?.quoteLineItem?.flute
                                )
                                .map(paper => paper.ect)
                                .filter(unique)
                                .sort((a, b) => a!.localeCompare(b!))
                                .map(ectMullen => (
                                  <MenuItem key={ectMullen} value={ectMullen}>
                                    {ectMullen}
                                  </MenuItem>
                                ))}
                            </TextField>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            {/* TODO: How do we make this a common control? This is painful. */}
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <FormControl fullWidth>
                                <InputLabel id='quote-paper-adders-label'>
                                  Adders
                                </InputLabel>

                                <Select
                                  labelId='quote-paper-adders-label'
                                  disabled={
                                    isSubmitting ||
                                    !OPPORTUNITIES_ADD_EDIT ||
                                    values.opportunityLastStatus?.specialIdentifier ===
                                      'OPPORTUNITY_STATUS_SOLD'
                                  }
                                  multiple
                                  fullWidth
                                  value={values.quoteRevision?.quoteLineItem?.quotePaperAdders?.map(
                                    quotePaperAdder =>
                                      quotePaperAdder.ratesPaperAdder?.id!
                                  )}
                                  onChange={(
                                    event: SelectChangeEvent<string[]>,
                                    _child: React.ReactNode
                                  ) => {
                                    if (Array.isArray(event.target.value)) {
                                      setFieldValue(
                                        'quoteRevision.quoteLineItem.quotePaperAdders',
                                        ratesPaperAdders
                                          .filter(ratesPaperAdder =>
                                            event.target.value.includes(
                                              ratesPaperAdder.id!
                                            )
                                          )
                                          .map(ratesPaperAdder => ({
                                            ...new QuotePaperAdderDto(),
                                            ratesPaperAdder
                                          }))
                                      )
                                    }
                                  }}
                                  input={<OutlinedInput label='Adders' />}
                                  renderValue={selected => (
                                    <Box
                                      sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                                    >
                                      {selected.map(ratesPaperAdderId => (
                                        <Chip
                                          key={ratesPaperAdderId}
                                          label={
                                            ratesPaperAdders?.filter(
                                              ratesPaperAdder =>
                                                ratesPaperAdder.id === ratesPaperAdderId
                                            )[0]?.adder ?? ''
                                          }
                                        />
                                      ))}
                                    </Box>
                                  )}
                                  MenuProps={MenuProps}
                                >
                                  {ratesPaperAdders
                                    ?.filter(
                                      ratesPaperAdder =>
                                        values.quoteRevision?.quoteLineItem?.vendor &&
                                        values.quoteRevision?.quoteLineItem?.boardType &&
                                        values.quoteRevision?.quoteLineItem?.flute &&
                                        ratesPaperAdder.company?.name ===
                                          values.quoteRevision?.quoteLineItem?.vendor &&
                                        (!ratesPaperAdder.ect ||
                                          ratesPaperAdder.ect ===
                                            values.quoteRevision?.quoteLineItem
                                              ?.ectMullen)
                                    )
                                    ?.map(ratesPaperAdder => (
                                      <MenuItem
                                        key={ratesPaperAdder.id}
                                        value={ratesPaperAdder.id}
                                      >
                                        <Checkbox
                                          checked={
                                            values.quoteRevision?.quoteLineItem?.quotePaperAdders
                                              ?.map(
                                                quotePaperAdder =>
                                                  quotePaperAdder!.ratesPaperAdder!.id
                                              )
                                              .indexOf(ratesPaperAdder!.id)! > -1
                                          }
                                        />
                                        <ListItemText primary={ratesPaperAdder.adder} />
                                      </MenuItem>
                                    ))}
                                </Select>

                                {getIn(
                                  touched,
                                  'quoteRevision.quoteLineItem.quotePaperAdders'
                                ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.quotePaperAdders'
                                  ) && (
                                    <FormHelperText error>
                                      {getIn(
                                        touched,
                                        'quoteRevision.quoteLineItem.quotePaperAdders'
                                      ) &&
                                        getIn(
                                          errors,
                                          'quoteRevision.quoteLineItem.quotePaperAdders'
                                        )}
                                    </FormHelperText>
                                  )}
                              </FormControl>
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12}>
                            <ExplanationAccordion>
                              Adder drop down is populated from the rates table based on
                              the selected vendor on the paper card.||ECT / Mullen loads
                              based on flute selection, which loads based on board
                              selection which loads based on vendor selection.||All fields
                              on this card except Adders are required.
                            </ExplanationAccordion>

                            <ExplanationAccordion development>
                              A unique rates paper item is selected using the "Paper" card
                              and "Blend" card. The list of all active rate spapers is
                              gradually filtered down starting with the "Vendor" field,
                              then the "Board" field, then the "Flute" field, then the
                              "Flute" field, then the "ECT / Mullen" field, then (on the
                              "Blend" card) the "Liner 1" field, then "Medium 1", then
                              "Liner 2", then (if applicable) "Medium 2", then (if
                              applicable) "Liner 3", then (if applicable) "Medium 3", then
                              (if applicable) "Liner 4".||If, at that point, there still
                              isn't a unique paper, it's up to the user to rectify the
                              rates paper data to fix the ambiguity.||1. The "Vendor"
                              field uses a list of all active rates papers, and displays a
                              list of unique vendor names associated with some rates paper
                              in that list.||2. The "Board" field uses a list of all
                              active rates papers with the selected vendor, and displays a
                              list of unique board type names associated with some rates
                              paper in that list.||3. The "Flute" field uses a list of all
                              active rates papers with the selected vendor AND the
                              selected board type, and displays a list of unique flute
                              values associated with some rates apper in that list.||4.
                              This process then continues filtering progressively further
                              by the fields listed above.
                            </ExplanationAccordion>
                          </Grid>
                        </Grid>
                      </DetailsForm>
                    </Grid>

                    <Grid item xs={12}>
                      <DetailsForm>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            container
                            alignItems='center'
                            justifyContent='space-between'
                          >
                            <Grid item xs={12}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#2780E3', fontWeight: 600 }}
                              >
                                Paper Cost
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Item
                            </Typography>
                          </Grid>

                          <Grid container item xs={12} sm={8}>
                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Setup Fee
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Cost per MSF
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Cost per MPCS
                              </Typography>
                            </Grid>
                          </Grid>

                          <Divider />

                          <Grid item xs={12} sm={4}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              Paper (Order MSF range:{' '}
                              {getPaperLabel(
                                values.quoteRevision?.quoteLineItem?.assemblyOrderMsf ?? 0
                              )}
                              )
                            </Typography>
                          </Grid>

                          <Grid container item xs={12} sm={8}>
                            <Grid item xs={12} sm={4}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.paperCostSetupFee,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem?.paperCostPerMsf,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem?.paperCostPerMpcs,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Subtotal
                            </Typography>
                          </Grid>

                          <Grid container item xs={12} sm={8}>
                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.paperCostSubtotalSetupFee,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.paperCostSubtotalCostPerMsf,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.paperCostSubtotalCostPerMpcs,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <ExplanationAccordion>
                              The "Paper" label shows the range in which the Order MSF
                              (from the Assembly card) falls in. || Setup Fee = Setup Fee
                              for the selected Paper from Rates. || Cost per MSF: This is
                              not a calculated value; Paper Cost per MSF is read from
                              Rates - Paper from the needed MSF range column (e.g.,
                              MSF&gt;0&lt;5, MSF&gt;=5&lt;10, etc.). || Cost per MPCS =
                              Cost per MSF * Gross SQFT from Sheet Dimensions card.
                            </ExplanationAccordion>
                          </Grid>
                        </Grid>
                      </DetailsForm>
                    </Grid>

                    <Grid item xs={12}>
                      <DetailsForm>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            container
                            alignItems='center'
                            justifyContent='space-between'
                          >
                            <Grid item xs={12}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#2780E3', fontWeight: 600 }}
                              >
                                Paper Adders Cost
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Item
                            </Typography>
                          </Grid>

                          <Grid container item xs={12} sm={8}>
                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Setup Fee
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Cost per MSF
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Cost per MPCS
                              </Typography>
                            </Grid>
                          </Grid>

                          {values.quoteRevision?.quoteLineItem?.quotePaperAdders?.map(
                            paperAdder => (
                              <>
                                <Grid item xs={12} sm={4}>
                                  <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                    {clearWhenDirty(format(paperAdder.adder))}
                                  </Typography>
                                </Grid>

                                <Grid container item xs={12} sm={8}>
                                  <Grid item xs={12} sm={4}>
                                    <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                      {clearWhenDirty(
                                        format(paperAdder.adderSetup, formats.currency)
                                      )}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={12} sm={4}>
                                    <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                      {clearWhenDirty(
                                        format(paperAdder.costPerMsf, formats.currency)
                                      )}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={12} sm={4}>
                                    <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                      {clearWhenDirty(
                                        format(paperAdder.costPerMpcs, formats.currency)
                                      )}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </>
                            )
                          )}

                          <Grid item xs={12} sm={4}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Subtotal
                            </Typography>
                          </Grid>

                          <Grid container item xs={12} sm={8}>
                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.paperAddersCostSubtotalSetupFee,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.paperAddersCostSubtotalCostPerMsf,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.paperAddersCostSubtotalCostPerMpcs,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <ExplanationAccordion>
                              Setup Fee = Adder Setup for the selected Paper Adder from
                              Rates. || Cost per MSF = Adder Per MSF for the selected
                              Paper Adder from Rates. || Cost per MPCS = Cost per MSF *
                              Gross SQFT from Sheet Dimensions card.
                            </ExplanationAccordion>
                          </Grid>
                        </Grid>
                      </DetailsForm>
                    </Grid>

                    <Grid item xs={12}>
                      <DetailsForm>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            container
                            alignItems='center'
                            justifyContent='space-between'
                          >
                            <Grid item xs={12}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#2780E3', fontWeight: 600 }}
                              >
                                Sesame Tape
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                              <TextField
                                disabled={isSubmitting}
                                error={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.sesameTapeType'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.sesameTapeType'
                                  )
                                }
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.sesameTapeType'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.sesameTapeType'
                                  )
                                }
                                label='Sesame Tape Type'
                                name='quoteRevision.quoteLineItem.sesameTapeType'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                select
                                value={
                                  values.quoteRevision?.quoteLineItem?.sesameTapeType ||
                                  ''
                                }
                              >
                                <MenuItem value=''>Sesame Type</MenuItem>

                                <MenuItem value='Coastal Container Sesame Tape 1 Side'>
                                  Coastal Container Sesame Tape 1 Side
                                </MenuItem>

                                <MenuItem value='Coastal Container Sesame Tape 2 Side'>
                                  Coastal Container Sesame Tape 2 Side
                                </MenuItem>

                                <MenuItem value='Georgia Pacific Sesame Tape 1 Side'>
                                  Georgia Pacific Sesame Tape 1 Side
                                </MenuItem>

                                <MenuItem value='Georgia Pacific Sesame Tape 2 Side'>
                                  Georgia Pacific Sesame Tape 2 Side
                                </MenuItem>

                                <MenuItem value='Viking 1 Sesame Tape 1 Side'>
                                  Viking 1 Sesame Tape 1 Side
                                </MenuItem>

                                <MenuItem value='Viking 1 Sesame Tape 2 Side'>
                                  Viking 1 Sesame Tape 2 Side
                                </MenuItem>

                                <MenuItem value='Viking 2 Sesame Tape 1 Side'>
                                  Viking 2 Sesame Tape 1 Side
                                </MenuItem>

                                <MenuItem value='Viking 2 Sesame Tape 2 Side'>
                                  Viking 2 Sesame Tape 2 Side
                                </MenuItem>
                              </TextField>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <TextField
                              disabled={isSubmitting}
                              error={
                                getIn(
                                  touched,
                                  'quoteRevision.quoteLineItem.sesameTapeInches'
                                ) &&
                                getIn(
                                  errors,
                                  'quoteRevision.quoteLineItem.sesameTapeInches'
                                )
                              }
                              fullWidth
                              helperText={
                                getIn(
                                  touched,
                                  'quoteRevision.quoteLineItem.sesameTapeInches'
                                ) &&
                                getIn(
                                  errors,
                                  'quoteRevision.quoteLineItem.sesameTapeInches'
                                )
                              }
                              label='Sesame Tape Inches'
                              name='quoteRevision.quoteLineItem.sesameTapeInches'
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={
                                values.quoteRevision?.quoteLineItem?.sesameTapeInches ||
                                ''
                              }
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Subtotal:{' '}
                              {clearWhenDirty(
                                format(
                                  values.quoteRevision?.quoteLineItem?.sesameTapeSubtotal
                                )
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <ExplanationAccordion>
                              || Sesame Tape fields are required when a user adds a Paper
                              Adder with “Requires Sesame Tape Inches” checked under
                              “Rates - Paper Adders” to the quote. || The “Sesame Tape
                              Type” dropdown is hardcoded with these options: Coastal
                              Container Sesame Tape 1 Side, Coastal Container Sesame Tape
                              2 Side, Georgia Pacific Sesame Tape 1 Side, Georgia Pacific
                              Sesame Tape 2 Side, Viking 1 Sesame Tape 1 Side, Viking 1
                              Sesame Tape 2 Side, Viking 2 Sesame Tape 1 Side, Viking 2
                              Sesame Tape 2 Side. || Sesame Tape Inches is a numeric field
                              that only allows positive values. || Subtotal is calculated
                              upon saving using the selected Sesame Tape Type’s Cost Per
                              Inch from Rates – Global * Sesame Tape Inches. || Sesame
                              Tape Inches is the total number of inches of sesame tape
                              required from the vendor per box/packaging.
                            </ExplanationAccordion>
                          </Grid>
                        </Grid>
                      </DetailsForm>
                    </Grid>

                    <Grid item xs={12}>
                      <DetailsForm>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            container
                            alignItems='center'
                            justifyContent='space-between'
                          >
                            <Grid item xs={12} sm={6}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#2780E3', fontWeight: 600 }}
                              >
                                Blend
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.blendLiner1'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.blendLiner1'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.blendLiner1'
                                  ) &&
                                  getIn(errors, 'quoteRevision.quoteLineItem.blendLiner1')
                                }
                                label='Liner 1'
                                name='quoteRevision.quoteLineItem.blendLiner1'
                                onBlur={handleBlur}
                                onChange={e => {
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.blendLiner1',
                                    e.target.value
                                  )

                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.blendMedium1',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.blendLiner2',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.blendMedium2',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.blendLiner3',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.blendMedium3',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.blendLiner4',
                                    undefined
                                  )
                                }}
                                select
                                value={
                                  values.quoteRevision?.quoteLineItem?.blendLiner1 || ''
                                }
                              >
                                {ratesPaper
                                  .filter(
                                    paper =>
                                      values.quoteRevision?.quoteLineItem?.vendor &&
                                      values.quoteRevision?.quoteLineItem?.boardType &&
                                      values.quoteRevision?.quoteLineItem?.flute &&
                                      values.quoteRevision?.quoteLineItem?.ectMullen &&
                                      paper.company?.name ===
                                        values.quoteRevision.quoteLineItem.vendor &&
                                      paper.boardType?.id ===
                                        values.quoteRevision?.quoteLineItem?.boardType
                                          ?.id &&
                                      paper.flute ===
                                        values.quoteRevision?.quoteLineItem?.flute &&
                                      paper.ect ===
                                        values.quoteRevision?.quoteLineItem?.ectMullen
                                  )
                                  .map(paper => paper.liner1)
                                  .filter(unique)
                                  .map((liner1, index) => (
                                    <MenuItem key={index} value={liner1}>
                                      {liner1}
                                    </MenuItem>
                                  ))}
                              </TextField>
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.blendMedium1'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.blendMedium1'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.blendMedium1'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.blendMedium1'
                                  )
                                }
                                label='Medium 1'
                                name='quoteRevision.quoteLineItem.blendMedium1'
                                onBlur={handleBlur}
                                onChange={e => {
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.blendMedium1',
                                    e.target.value
                                  )

                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.blendLiner2',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.blendMedium2',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.blendLiner3',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.blendMedium3',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.blendLiner4',
                                    undefined
                                  )
                                }}
                                select
                                value={
                                  values.quoteRevision?.quoteLineItem?.blendMedium1 || ''
                                }
                              >
                                {ratesPaper
                                  .filter(
                                    paper =>
                                      values.quoteRevision?.quoteLineItem?.vendor &&
                                      values.quoteRevision?.quoteLineItem?.boardType &&
                                      values.quoteRevision?.quoteLineItem?.flute &&
                                      values.quoteRevision?.quoteLineItem?.ectMullen &&
                                      values.quoteRevision?.quoteLineItem?.blendLiner1 &&
                                      paper.company?.name ===
                                        values.quoteRevision.quoteLineItem.vendor &&
                                      paper.boardType?.id ===
                                        values.quoteRevision?.quoteLineItem?.boardType
                                          ?.id &&
                                      paper.flute ===
                                        values.quoteRevision?.quoteLineItem?.flute &&
                                      paper.ect ===
                                        values.quoteRevision?.quoteLineItem?.ectMullen &&
                                      paper.liner1 ===
                                        +values.quoteRevision?.quoteLineItem?.blendLiner1
                                  )
                                  .map(paper => paper.medium1)
                                  .filter(unique)
                                  .map((medium1, index) => (
                                    <MenuItem key={index} value={medium1}>
                                      {medium1}
                                    </MenuItem>
                                  ))}
                              </TextField>
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.blendLiner2'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.blendLiner2'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.blendLiner2'
                                  ) &&
                                  getIn(errors, 'quoteRevision.quoteLineItem.blendLiner2')
                                }
                                label='Liner 2'
                                name='quoteRevision.quoteLineItem.blendLiner2'
                                onBlur={handleBlur}
                                onChange={e => {
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.blendLiner2',
                                    e.target.value
                                  )

                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.blendMedium2',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.blendLiner3',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.blendMedium3',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.blendLiner4',
                                    undefined
                                  )
                                }}
                                select
                                value={
                                  values.quoteRevision?.quoteLineItem?.blendLiner2 || ''
                                }
                              >
                                {ratesPaper
                                  .filter(
                                    paper =>
                                      values.quoteRevision?.quoteLineItem?.vendor &&
                                      values.quoteRevision?.quoteLineItem?.boardType &&
                                      values.quoteRevision?.quoteLineItem?.flute &&
                                      values.quoteRevision?.quoteLineItem?.ectMullen &&
                                      values.quoteRevision?.quoteLineItem?.blendLiner1 &&
                                      values.quoteRevision?.quoteLineItem?.blendMedium1 &&
                                      paper.company?.name ===
                                        values.quoteRevision.quoteLineItem.vendor &&
                                      paper.boardType?.id ===
                                        values.quoteRevision?.quoteLineItem?.boardType
                                          ?.id &&
                                      paper.flute ===
                                        values.quoteRevision?.quoteLineItem?.flute &&
                                      paper.ect ===
                                        values.quoteRevision?.quoteLineItem?.ectMullen &&
                                      paper.liner1 ===
                                        +values.quoteRevision?.quoteLineItem
                                          ?.blendLiner1 &&
                                      paper.medium1 ===
                                        +values.quoteRevision?.quoteLineItem?.blendMedium1
                                  )
                                  .map(paper => paper.liner2)
                                  .filter(unique)
                                  .sort((a, b) => a! - b!)
                                  .map((liner2, index) => (
                                    <MenuItem key={index} value={liner2}>
                                      {liner2}
                                    </MenuItem>
                                  ))}
                              </TextField>
                            </Tooltip>
                          </Grid>

                          {(values.quoteRevision?.quoteLineItem?.boardType
                            ?.specialIdentifier === 'DOUBLE_WALL' ||
                            values.quoteRevision?.quoteLineItem?.boardType
                              ?.specialIdentifier === 'TRIPLE_WALL') && (
                            <>
                              <Grid item xs={12} sm={6}>
                                <Tooltip
                                  title={
                                    values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                      ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                      : OPPORTUNITIES_ADD_EDIT
                                      ? ''
                                      : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                                  }
                                  placement='bottom'
                                >
                                  <TextField
                                    disabled={
                                      isSubmitting ||
                                      !OPPORTUNITIES_ADD_EDIT ||
                                      values.opportunityLastStatus?.specialIdentifier ===
                                        'OPPORTUNITY_STATUS_SOLD'
                                    }
                                    error={Boolean(
                                      getIn(
                                        touched,
                                        'quoteRevision.quoteLineItem.blendMedium2'
                                      ) &&
                                        getIn(
                                          errors,
                                          'quoteRevision.quoteLineItem.blendMedium2'
                                        )
                                    )}
                                    fullWidth
                                    helperText={
                                      getIn(
                                        touched,
                                        'quoteRevision.quoteLineItem.blendMedium2'
                                      ) &&
                                      getIn(
                                        errors,
                                        'quoteRevision.quoteLineItem.blendMedium2'
                                      )
                                    }
                                    label='Medium 2'
                                    name='quoteRevision.quoteLineItem.blendMedium2'
                                    onBlur={handleBlur}
                                    onChange={e => {
                                      setFieldValue(
                                        'quoteRevision.quoteLineItem.blendMedium2',
                                        e.target.value
                                      )

                                      setFieldValue(
                                        'quoteRevision.quoteLineItem.blendLiner3',
                                        undefined
                                      )
                                      setFieldValue(
                                        'quoteRevision.quoteLineItem.blendMedium3',
                                        undefined
                                      )
                                      setFieldValue(
                                        'quoteRevision.quoteLineItem.blendLiner4',
                                        undefined
                                      )
                                    }}
                                    select
                                    value={
                                      values.quoteRevision?.quoteLineItem?.blendMedium2
                                    }
                                  >
                                    {ratesPaper
                                      .filter(
                                        paper =>
                                          values.quoteRevision?.quoteLineItem?.vendor &&
                                          values.quoteRevision?.quoteLineItem
                                            ?.boardType &&
                                          values.quoteRevision?.quoteLineItem?.flute &&
                                          values.quoteRevision?.quoteLineItem
                                            ?.ectMullen &&
                                          values.quoteRevision?.quoteLineItem
                                            ?.blendLiner1 &&
                                          values.quoteRevision?.quoteLineItem
                                            ?.blendMedium1 &&
                                          values.quoteRevision?.quoteLineItem
                                            ?.blendLiner2 &&
                                          paper.company?.name ===
                                            values.quoteRevision.quoteLineItem.vendor &&
                                          paper.boardType?.id ===
                                            values.quoteRevision?.quoteLineItem?.boardType
                                              ?.id &&
                                          paper.flute ===
                                            values.quoteRevision?.quoteLineItem?.flute &&
                                          paper.ect ===
                                            values.quoteRevision?.quoteLineItem
                                              ?.ectMullen &&
                                          paper.liner1 ===
                                            +values.quoteRevision?.quoteLineItem
                                              ?.blendLiner1 &&
                                          paper.medium1 ===
                                            +values.quoteRevision?.quoteLineItem
                                              ?.blendMedium1 &&
                                          paper.liner2 ===
                                            +values.quoteRevision?.quoteLineItem
                                              ?.blendLiner2
                                      )
                                      .map(paper => paper.medium2)
                                      .filter(unique)
                                      .sort((a, b) => a! - b!)
                                      .map((medium2, index) => (
                                        <MenuItem key={index} value={medium2}>
                                          {medium2}
                                        </MenuItem>
                                      ))}
                                  </TextField>
                                </Tooltip>
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                <Tooltip
                                  title={
                                    values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                      ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                      : OPPORTUNITIES_ADD_EDIT
                                      ? ''
                                      : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                                  }
                                  placement='bottom'
                                >
                                  <TextField
                                    disabled={
                                      isSubmitting ||
                                      !OPPORTUNITIES_ADD_EDIT ||
                                      values.opportunityLastStatus?.specialIdentifier ===
                                        'OPPORTUNITY_STATUS_SOLD'
                                    }
                                    error={Boolean(
                                      getIn(
                                        touched,
                                        'quoteRevision.quoteLineItem.blendLiner3'
                                      ) &&
                                        getIn(
                                          errors,
                                          'quoteRevision.quoteLineItem.blendLiner3'
                                        )
                                    )}
                                    fullWidth
                                    helperText={
                                      getIn(
                                        touched,
                                        'quoteRevision.quoteLineItem.blendLiner3'
                                      ) &&
                                      getIn(
                                        errors,
                                        'quoteRevision.quoteLineItem.blendLiner3'
                                      )
                                    }
                                    label='Liner 3'
                                    name='quoteRevision.quoteLineItem.blendLiner3'
                                    onBlur={handleBlur}
                                    onChange={e => {
                                      setFieldValue(
                                        'quoteRevision.quoteLineItem.blendLiner3',
                                        e.target.value
                                      )

                                      setFieldValue(
                                        'quoteRevision.quoteLineItem.blendMedium3',
                                        undefined
                                      )
                                      setFieldValue(
                                        'quoteRevision.quoteLineItem.blendLiner4',
                                        undefined
                                      )
                                    }}
                                    select
                                    value={
                                      values.quoteRevision?.quoteLineItem?.blendLiner3 ||
                                      ''
                                    }
                                  >
                                    {ratesPaper
                                      .filter(
                                        paper =>
                                          values.quoteRevision?.quoteLineItem?.vendor &&
                                          values.quoteRevision?.quoteLineItem
                                            ?.boardType &&
                                          values.quoteRevision?.quoteLineItem?.flute &&
                                          values.quoteRevision?.quoteLineItem
                                            ?.ectMullen &&
                                          values.quoteRevision?.quoteLineItem
                                            ?.blendLiner1 &&
                                          values.quoteRevision?.quoteLineItem
                                            ?.blendMedium1 &&
                                          values.quoteRevision?.quoteLineItem
                                            ?.blendLiner2 &&
                                          values.quoteRevision?.quoteLineItem
                                            ?.blendMedium2 &&
                                          paper.company?.name ===
                                            values.quoteRevision.quoteLineItem.vendor &&
                                          paper.boardType?.id ===
                                            values.quoteRevision?.quoteLineItem?.boardType
                                              ?.id &&
                                          paper.flute ===
                                            values.quoteRevision?.quoteLineItem?.flute &&
                                          paper.ect ===
                                            values.quoteRevision?.quoteLineItem
                                              ?.ectMullen &&
                                          paper.liner1 ===
                                            +values.quoteRevision?.quoteLineItem
                                              ?.blendLiner1 &&
                                          paper.medium1 ===
                                            +values.quoteRevision?.quoteLineItem
                                              ?.blendMedium1 &&
                                          paper.liner2 ===
                                            +values.quoteRevision?.quoteLineItem
                                              ?.blendLiner2 &&
                                          paper.medium2 ===
                                            +values.quoteRevision?.quoteLineItem
                                              ?.blendMedium2
                                      )
                                      .map(paper => paper.liner3)
                                      .filter(unique)
                                      .sort((a, b) => a! - b!)
                                      .map((liner3, index) => (
                                        <MenuItem key={index} value={liner3}>
                                          {liner3}
                                        </MenuItem>
                                      ))}
                                  </TextField>
                                </Tooltip>
                              </Grid>
                            </>
                          )}

                          {values.quoteRevision?.quoteLineItem?.boardType
                            ?.specialIdentifier === 'TRIPLE_WALL' && (
                            <>
                              <Grid item xs={12} sm={6}>
                                <Tooltip
                                  title={
                                    values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                      ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                      : OPPORTUNITIES_ADD_EDIT
                                      ? ''
                                      : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                                  }
                                  placement='bottom'
                                >
                                  <TextField
                                    disabled={
                                      isSubmitting ||
                                      !OPPORTUNITIES_ADD_EDIT ||
                                      values.opportunityLastStatus?.specialIdentifier ===
                                        'OPPORTUNITY_STATUS_SOLD'
                                    }
                                    error={Boolean(
                                      getIn(
                                        touched,
                                        'quoteRevision.quoteLineItem.blendMedium3'
                                      ) &&
                                        getIn(
                                          errors,
                                          'quoteRevision.quoteLineItem.blendMedium3'
                                        )
                                    )}
                                    fullWidth
                                    helperText={
                                      getIn(
                                        touched,
                                        'quoteRevision.quoteLineItem.blendMedium3'
                                      ) &&
                                      getIn(
                                        errors,
                                        'quoteRevision.quoteLineItem.blendMedium3'
                                      )
                                    }
                                    label='Medium 3'
                                    name='quoteRevision.quoteLineItem.blendMedium3'
                                    onBlur={handleBlur}
                                    onChange={e => {
                                      setFieldValue(
                                        'quoteRevision.quoteLineItem.blendMedium3',
                                        e.target.value
                                      )

                                      setFieldValue(
                                        'quoteRevision.quoteLineItem.blendLiner4',
                                        undefined
                                      )
                                    }}
                                    select
                                    value={
                                      values.quoteRevision?.quoteLineItem?.blendMedium3
                                    }
                                  >
                                    {ratesPaper
                                      .filter(
                                        paper =>
                                          values.quoteRevision?.quoteLineItem?.vendor &&
                                          values.quoteRevision?.quoteLineItem
                                            ?.boardType &&
                                          values.quoteRevision?.quoteLineItem?.flute &&
                                          values.quoteRevision?.quoteLineItem
                                            ?.ectMullen &&
                                          values.quoteRevision?.quoteLineItem
                                            ?.blendLiner1 &&
                                          values.quoteRevision?.quoteLineItem
                                            ?.blendMedium1 &&
                                          values.quoteRevision?.quoteLineItem
                                            ?.blendLiner2 &&
                                          values.quoteRevision?.quoteLineItem
                                            ?.blendMedium2 &&
                                          values.quoteRevision?.quoteLineItem
                                            ?.blendLiner3 &&
                                          paper.company?.name ===
                                            values.quoteRevision.quoteLineItem.vendor &&
                                          paper.boardType?.id ===
                                            values.quoteRevision?.quoteLineItem?.boardType
                                              ?.id &&
                                          paper.flute ===
                                            values.quoteRevision?.quoteLineItem?.flute &&
                                          paper.ect ===
                                            values.quoteRevision?.quoteLineItem
                                              ?.ectMullen &&
                                          paper.liner1 ===
                                            +values.quoteRevision?.quoteLineItem
                                              ?.blendLiner1 &&
                                          paper.medium1 ===
                                            +values.quoteRevision?.quoteLineItem
                                              ?.blendMedium1 &&
                                          paper.liner2 ===
                                            +values.quoteRevision?.quoteLineItem
                                              ?.blendLiner2 &&
                                          paper.medium2 ===
                                            +values.quoteRevision?.quoteLineItem
                                              ?.blendMedium2 &&
                                          paper.liner3 ===
                                            +values.quoteRevision?.quoteLineItem
                                              ?.blendLiner3
                                      )
                                      .map(paper => paper.medium3)
                                      .filter(unique)
                                      .sort((a, b) => a! - b!)
                                      .map((medium3, index) => (
                                        <MenuItem key={index} value={medium3}>
                                          {medium3}
                                        </MenuItem>
                                      ))}
                                  </TextField>
                                </Tooltip>
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                <Tooltip
                                  title={
                                    values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                      ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                      : OPPORTUNITIES_ADD_EDIT
                                      ? ''
                                      : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                                  }
                                  placement='bottom'
                                >
                                  <TextField
                                    disabled={
                                      isSubmitting ||
                                      !OPPORTUNITIES_ADD_EDIT ||
                                      values.opportunityLastStatus?.specialIdentifier ===
                                        'OPPORTUNITY_STATUS_SOLD'
                                    }
                                    error={Boolean(
                                      getIn(
                                        touched,
                                        'quoteRevision.quoteLineItem.blendLiner4'
                                      ) &&
                                        getIn(
                                          errors,
                                          'quoteRevision.quoteLineItem.blendLiner4'
                                        )
                                    )}
                                    fullWidth
                                    helperText={
                                      getIn(
                                        touched,
                                        'quoteRevision.quoteLineItem.blendLiner4'
                                      ) &&
                                      getIn(
                                        errors,
                                        'quoteRevision.quoteLineItem.blendLiner4'
                                      )
                                    }
                                    label='Liner 4'
                                    name='quoteRevision.quoteLineItem.blendLiner4'
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    select
                                    value={
                                      values.quoteRevision?.quoteLineItem?.blendLiner4 ||
                                      ''
                                    }
                                  >
                                    {ratesPaper
                                      .filter(
                                        paper =>
                                          values.quoteRevision?.quoteLineItem?.vendor &&
                                          values.quoteRevision?.quoteLineItem
                                            ?.boardType &&
                                          values.quoteRevision?.quoteLineItem?.flute &&
                                          values.quoteRevision?.quoteLineItem
                                            ?.ectMullen &&
                                          values.quoteRevision?.quoteLineItem
                                            ?.blendLiner1 &&
                                          values.quoteRevision?.quoteLineItem
                                            ?.blendMedium1 &&
                                          values.quoteRevision?.quoteLineItem
                                            ?.blendLiner2 &&
                                          values.quoteRevision?.quoteLineItem
                                            ?.blendMedium2 &&
                                          values.quoteRevision?.quoteLineItem
                                            ?.blendLiner3 &&
                                          values.quoteRevision?.quoteLineItem
                                            ?.blendMedium3 &&
                                          paper.company?.name ===
                                            values.quoteRevision.quoteLineItem.vendor &&
                                          paper.boardType?.id ===
                                            values.quoteRevision?.quoteLineItem?.boardType
                                              ?.id &&
                                          paper.flute ===
                                            values.quoteRevision?.quoteLineItem?.flute &&
                                          paper.ect ===
                                            values.quoteRevision?.quoteLineItem
                                              ?.ectMullen &&
                                          paper.liner1 ===
                                            +values.quoteRevision?.quoteLineItem
                                              ?.blendLiner1 &&
                                          paper.medium1 ===
                                            +values.quoteRevision?.quoteLineItem
                                              ?.blendMedium1 &&
                                          paper.liner2 ===
                                            +values.quoteRevision?.quoteLineItem
                                              ?.blendLiner2 &&
                                          paper.medium2 ===
                                            +values.quoteRevision?.quoteLineItem
                                              ?.blendMedium2 &&
                                          paper.liner3 ===
                                            +values.quoteRevision?.quoteLineItem
                                              ?.blendLiner3 &&
                                          paper.medium3 ===
                                            +values.quoteRevision?.quoteLineItem
                                              ?.blendMedium3
                                      )
                                      .map(paper => paper.liner4)
                                      .filter(unique)
                                      .sort((a, b) => a! - b!)
                                      .map((liner4, index) => (
                                        <MenuItem key={index} value={liner4}>
                                          {liner4}
                                        </MenuItem>
                                      ))}
                                  </TextField>
                                </Tooltip>
                              </Grid>
                            </>
                          )}

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <FormControl fullWidth>
                                <InputLabel id='quote-blend-adders-label'>
                                  Adders
                                </InputLabel>

                                <Select
                                  labelId='quote-blend-adders-label'
                                  disabled={
                                    isSubmitting ||
                                    !OPPORTUNITIES_ADD_EDIT ||
                                    values.opportunityLastStatus?.specialIdentifier ===
                                      'OPPORTUNITY_STATUS_SOLD'
                                  }
                                  multiple
                                  fullWidth
                                  value={
                                    values.quoteRevision?.quoteLineItem?.quoteBlendAdders?.map(
                                      quoteBlendAdder =>
                                        quoteBlendAdder.ratesBlendAdder?.id!
                                    ) ?? []
                                  }
                                  onChange={(
                                    event: SelectChangeEvent<string[]>,
                                    _child: React.ReactNode
                                  ) => {
                                    if (Array.isArray(event.target.value)) {
                                      setFieldValue(
                                        'quoteRevision.quoteLineItem.quoteBlendAdders',
                                        ratesBlendAdders
                                          .filter(ratesBlendAdder =>
                                            event.target.value.includes(
                                              ratesBlendAdder.id!
                                            )
                                          )
                                          .map(ratesBlendAdder => ({
                                            ...new QuoteBlendAdderDto(),
                                            ratesBlendAdder
                                          }))
                                      )
                                    }
                                  }}
                                  input={<OutlinedInput label='Adders' />}
                                  renderValue={selected => (
                                    <Box
                                      sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                                    >
                                      {selected.map(ratesBlendAdderId => (
                                        <Chip
                                          key={ratesBlendAdderId}
                                          label={
                                            ratesBlendAdders?.filter(
                                              ratesBlendAdder =>
                                                ratesBlendAdder.id === ratesBlendAdderId
                                            )[0]?.adder ?? ''
                                          }
                                        />
                                      ))}
                                    </Box>
                                  )}
                                  MenuProps={MenuProps}
                                >
                                  {ratesBlendAdders
                                    ?.filter(
                                      ratesBlendAdder =>
                                        values.quoteRevision?.quoteLineItem?.vendor &&
                                        values.quoteRevision?.quoteLineItem?.ectMullen &&
                                        ratesBlendAdder.company?.name ===
                                          values.quoteRevision?.quoteLineItem?.vendor &&
                                        (!ratesBlendAdder.ect ||
                                          ratesBlendAdder.ect ===
                                            values.quoteRevision?.quoteLineItem
                                              ?.ectMullen)
                                    )
                                    ?.map(ratesBlendAdder => (
                                      <MenuItem
                                        key={ratesBlendAdder.id}
                                        value={ratesBlendAdder.id}
                                      >
                                        <Checkbox
                                          checked={
                                            values.quoteRevision?.quoteLineItem?.quoteBlendAdders
                                              ?.map(
                                                quoteBlendAdder =>
                                                  quoteBlendAdder!.ratesBlendAdder!.id
                                              )
                                              .indexOf(ratesBlendAdder!.id)! > -1
                                          }
                                        />

                                        <ListItemText primary={ratesBlendAdder.adder} />
                                      </MenuItem>
                                    ))}
                                </Select>

                                {getIn(
                                  touched,
                                  'quoteRevision.quoteLineItem.quotePaperAdders'
                                ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.quotePaperAdders'
                                  ) && (
                                    <FormHelperText error>
                                      {getIn(
                                        touched,
                                        'quoteRevision.quoteLineItem.quotePaperAdders'
                                      ) &&
                                        getIn(
                                          errors,
                                          'quoteRevision.quoteLineItem.quotePaperAdders'
                                        )}
                                    </FormHelperText>
                                  )}
                              </FormControl>
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Paper Code:{' '}
                              {clearWhenDirty(
                                format(values.quoteRevision?.quoteLineItem?.paperCode)
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <ExplanationAccordion>
                              Fields show up based on board selected on paper card. This
                              is hardcoded based on the 3 options below. || SINGLEWALL =
                              Liner 1, Medium 1, Liner 2. || DOUBLEWALL = Liner 1, Medium
                              1, Liner 2, Medium 2, Liner 3. || TRIPLEWALL = Liner 1,
                              Medium 1, Liner 2, Medium 2, Liner 3, Medium 3, Liner 4. ||
                              The Adder drop down is populated from the rates table based
                              on the selected vendor on the paper card. || The Paper Code
                              label is populated after the quote revision is saved based
                              on the selected Paper card and Blend card field combinations
                              (e.g., Vendor, Board, Flute, ECT/Mullen, Liner 1, Medium 1,
                              Liner 2, Medium 2, Liner 3, Medium 3, and Liner 4
                              combination).
                            </ExplanationAccordion>
                          </Grid>
                        </Grid>
                      </DetailsForm>
                    </Grid>

                    <Grid item xs={12}>
                      <DetailsForm>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            container
                            alignItems='center'
                            justifyContent='space-between'
                          >
                            <Grid item xs={12}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#2780E3', fontWeight: 600 }}
                              >
                                Blend Cost
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Item
                            </Typography>
                          </Grid>

                          <Grid container item xs={12} sm={8}>
                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Setup Fee
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Cost per MSF
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Cost per MPCS
                              </Typography>
                            </Grid>
                          </Grid>

                          <Divider />

                          {values.quoteRevision?.quoteLineItem?.quoteBlendAdders?.map(
                            blendAdder => (
                              <>
                                <Grid item xs={12} sm={4}>
                                  <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                    {clearWhenDirty(format(blendAdder.adder))}
                                  </Typography>
                                </Grid>

                                <Grid container item xs={12} sm={8}>
                                  <Grid item xs={12} sm={4}>
                                    <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                      {clearWhenDirty(
                                        format(blendAdder.adderSetup, formats.currency)
                                      )}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={12} sm={4}>
                                    <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                      {clearWhenDirty(
                                        format(blendAdder.costPerMsf, formats.currency)
                                      )}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={12} sm={4}>
                                    <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                      {clearWhenDirty(
                                        format(blendAdder.costPerMpcs, formats.currency)
                                      )}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </>
                            )
                          )}

                          <Grid item xs={12} sm={4}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Subtotal
                            </Typography>
                          </Grid>

                          <Grid container item xs={12} sm={8}>
                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.blendCostSubtotalSetupFee,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.blendCostSubtotalCostPerMsf,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.blendCostSubtotalCostPerMpcs,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <ExplanationAccordion>
                              Setup Fee = Adder Setup for the selected Blend Adder from
                              Rates. || Cost per MSF = Adder Per MSF for the selected
                              Blend Adder from Rates. || Cost per MPCS = Cost per MSF *
                              Gross SQFT from Sheet Dimensions card.
                            </ExplanationAccordion>
                          </Grid>
                        </Grid>
                      </DetailsForm>
                    </Grid>

                    <Grid item xs={12}>
                      <DetailsForm>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            container
                            alignItems='center'
                            justifyContent='space-between'
                          >
                            <Grid item xs={12} sm={6}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#2780E3', fontWeight: 600 }}
                              >
                                Assembly
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.assemblyCustomerOrderQuantity'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.assemblyCustomerOrderQuantity'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.assemblyCustomerOrderQuantity'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.assemblyCustomerOrderQuantity'
                                  )
                                }
                                label='Customer order quantity'
                                name='quoteRevision.quoteLineItem.assemblyCustomerOrderQuantity'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: IntFormat as any
                                }}
                                value={
                                  values.quoteRevision?.quoteLineItem
                                    ?.assemblyCustomerOrderQuantity || ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              Order MSF:{' '}
                              {clearWhenDirty(
                                format(
                                  values.quoteRevision?.quoteLineItem?.assemblyOrderMsf
                                )
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <Divider />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.assemblyFinishedGoodUnitQuantityPerBundle'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.assemblyFinishedGoodUnitQuantityPerBundle'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.assemblyFinishedGoodUnitQuantityPerBundle'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.assemblyFinishedGoodUnitQuantityPerBundle'
                                  )
                                }
                                label='Finished good unit quantity per bundle'
                                name='quoteRevision.quoteLineItem.assemblyFinishedGoodUnitQuantityPerBundle'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: IntFormat as any
                                }}
                                value={
                                  values.quoteRevision?.quoteLineItem
                                    ?.assemblyFinishedGoodUnitQuantityPerBundle || ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              MSF:{' '}
                              {clearWhenDirty(
                                format(values.quoteRevision?.quoteLineItem?.assemblyMsf)
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <Divider />
                          </Grid>

                          <Grid item xs={12}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              Bundle Configuration:{' '}
                              {clearWhenDirty(
                                values.quoteRevision?.quoteLineItem
                                  ?.assemblyBundleConfiguration
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <Divider />
                          </Grid>

                          <Grid item xs={12}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              Basis Weight:{' '}
                              {clearWhenDirty(
                                format(
                                  values.quoteRevision?.quoteLineItem?.assemblyBasisWeight
                                )
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              Weight MPCS:{' '}
                              {clearWhenDirty(
                                format(
                                  values.quoteRevision?.quoteLineItem?.assemblyWeightMpcs
                                )
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              Ton per MPCS:{' '}
                              {clearWhenDirty(
                                format(
                                  values.quoteRevision?.quoteLineItem?.assemblyTonPerMpcs
                                )
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <Divider />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.assemblyOuts'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.assemblyOuts'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.assemblyOuts'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.assemblyOuts'
                                  )
                                }
                                label='Outs'
                                name='quoteRevision.quoteLineItem.assemblyOuts'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: IntFormat as any
                                }}
                                value={
                                  values.quoteRevision?.quoteLineItem?.assemblyOuts || ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.assemblyStyle'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.assemblyStyle'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.assemblyStyle'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.assemblyStyle'
                                  )
                                }
                                label='Style'
                                name='quoteRevision.quoteLineItem.assemblyStyle'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                select
                                value={
                                  values.quoteRevision?.quoteLineItem?.assemblyStyle || ''
                                }
                              >
                                {quoteAssemblyStyles.map(quoteAssemblyStyle => (
                                  <MenuItem
                                    key={quoteAssemblyStyle.id}
                                    value={quoteAssemblyStyle.name}
                                  >
                                    {quoteAssemblyStyle.name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.assemblyJoint'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.assemblyJoint'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.assemblyJoint'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.assemblyJoint'
                                  )
                                }
                                label='Joint'
                                name='quoteRevision.quoteLineItem.assemblyJoint'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                select
                                value={
                                  values.quoteRevision?.quoteLineItem?.assemblyJoint || ''
                                }
                              >
                                {quoteAssemblyJoints.map(quoteAssemblyJoint => (
                                  <MenuItem
                                    key={quoteAssemblyJoint.id}
                                    value={quoteAssemblyJoint.name}
                                  >
                                    {quoteAssemblyJoint.name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.assemblyUnitType'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.assemblyUnitType'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.assemblyUnitType'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.assemblyUnitType'
                                  )
                                }
                                label='Unit Type'
                                name='quoteRevision.quoteLineItem.assemblyUnitType'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                select
                                value={
                                  values.quoteRevision?.quoteLineItem?.assemblyUnitType ||
                                  ''
                                }
                              >
                                {quoteAssemblyUnitTypes.map(quoteAssemblyUnitType => (
                                  <MenuItem
                                    key={quoteAssemblyUnitType.id}
                                    value={quoteAssemblyUnitType.name}
                                  >
                                    {quoteAssemblyUnitType.name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <FormControl fullWidth>
                                <InputLabel id='quote-assembly-adders-label'>
                                  Adders
                                </InputLabel>

                                <Select
                                  labelId='quote-assembly-adders-label'
                                  disabled={
                                    isSubmitting ||
                                    !OPPORTUNITIES_ADD_EDIT ||
                                    values.opportunityLastStatus?.specialIdentifier ===
                                      'OPPORTUNITY_STATUS_SOLD'
                                  }
                                  multiple
                                  fullWidth
                                  value={
                                    values.quoteRevision?.quoteLineItem?.quoteAssemblyAdders?.map(
                                      quoteAssemblyAdder =>
                                        quoteAssemblyAdder.ratesAssemblyAdder?.id!
                                    ) ?? []
                                  }
                                  onChange={(
                                    event: SelectChangeEvent<string[]>,
                                    _child: React.ReactNode
                                  ) => {
                                    if (Array.isArray(event.target.value)) {
                                      setFieldValue(
                                        'quoteRevision.quoteLineItem.quoteAssemblyAdders',
                                        ratesAssemblyAdders
                                          .filter(ratesAssemblyAdder =>
                                            event.target.value.includes(
                                              ratesAssemblyAdder.id!
                                            )
                                          )
                                          .map(ratesAssemblyAdder => ({
                                            ...new QuoteAssemblyAdderDto(),
                                            ratesAssemblyAdder
                                          }))
                                      )
                                    }
                                  }}
                                  input={<OutlinedInput label='Adders' />}
                                  renderValue={selected => (
                                    <Box
                                      sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                                    >
                                      {selected.map(ratesAssemblyAdderId => (
                                        <Chip
                                          key={ratesAssemblyAdderId}
                                          label={
                                            ratesAssemblyAdders?.filter(
                                              ratesAssemblyAdder =>
                                                ratesAssemblyAdder.id ===
                                                ratesAssemblyAdderId
                                            )[0]?.adder ?? ''
                                          }
                                        />
                                      ))}
                                    </Box>
                                  )}
                                  MenuProps={MenuProps}
                                >
                                  {ratesAssemblyAdders
                                    ?.filter(
                                      ratesAssemblyAdder =>
                                        values.quoteRevision?.quoteLineItem?.vendor &&
                                        ratesAssemblyAdder.company?.name ===
                                          values.quoteRevision?.quoteLineItem?.vendor &&
                                        (!ratesAssemblyAdder.ect ||
                                          ratesAssemblyAdder.ect ===
                                            values.quoteRevision?.quoteLineItem
                                              ?.ectMullen)
                                    )
                                    ?.map(ratesAssemblyAdder => (
                                      <MenuItem
                                        key={ratesAssemblyAdder.id}
                                        value={ratesAssemblyAdder.id}
                                      >
                                        <Checkbox
                                          checked={
                                            values.quoteRevision?.quoteLineItem?.quoteAssemblyAdders
                                              ?.map(
                                                quoteAssemblyAdder =>
                                                  quoteAssemblyAdder!.ratesAssemblyAdder!
                                                    .id
                                              )
                                              .indexOf(ratesAssemblyAdder!.id)! > -1
                                          }
                                        />

                                        <ListItemText
                                          primary={ratesAssemblyAdder.adder}
                                        />
                                      </MenuItem>
                                    ))}
                                </Select>

                                {getIn(
                                  touched,
                                  'quoteRevision.quoteLineItem.quoteAssemblyAdders'
                                ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.quoteAssemblyAdders'
                                  ) && (
                                    <FormHelperText error>
                                      {getIn(
                                        touched,
                                        'quoteRevision.quoteLineItem.quoteAssemblyAdders'
                                      ) &&
                                        getIn(
                                          errors,
                                          'quoteRevision.quoteLineItem.quoteAssemblyAdders'
                                        )}
                                    </FormHelperText>
                                  )}
                              </FormControl>
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12}>
                            <Grid item xs={12}>
                              <ExplanationAccordion>
                                Order MSF = (((Gross Width from the Sheet Dimensions card
                                * Gross Length from the Sheet Dimensions card)/144) *
                                Customer order quantity from Assembly card)) / 1000. Note:
                                Rounded to four decimal places. The "Order MSF" value is
                                used by the Quote Builder to know which MSF range to use
                                from the Rates - Paper tab (e.g., MSF&gt;0&lt;5,
                                MSF&gt;=5&lt;10, etc.) for Paper cost. || MSF = (((Gross
                                Width from the Sheet Dimensions card * Gross Length from
                                the Sheet Dimensions card/144) * Finished good unit
                                quantity per bundle from Assembly card)/1000). Note:
                                Rounded to four decimal places. || Bundle configuration =
                                Customer order quantity divided by finished good unit
                                quantity per bundle with the any remainder in the final
                                bundle. || Basis Weight: Basis weight value in paper rates
                                || Weight MPCS: Basis Weight * Gross SQFT. Note: Rounded
                                to four decimal places. || Ton per MPCS = Weight MPCS from
                                Assembly card / 2,000. Note: Rounded to four decimal
                                places. || Adder drop down is populated from the rates
                                table based on the selected vendor on the paper card. ||
                                The Customer order quantity, Finished good unit quantity
                                per bundle, Outs, and Unit Type fields on this card are
                                required.
                              </ExplanationAccordion>
                            </Grid>
                          </Grid>
                        </Grid>
                      </DetailsForm>
                    </Grid>

                    {/* ASSEMBLY COSTS CARD */}
                    {/* ASSEMBLY COSTS CARD */}
                    {/* ASSEMBLY COSTS CARD */}
                    <Grid item xs={12}>
                      <DetailsForm>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            container
                            alignItems='center'
                            justifyContent='space-between'
                          >
                            <Grid item xs={12}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#2780E3', fontWeight: 600 }}
                              >
                                Assembly Cost
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Item
                            </Typography>
                          </Grid>

                          <Grid container item xs={12} sm={8}>
                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Setup Fee
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Cost per MSF
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Cost per MPCS
                              </Typography>
                            </Grid>
                          </Grid>

                          {values.quoteRevision?.quoteLineItem?.quoteAssemblyAdders?.map(
                            assemblyAdder => (
                              <>
                                <Grid item xs={12} sm={4}>
                                  <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                    {clearWhenDirty(format(assemblyAdder.adder))}
                                  </Typography>
                                </Grid>

                                <Grid container item xs={12} sm={8}>
                                  <Grid item xs={12} sm={4}>
                                    <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                      {clearWhenDirty(
                                        format(assemblyAdder.adderSetup, formats.currency)
                                      )}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={12} sm={4}>
                                    <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                      {clearWhenDirty(
                                        format(assemblyAdder.costPerMsf, formats.currency)
                                      )}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={12} sm={4}>
                                    <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                      {clearWhenDirty(
                                        format(
                                          assemblyAdder.costPerMpcs,
                                          formats.currency
                                        )
                                      )}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </>
                            )
                          )}

                          <Grid item xs={12} sm={4}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Subtotal
                            </Typography>
                          </Grid>

                          <Grid container item xs={12} sm={8}>
                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.assemblyCostSubtotalSetupFee,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.assemblyCostSubtotalCostPerMsf,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.assemblyCostSubtotalCostPerMpcs,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <ExplanationAccordion>
                              Setup Fee = Adder Setup for the selected Assembly Adder from
                              Rates. || Cost per MSF = Adder Per MSF for the selected
                              Assembly Adder from Rates. || Cost per MPCS = Cost per MSF *
                              Gross SQFT from Sheet Dimensions card.
                            </ExplanationAccordion>
                          </Grid>
                        </Grid>
                      </DetailsForm>
                    </Grid>

                    <Grid item xs={12}>
                      <DetailsForm>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            container
                            alignItems='center'
                            justifyContent='space-between'
                          >
                            <Grid item xs={12} sm={6}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#2780E3', fontWeight: 600 }}
                              >
                                Scoring
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring1'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.scoringCorrugatorScoring1'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring1'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring1'
                                  )
                                }
                                label='Corrugator Scoring 1'
                                name='quoteRevision.quoteLineItem.scoringCorrugatorScoring1'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: DecimalFormat as any
                                }}
                                value={
                                  values.quoteRevision?.quoteLineItem
                                    ?.scoringCorrugatorScoring1 || ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring2'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.scoringCorrugatorScoring2'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring2'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring2'
                                  )
                                }
                                label='Corrugator Scoring 2'
                                name='quoteRevision.quoteLineItem.scoringCorrugatorScoring2'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: DecimalFormat as any
                                }}
                                value={
                                  values.quoteRevision?.quoteLineItem
                                    ?.scoringCorrugatorScoring2 || ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring3'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.scoringCorrugatorScoring3'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring3'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring3'
                                  )
                                }
                                label='Corrugator Scoring 3'
                                name='quoteRevision.quoteLineItem.scoringCorrugatorScoring3'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: DecimalFormat as any
                                }}
                                value={
                                  values.quoteRevision?.quoteLineItem
                                    ?.scoringCorrugatorScoring3 || ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring4'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.scoringCorrugatorScoring4'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring4'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring4'
                                  )
                                }
                                label='Corrugator Scoring 4'
                                name='quoteRevision.quoteLineItem.scoringCorrugatorScoring4'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: DecimalFormat as any
                                }}
                                value={
                                  values.quoteRevision?.quoteLineItem
                                    ?.scoringCorrugatorScoring4 || ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring5'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.scoringCorrugatorScoring5'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring5'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring5'
                                  )
                                }
                                label='Corrugator Scoring 5'
                                name='quoteRevision.quoteLineItem.scoringCorrugatorScoring5'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: DecimalFormat as any
                                }}
                                value={
                                  values.quoteRevision?.quoteLineItem
                                    ?.scoringCorrugatorScoring5 || ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring6'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.scoringCorrugatorScoring6'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring6'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring6'
                                  )
                                }
                                label='Corrugator Scoring 6'
                                name='quoteRevision.quoteLineItem.scoringCorrugatorScoring6'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: DecimalFormat as any
                                }}
                                value={
                                  values.quoteRevision?.quoteLineItem
                                    ?.scoringCorrugatorScoring6 || ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring7'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.scoringCorrugatorScoring7'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring7'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring7'
                                  )
                                }
                                label='Corrugator Scoring 7'
                                name='quoteRevision.quoteLineItem.scoringCorrugatorScoring7'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: DecimalFormat as any
                                }}
                                value={
                                  values.quoteRevision?.quoteLineItem
                                    ?.scoringCorrugatorScoring7 || ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring8'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.scoringCorrugatorScoring8'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring8'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring8'
                                  )
                                }
                                label='Corrugator Scoring 8'
                                name='quoteRevision.quoteLineItem.scoringCorrugatorScoring8'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: DecimalFormat as any
                                }}
                                value={
                                  values.quoteRevision?.quoteLineItem
                                    ?.scoringCorrugatorScoring8 || ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring9'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.scoringCorrugatorScoring9'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring9'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring9'
                                  )
                                }
                                label='Corrugator Scoring 9'
                                name='quoteRevision.quoteLineItem.scoringCorrugatorScoring9'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: DecimalFormat as any
                                }}
                                value={
                                  values.quoteRevision?.quoteLineItem
                                    ?.scoringCorrugatorScoring9 || ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring10'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.scoringCorrugatorScoring10'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring10'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring10'
                                  )
                                }
                                label='Corrugator Scoring 10'
                                name='quoteRevision.quoteLineItem.scoringCorrugatorScoring10'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: DecimalFormat as any
                                }}
                                value={
                                  values.quoteRevision?.quoteLineItem
                                    ?.scoringCorrugatorScoring10 || ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring11'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.scoringCorrugatorScoring11'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring11'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring11'
                                  )
                                }
                                label='Corrugator Scoring 11'
                                name='quoteRevision.quoteLineItem.scoringCorrugatorScoring11'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: DecimalFormat as any
                                }}
                                value={
                                  values.quoteRevision?.quoteLineItem
                                    ?.scoringCorrugatorScoring11 || ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring12'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.scoringCorrugatorScoring12'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring12'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring12'
                                  )
                                }
                                label='Corrugator Scoring 12'
                                name='quoteRevision.quoteLineItem.scoringCorrugatorScoring12'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: DecimalFormat as any
                                }}
                                value={
                                  values.quoteRevision?.quoteLineItem
                                    ?.scoringCorrugatorScoring12 || ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring13'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.scoringCorrugatorScoring13'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring13'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring13'
                                  )
                                }
                                label='Corrugator Scoring 13'
                                name='quoteRevision.quoteLineItem.scoringCorrugatorScoring13'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: DecimalFormat as any
                                }}
                                value={
                                  values.quoteRevision?.quoteLineItem
                                    ?.scoringCorrugatorScoring13 || ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring14'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.scoringCorrugatorScoring14'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring14'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring14'
                                  )
                                }
                                label='Corrugator Scoring 14'
                                name='quoteRevision.quoteLineItem.scoringCorrugatorScoring14'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: DecimalFormat as any
                                }}
                                value={
                                  values.quoteRevision?.quoteLineItem
                                    ?.scoringCorrugatorScoring14 || ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring15'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.scoringCorrugatorScoring15'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring15'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring15'
                                  )
                                }
                                label='Corrugator Scoring 15'
                                name='quoteRevision.quoteLineItem.scoringCorrugatorScoring15'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: DecimalFormat as any
                                }}
                                value={
                                  values.quoteRevision?.quoteLineItem
                                    ?.scoringCorrugatorScoring15 || ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring16'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.scoringCorrugatorScoring16'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring16'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.scoringCorrugatorScoring16'
                                  )
                                }
                                label='Corrugator Scoring 16'
                                name='quoteRevision.quoteLineItem.scoringCorrugatorScoring16'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: DecimalFormat as any
                                }}
                                value={
                                  values.quoteRevision?.quoteLineItem
                                    ?.scoringCorrugatorScoring16 || ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <FormControl fullWidth>
                                <InputLabel id='quote-scoring-adders-label'>
                                  Adders
                                </InputLabel>

                                <Select
                                  labelId='quote-scoring-adders-label'
                                  disabled={
                                    isSubmitting ||
                                    !OPPORTUNITIES_ADD_EDIT ||
                                    values.opportunityLastStatus?.specialIdentifier ===
                                      'OPPORTUNITY_STATUS_SOLD'
                                  }
                                  multiple
                                  fullWidth
                                  value={
                                    values.quoteRevision?.quoteLineItem?.quoteScoringAdders?.map(
                                      quoteScoringAdder =>
                                        quoteScoringAdder.ratesScoringAdder?.id!
                                    ) ?? []
                                  }
                                  onChange={(
                                    event: SelectChangeEvent<string[]>,
                                    _child: React.ReactNode
                                  ) => {
                                    if (Array.isArray(event.target.value)) {
                                      setFieldValue(
                                        'quoteRevision.quoteLineItem.quoteScoringAdders',
                                        ratesScoringAdders
                                          .filter(ratesScoringAdder =>
                                            event.target.value.includes(
                                              ratesScoringAdder.id!
                                            )
                                          )
                                          .map(ratesScoringAdder => ({
                                            ...new QuoteScoringAdderDto(),
                                            ratesScoringAdder
                                          }))
                                      )
                                    }
                                  }}
                                  input={<OutlinedInput label='Adders' />}
                                  renderValue={selected => (
                                    <Box
                                      sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                                    >
                                      {selected.map(ratesScoringAdderId => (
                                        <Chip
                                          key={ratesScoringAdderId}
                                          label={
                                            ratesScoringAdders?.filter(
                                              ratesScoringAdder =>
                                                ratesScoringAdder.id ===
                                                ratesScoringAdderId
                                            )[0]?.adder ?? ''
                                          }
                                        />
                                      ))}
                                    </Box>
                                  )}
                                  MenuProps={MenuProps}
                                >
                                  {ratesScoringAdders
                                    ?.filter(
                                      ratesScoringAdder =>
                                        values.quoteRevision?.quoteLineItem?.vendor &&
                                        ratesScoringAdder.company?.name ===
                                          values.quoteRevision?.quoteLineItem?.vendor &&
                                        (!ratesScoringAdder.ect ||
                                          ratesScoringAdder.ect ===
                                            values.quoteRevision?.quoteLineItem
                                              ?.ectMullen)
                                    )
                                    ?.map(ratesScoringAdder => (
                                      <MenuItem
                                        key={ratesScoringAdder.id}
                                        value={ratesScoringAdder.id}
                                      >
                                        <Checkbox
                                          checked={
                                            values.quoteRevision?.quoteLineItem?.quoteScoringAdders
                                              ?.map(
                                                quoteScoringAdder =>
                                                  quoteScoringAdder!.ratesScoringAdder!.id
                                              )
                                              .indexOf(ratesScoringAdder!.id)! > -1
                                          }
                                        />

                                        <ListItemText primary={ratesScoringAdder.adder} />
                                      </MenuItem>
                                    ))}
                                </Select>

                                {getIn(
                                  touched,
                                  'quoteRevision.quoteLineItem.quoteScoringAdders'
                                ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.quoteScoringAdders'
                                  ) && (
                                    <FormHelperText error>
                                      {getIn(
                                        touched,
                                        'quoteRevision.quoteLineItem.quoteScoringAdders'
                                      ) &&
                                        getIn(
                                          errors,
                                          'quoteRevision.quoteLineItem.quoteScoringAdders'
                                        )}
                                    </FormHelperText>
                                  )}
                              </FormControl>
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12}>
                            <ExplanationAccordion>
                              Note: All fields allow up to four decimal places.||Scoring
                              is not a width X length pair.||Adder drop down is populated
                              from the rates table based on the selected vendor on the
                              paper card.||80% of JackPack quotes will have 6 scores.||No
                              fields on this card are required.
                            </ExplanationAccordion>
                            <ExplanationAccordion development>
                              Scoring values need to be displayed in a row (i.e. not
                              columns) when created on the vendor order PDF.
                            </ExplanationAccordion>
                          </Grid>
                        </Grid>
                      </DetailsForm>
                    </Grid>

                    {/* SCORING COSTS CARD */}
                    {/* SCORING COSTS CARD */}
                    {/* SCORING COSTS CARD */}
                    <Grid item xs={12}>
                      <DetailsForm>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            container
                            alignItems='center'
                            justifyContent='space-between'
                          >
                            <Grid item xs={12}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#2780E3', fontWeight: 600 }}
                              >
                                Scoring Cost
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Item
                            </Typography>
                          </Grid>

                          <Grid container item xs={12} sm={8}>
                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Setup Fee
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Cost per MSF
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Cost per MPCS
                              </Typography>
                            </Grid>
                          </Grid>

                          {values.quoteRevision?.quoteLineItem?.quoteScoringAdders?.map(
                            scoringAdder => (
                              <>
                                <Grid item xs={12} sm={4}>
                                  <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                    {clearWhenDirty(format(scoringAdder.adder))}
                                  </Typography>
                                </Grid>

                                <Grid container item xs={12} sm={8}>
                                  <Grid item xs={12} sm={4}>
                                    <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                      {clearWhenDirty(
                                        format(scoringAdder.adderSetup, formats.currency)
                                      )}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={12} sm={4}>
                                    <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                      {clearWhenDirty(
                                        format(scoringAdder.costPerMsf, formats.currency)
                                      )}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={12} sm={4}>
                                    <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                      {clearWhenDirty(
                                        format(scoringAdder.costPerMpcs, formats.currency)
                                      )}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </>
                            )
                          )}

                          <Grid item xs={12} sm={4}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Subtotal
                            </Typography>
                          </Grid>

                          <Grid container item xs={12} sm={8}>
                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.scoringCostSubtotalSetupFee,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.scoringCostSubtotalCostPerMsf,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.scoringCostSubtotalCostPerMpcs,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <ExplanationAccordion>
                              Setup Fee = Adder Setup for the selected Scoring Adder from
                              Rates. || Cost per MSF = Adder Per MSF for the selected
                              Scoring Adder from Rates. || Cost per MPCS = Cost per MSF *
                              Gross SQFT from Sheet Dimensions card.
                            </ExplanationAccordion>
                          </Grid>
                        </Grid>
                      </DetailsForm>
                    </Grid>

                    {/* TOOLING CARD */}
                    {/* TOOLING CARD */}
                    {/* TOOLING CARD */}
                    <Grid item xs={12}>
                      <DetailsForm>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            container
                            alignItems='center'
                            justifyContent='space-between'
                          >
                            <Grid item xs={12} sm={6}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#2780E3', fontWeight: 600 }}
                              >
                                Tooling
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.toolingCuttingDieCost'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.toolingCuttingDieCost'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.toolingCuttingDieCost'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.toolingCuttingDieCost'
                                  )
                                }
                                label='Cutting Die Cost'
                                name='quoteRevision.quoteLineItem.toolingCuttingDieCost'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: DecimalFormat as any
                                }}
                                value={
                                  values.quoteRevision?.quoteLineItem
                                    ?.toolingCuttingDieCost || ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.toolingDieQuantity'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.toolingDieQuantity'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.toolingDieQuantity'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.toolingDieQuantity'
                                  )
                                }
                                label='Cutting Die Quantity'
                                name='quoteRevision.quoteLineItem.toolingDieQuantity'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: IntFormat as any
                                }}
                                value={
                                  values.quoteRevision?.quoteLineItem
                                    ?.toolingDieQuantity || ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12}>
                            <Typography
                              variant='body1'
                              color={
                                Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.cuttingDieOwnership'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.cuttingDieOwnership'
                                    )
                                )
                                  ? 'error'
                                  : undefined
                              }
                              sx={
                                Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.cuttingDieOwnership'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.cuttingDieOwnership'
                                    )
                                )
                                  ? undefined
                                  : {
                                      color: '#7F7F7F'
                                    }
                              }
                            >
                              <Tooltip
                                title={
                                  values.opportunityLastStatus?.specialIdentifier ===
                                  'OPPORTUNITY_STATUS_SOLD'
                                    ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                    : OPPORTUNITIES_ADD_EDIT
                                    ? ''
                                    : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                                }
                                placement='bottom'
                              >
                                <FormControl
                                  error={Boolean(
                                    getIn(
                                      touched,
                                      'quoteRevision.quoteLineItem.cuttingDieOwnership'
                                    ) &&
                                      getIn(
                                        errors,
                                        'quoteRevision.quoteLineItem.cuttingDieOwnership'
                                      )
                                  )}
                                >
                                  Cutting Die Ownership
                                  <RadioGroup
                                    name='quoteRevision.quoteLineItem.cuttingDieOwnership'
                                    row
                                    value={
                                      values.quoteRevision?.quoteLineItem
                                        ?.cuttingDieOwnership
                                    }
                                  >
                                    <FormControlLabel
                                      disabled={
                                        isSubmitting ||
                                        !OPPORTUNITIES_ADD_EDIT ||
                                        values.opportunityLastStatus
                                          ?.specialIdentifier ===
                                          'OPPORTUNITY_STATUS_SOLD'
                                      }
                                      value='NorthGate'
                                      onClick={e => {
                                        // These radio buttons set the 'cuttingDieOwnership' value to one of two hard-coded strings
                                        setFieldValue(
                                          'quoteRevision.quoteLineItem.cuttingDieOwnership',
                                          'NorthGate'
                                        )
                                      }}
                                      control={<Radio />}
                                      label='NorthGate'
                                    />
                                    <FormControlLabel
                                      disabled={
                                        isSubmitting ||
                                        !OPPORTUNITIES_ADD_EDIT ||
                                        values.opportunityLastStatus
                                          ?.specialIdentifier ===
                                          'OPPORTUNITY_STATUS_SOLD'
                                      }
                                      value='Client'
                                      onClick={e => {
                                        // These radio buttons set the 'cuttingDieOwnership' value to one of two hard-coded strings
                                        setFieldValue(
                                          'quoteRevision.quoteLineItem.cuttingDieOwnership',
                                          'Client'
                                        )
                                      }}
                                      control={<Radio />}
                                      label='Client'
                                    />
                                  </RadioGroup>
                                  <FormHelperText>
                                    {getIn(
                                      touched,
                                      'quoteRevision.quoteLineItem.cuttingDieOwnership'
                                    ) &&
                                      getIn(
                                        errors,
                                        'quoteRevision.quoteLineItem.cuttingDieOwnership'
                                      )}
                                  </FormHelperText>
                                </FormControl>
                              </Tooltip>
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.toolingPrint'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.toolingPrint'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.toolingPrint'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.toolingPrint'
                                  )
                                }
                                label='Print'
                                name='quoteRevision.quoteLineItem.toolingPrint'
                                onBlur={handleBlur}
                                onChange={e => {
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.toolingPrint',
                                    e.target.value === 'true'
                                  )

                                  // These fields are directly dependent on the toolingPrint field
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.toolingColorsCount',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.toolingPrintPlateCost',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.toolingPrintPlateQuantity',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.toolingPrintPanelsCount',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.toolingPrintPlateOwnership',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.inksCoveragePercentage',
                                    undefined
                                  )
                                  // These fields are indirectly dependent on the toolingPrint field via the "Print Panels (Count)" and "Colors (Count)" fields
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.ratesInkPanel1Color1',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.ratesInkPanel1Color2',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.ratesInkPanel1Color3',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.ratesInkPanel1Color4',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.ratesInkPanel2Color1',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.ratesInkPanel2Color2',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.ratesInkPanel2Color3',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.ratesInkPanel2Color4',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.ratesInkPanel3Color1',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.ratesInkPanel3Color2',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.ratesInkPanel3Color3',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.ratesInkPanel3Color4',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.ratesInkPanel4Color1',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.ratesInkPanel4Color2',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.ratesInkPanel4Color3',
                                    undefined
                                  )
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.ratesInkPanel4Color4',
                                    undefined
                                  )
                                }}
                                select
                                value={
                                  values?.quoteRevision?.quoteLineItem?.toolingPrint ===
                                  undefined
                                    ? ''
                                    : values?.quoteRevision?.quoteLineItem?.toolingPrint
                                }
                              >
                                <MenuItem value='true'>Yes</MenuItem>

                                <MenuItem value='false'>No</MenuItem>
                              </TextField>
                            </Tooltip>
                          </Grid>

                          {values.quoteRevision?.quoteLineItem?.toolingPrint && (
                            <>
                              <Grid item xs={12} sm={6}>
                                <Tooltip
                                  title={
                                    values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                      ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                      : OPPORTUNITIES_ADD_EDIT
                                      ? ''
                                      : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                                  }
                                  placement='bottom'
                                >
                                  <TextField
                                    disabled={
                                      isSubmitting ||
                                      !OPPORTUNITIES_ADD_EDIT ||
                                      values.opportunityLastStatus?.specialIdentifier ===
                                        'OPPORTUNITY_STATUS_SOLD'
                                    }
                                    error={Boolean(
                                      getIn(
                                        touched,
                                        'quoteRevision.quoteLineItem.toolingColorsCount'
                                      ) &&
                                        getIn(
                                          errors,
                                          'quoteRevision.quoteLineItem.toolingColorsCount'
                                        )
                                    )}
                                    fullWidth
                                    helperText={
                                      getIn(
                                        touched,
                                        'quoteRevision.quoteLineItem.toolingColorsCount'
                                      ) &&
                                      getIn(
                                        errors,
                                        'quoteRevision.quoteLineItem.toolingColorsCount'
                                      )
                                    }
                                    label='Colors (Count)'
                                    name='quoteRevision.quoteLineItem.toolingColorsCount'
                                    onBlur={handleBlur}
                                    onChange={e => {
                                      setFieldValue(
                                        'quoteRevision.quoteLineItem.toolingColorsCount',
                                        e.target.value
                                      )
                                      setFieldValue(
                                        'quoteRevision.quoteLineItem.ratesInkPanel1Color1',
                                        undefined
                                      )
                                      setFieldValue(
                                        'quoteRevision.quoteLineItem.ratesInkPanel1Color2',
                                        undefined
                                      )
                                      setFieldValue(
                                        'quoteRevision.quoteLineItem.ratesInkPanel1Color3',
                                        undefined
                                      )
                                      setFieldValue(
                                        'quoteRevision.quoteLineItem.ratesInkPanel1Color4',
                                        undefined
                                      )
                                      setFieldValue(
                                        'quoteRevision.quoteLineItem.ratesInkPanel2Color1',
                                        undefined
                                      )
                                      setFieldValue(
                                        'quoteRevision.quoteLineItem.ratesInkPanel2Color2',
                                        undefined
                                      )
                                      setFieldValue(
                                        'quoteRevision.quoteLineItem.ratesInkPanel2Color3',
                                        undefined
                                      )
                                      setFieldValue(
                                        'quoteRevision.quoteLineItem.ratesInkPanel2Color4',
                                        undefined
                                      )
                                      setFieldValue(
                                        'quoteRevision.quoteLineItem.ratesInkPanel3Color1',
                                        undefined
                                      )
                                      setFieldValue(
                                        'quoteRevision.quoteLineItem.ratesInkPanel3Color2',
                                        undefined
                                      )
                                      setFieldValue(
                                        'quoteRevision.quoteLineItem.ratesInkPanel3Color3',
                                        undefined
                                      )
                                      setFieldValue(
                                        'quoteRevision.quoteLineItem.ratesInkPanel3Color4',
                                        undefined
                                      )
                                      setFieldValue(
                                        'quoteRevision.quoteLineItem.ratesInkPanel4Color1',
                                        undefined
                                      )
                                      setFieldValue(
                                        'quoteRevision.quoteLineItem.ratesInkPanel4Color2',
                                        undefined
                                      )
                                      setFieldValue(
                                        'quoteRevision.quoteLineItem.ratesInkPanel4Color3',
                                        undefined
                                      )
                                      setFieldValue(
                                        'quoteRevision.quoteLineItem.ratesInkPanel4Color4',
                                        undefined
                                      )
                                    }}
                                    select
                                    value={
                                      values?.quoteRevision?.quoteLineItem
                                        ?.toolingColorsCount ?? ''
                                    }
                                  >
                                    <MenuItem value={1}>1</MenuItem>

                                    <MenuItem value={2}>2</MenuItem>

                                    <MenuItem value={3}>3</MenuItem>

                                    <MenuItem value={4}>4</MenuItem>
                                  </TextField>
                                </Tooltip>
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                <Tooltip
                                  title={
                                    values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                      ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                      : OPPORTUNITIES_ADD_EDIT
                                      ? ''
                                      : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                                  }
                                  placement='bottom'
                                >
                                  <TextField
                                    disabled={
                                      isSubmitting ||
                                      !OPPORTUNITIES_ADD_EDIT ||
                                      values.opportunityLastStatus?.specialIdentifier ===
                                        'OPPORTUNITY_STATUS_SOLD'
                                    }
                                    error={Boolean(
                                      getIn(
                                        touched,
                                        'quoteRevision.quoteLineItem.toolingPrintPlateCost'
                                      ) &&
                                        getIn(
                                          errors,
                                          'quoteRevision.quoteLineItem.toolingPrintPlateCost'
                                        )
                                    )}
                                    fullWidth
                                    helperText={
                                      getIn(
                                        touched,
                                        'quoteRevision.quoteLineItem.toolingPrintPlateCost'
                                      ) &&
                                      getIn(
                                        errors,
                                        'quoteRevision.quoteLineItem.toolingPrintPlateCost'
                                      )
                                    }
                                    label='Print plate cost'
                                    name='quoteRevision.quoteLineItem.toolingPrintPlateCost'
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={
                                      values.quoteRevision?.quoteLineItem
                                        ?.toolingPrintPlateCost || ''
                                    }
                                  />
                                </Tooltip>
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                <Tooltip
                                  title={
                                    values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                      ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                      : OPPORTUNITIES_ADD_EDIT
                                      ? ''
                                      : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                                  }
                                  placement='bottom'
                                >
                                  <TextField
                                    disabled={
                                      isSubmitting ||
                                      !OPPORTUNITIES_ADD_EDIT ||
                                      values.opportunityLastStatus?.specialIdentifier ===
                                        'OPPORTUNITY_STATUS_SOLD'
                                    }
                                    error={Boolean(
                                      getIn(
                                        touched,
                                        'quoteRevision.quoteLineItem.toolingPrintPlateQuantity'
                                      ) &&
                                        getIn(
                                          errors,
                                          'quoteRevision.quoteLineItem.toolingPrintPlateQuantity'
                                        )
                                    )}
                                    fullWidth
                                    helperText={
                                      getIn(
                                        touched,
                                        'quoteRevision.quoteLineItem.toolingPrintPlateQuantity'
                                      ) &&
                                      getIn(
                                        errors,
                                        'quoteRevision.quoteLineItem.toolingPrintPlateQuantity'
                                      )
                                    }
                                    label='Print plate quantity'
                                    name='quoteRevision.quoteLineItem.toolingPrintPlateQuantity'
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    InputProps={{
                                      inputComponent: IntFormat as any
                                    }}
                                    value={
                                      values.quoteRevision?.quoteLineItem
                                        ?.toolingPrintPlateQuantity || ''
                                    }
                                  />
                                </Tooltip>
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                <Tooltip
                                  title={
                                    values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                      ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                      : OPPORTUNITIES_ADD_EDIT
                                      ? ''
                                      : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                                  }
                                  placement='bottom'
                                >
                                  <FormControl fullWidth>
                                    <TextField
                                      disabled={
                                        isSubmitting ||
                                        !OPPORTUNITIES_ADD_EDIT ||
                                        values.opportunityLastStatus
                                          ?.specialIdentifier ===
                                          'OPPORTUNITY_STATUS_SOLD'
                                      }
                                      error={Boolean(
                                        getIn(
                                          touched,
                                          'quoteRevision.quoteLineItem.toolingPrintPanelsCount'
                                        ) &&
                                          getIn(
                                            errors,
                                            'quoteRevision.quoteLineItem.toolingPrintPanelsCount'
                                          )
                                      )}
                                      fullWidth
                                      helperText={
                                        getIn(
                                          touched,
                                          'quoteRevision.quoteLineItem.toolingPrintPanelsCount'
                                        ) &&
                                        getIn(
                                          errors,
                                          'quoteRevision.quoteLineItem.toolingPrintPanelsCount'
                                        )
                                      }
                                      label='Print panels (Count)'
                                      name='quoteRevision.quoteLineItem.toolingPrintPanelsCount'
                                      onBlur={handleBlur}
                                      onChange={e => {
                                        setFieldValue(
                                          'quoteRevision.quoteLineItem.toolingPrintPanelsCount',
                                          e.target.value
                                        )
                                        setFieldValue(
                                          'quoteRevision.quoteLineItem.ratesInkPanel1Color1',
                                          undefined
                                        )
                                        setFieldValue(
                                          'quoteRevision.quoteLineItem.ratesInkPanel1Color2',
                                          undefined
                                        )
                                        setFieldValue(
                                          'quoteRevision.quoteLineItem.ratesInkPanel1Color3',
                                          undefined
                                        )
                                        setFieldValue(
                                          'quoteRevision.quoteLineItem.ratesInkPanel1Color4',
                                          undefined
                                        )
                                        setFieldValue(
                                          'quoteRevision.quoteLineItem.ratesInkPanel2Color1',
                                          undefined
                                        )
                                        setFieldValue(
                                          'quoteRevision.quoteLineItem.ratesInkPanel2Color2',
                                          undefined
                                        )
                                        setFieldValue(
                                          'quoteRevision.quoteLineItem.ratesInkPanel2Color3',
                                          undefined
                                        )
                                        setFieldValue(
                                          'quoteRevision.quoteLineItem.ratesInkPanel2Color4',
                                          undefined
                                        )
                                        setFieldValue(
                                          'quoteRevision.quoteLineItem.ratesInkPanel3Color1',
                                          undefined
                                        )
                                        setFieldValue(
                                          'quoteRevision.quoteLineItem.ratesInkPanel3Color2',
                                          undefined
                                        )
                                        setFieldValue(
                                          'quoteRevision.quoteLineItem.ratesInkPanel3Color3',
                                          undefined
                                        )
                                        setFieldValue(
                                          'quoteRevision.quoteLineItem.ratesInkPanel3Color4',
                                          undefined
                                        )
                                        setFieldValue(
                                          'quoteRevision.quoteLineItem.ratesInkPanel4Color1',
                                          undefined
                                        )
                                        setFieldValue(
                                          'quoteRevision.quoteLineItem.ratesInkPanel4Color2',
                                          undefined
                                        )
                                        setFieldValue(
                                          'quoteRevision.quoteLineItem.ratesInkPanel4Color3',
                                          undefined
                                        )
                                        setFieldValue(
                                          'quoteRevision.quoteLineItem.ratesInkPanel4Color4',
                                          undefined
                                        )
                                      }}
                                      select
                                      value={
                                        values?.quoteRevision?.quoteLineItem
                                          ?.toolingPrintPanelsCount ?? ''
                                      }
                                    >
                                      <MenuItem value={1}>1</MenuItem>

                                      <MenuItem value={2}>2</MenuItem>

                                      <MenuItem value={3}>3</MenuItem>

                                      <MenuItem value={4}>4</MenuItem>
                                    </TextField>
                                  </FormControl>
                                </Tooltip>
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                <Typography
                                  variant='body1'
                                  color={
                                    Boolean(
                                      getIn(
                                        touched,
                                        'quoteRevision.quoteLineItem.toolingPrintPlateOwnership'
                                      ) &&
                                        getIn(
                                          errors,
                                          'quoteRevision.quoteLineItem.toolingPrintPlateOwnership'
                                        )
                                    )
                                      ? 'error'
                                      : undefined
                                  }
                                  sx={
                                    Boolean(
                                      getIn(
                                        touched,
                                        'quoteRevision.quoteLineItem.toolingPrintPlateOwnership'
                                      ) &&
                                        getIn(
                                          errors,
                                          'quoteRevision.quoteLineItem.toolingPrintPlateOwnership'
                                        )
                                    )
                                      ? undefined
                                      : {
                                          color: '#7F7F7F'
                                        }
                                  }
                                >
                                  <Tooltip
                                    title={
                                      values.opportunityLastStatus?.specialIdentifier ===
                                      'OPPORTUNITY_STATUS_SOLD'
                                        ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                        : OPPORTUNITIES_ADD_EDIT
                                        ? ''
                                        : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                                    }
                                    placement='bottom'
                                  >
                                    <FormControl
                                      error={Boolean(
                                        getIn(
                                          touched,
                                          'quoteRevision.quoteLineItem.toolingPrintPlateOwnership'
                                        ) &&
                                          getIn(
                                            errors,
                                            'quoteRevision.quoteLineItem.toolingPrintPlateOwnership'
                                          )
                                      )}
                                    >
                                      Print Plate Ownership
                                      <RadioGroup
                                        name='quoteRevision.quoteLineItem.toolingPrintPlateOwnership'
                                        row
                                        value={
                                          values.quoteRevision?.quoteLineItem
                                            ?.toolingPrintPlateOwnership
                                        }
                                      >
                                        <FormControlLabel
                                          disabled={
                                            isSubmitting ||
                                            !OPPORTUNITIES_ADD_EDIT ||
                                            values.opportunityLastStatus
                                              ?.specialIdentifier ===
                                              'OPPORTUNITY_STATUS_SOLD'
                                          }
                                          value='NorthGate'
                                          onClick={e => {
                                            // These radio buttons set the 'toolingPrintPlateOwnership' value to one of two hard-coded strings
                                            setFieldValue(
                                              'quoteRevision.quoteLineItem.toolingPrintPlateOwnership',
                                              'NorthGate'
                                            )
                                          }}
                                          control={<Radio />}
                                          label='NorthGate'
                                        />
                                        <FormControlLabel
                                          disabled={
                                            isSubmitting ||
                                            !OPPORTUNITIES_ADD_EDIT ||
                                            values.opportunityLastStatus
                                              ?.specialIdentifier ===
                                              'OPPORTUNITY_STATUS_SOLD'
                                          }
                                          value='Client'
                                          onClick={e => {
                                            // These radio buttons set the 'toolingPrintPlateOwnership' value to one of two hard-coded strings
                                            setFieldValue(
                                              'quoteRevision.quoteLineItem.toolingPrintPlateOwnership',
                                              'Client'
                                            )
                                          }}
                                          control={<Radio />}
                                          label='Client'
                                        />
                                      </RadioGroup>
                                      <FormHelperText>
                                        {getIn(
                                          touched,
                                          'quoteRevision.quoteLineItem.toolingPrintPlateOwnership'
                                        ) &&
                                          getIn(
                                            errors,
                                            'quoteRevision.quoteLineItem.toolingPrintPlateOwnership'
                                          )}
                                      </FormHelperText>
                                    </FormControl>
                                  </Tooltip>
                                </Typography>
                              </Grid>
                            </>
                          )}

                          <Grid item xs={12}>
                            <ExplanationAccordion>
                              Print: If yes, user must select colors and print panels
                              (1C2P, etc.) Then on the inks card, the user selects the
                              colors (GCMI) and enters in the coverage percentage. If no,
                              user does not select colors or print panels and inks card
                              fields will be read-only.||If either the "Print panels
                              (Count)" dropdown or the "Colors (Count)" dropdown changes,
                              clear all of the color fields on the "Inks" card.
                            </ExplanationAccordion>
                          </Grid>
                        </Grid>
                      </DetailsForm>
                    </Grid>

                    {/* INKS CARD */}
                    {/* INKS CARD */}
                    {/* INKS CARD */}
                    <Grid item xs={12}>
                      <DetailsForm>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            container
                            alignItems='center'
                            justifyContent='space-between'
                          >
                            <Grid item xs={12} sm={6}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#2780E3', fontWeight: 600 }}
                              >
                                Inks
                              </Typography>
                            </Grid>
                          </Grid>
                          {values?.quoteRevision?.quoteLineItem?.toolingPrint && (
                            <>
                              <Grid item xs={12}>
                                <TextField
                                  disabled={
                                    isSubmitting ||
                                    !OPPORTUNITIES_ADD_EDIT ||
                                    values.opportunityLastStatus?.specialIdentifier ===
                                      'OPPORTUNITY_STATUS_SOLD'
                                  }
                                  error={Boolean(
                                    getIn(
                                      touched,
                                      'quoteRevision.quoteLineItem.inksCoveragePercentage'
                                    ) &&
                                      getIn(
                                        errors,
                                        'quoteRevision.quoteLineItem.inksCoveragePercentage'
                                      )
                                  )}
                                  fullWidth
                                  helperText={
                                    getIn(
                                      touched,
                                      'quoteRevision.quoteLineItem.inksCoveragePercentage'
                                    ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.inksCoveragePercentage'
                                    )
                                  }
                                  label='Coverage percentage'
                                  name='quoteRevision.quoteLineItem.inksCoveragePercentage'
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={
                                    values.quoteRevision?.quoteLineItem
                                      ?.inksCoveragePercentage || ''
                                  }
                                />
                              </Grid>
                              {(values?.quoteRevision?.quoteLineItem
                                ?.toolingPrintPanelsCount ?? 0) >= 1 &&
                                (values?.quoteRevision?.quoteLineItem
                                  ?.toolingColorsCount ?? 0) >= 1 && (
                                  <>
                                    <Grid container item xs={12} sm={6}>
                                      <Tooltip
                                        title={
                                          values.opportunityLastStatus
                                            ?.specialIdentifier ===
                                          'OPPORTUNITY_STATUS_SOLD'
                                            ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                            : OPPORTUNITIES_ADD_EDIT
                                            ? ''
                                            : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                                        }
                                        placement='bottom'
                                      >
                                        <FormControl fullWidth>
                                          <TextField
                                            disabled={
                                              isSubmitting ||
                                              !OPPORTUNITIES_ADD_EDIT ||
                                              values.opportunityLastStatus
                                                ?.specialIdentifier ===
                                                'OPPORTUNITY_STATUS_SOLD'
                                            }
                                            error={Boolean(
                                              getIn(
                                                touched,
                                                'quoteRevision.quoteLineItem.ratesInkPanel1Color1'
                                              ) &&
                                                getIn(
                                                  errors,
                                                  'quoteRevision.quoteLineItem.ratesInkPanel1Color1'
                                                )
                                            )}
                                            fullWidth
                                            helperText={
                                              getIn(
                                                touched,
                                                'quoteRevision.quoteLineItem.ratesInkPanel1Color1'
                                              ) &&
                                              getIn(
                                                errors,
                                                'quoteRevision.quoteLineItem.ratesInkPanel1Color1'
                                              )
                                            }
                                            label='Ink Panel 1, Color 1: GCMI'
                                            name='quoteRevision.quoteLineItem.ratesInkPanel1Color1'
                                            onBlur={handleBlur}
                                            onChange={e => {
                                              setFieldValue(
                                                'quoteRevision.quoteLineItem.ratesInkPanel1Color1',
                                                JSON.parse(e.target.value)
                                              )
                                            }}
                                            select
                                            value={
                                              values?.quoteRevision?.quoteLineItem
                                                ?.ratesInkPanel1Color1
                                                ? JSON.stringify(
                                                    ratesInks.filter(
                                                      ink =>
                                                        ink?.id ===
                                                        values?.quoteRevision
                                                          ?.quoteLineItem
                                                          ?.ratesInkPanel1Color1?.id
                                                    )[0]
                                                  )
                                                : ''
                                            }
                                          >
                                            {ratesInks
                                              ?.sort((a, b) =>
                                                (a?.gcmiColors ?? '').localeCompare(
                                                  b?.gcmiColors ?? ''
                                                )
                                              )
                                              ?.map(ink => (
                                                <MenuItem
                                                  value={JSON.stringify(ink)}
                                                  key={ink?.id ?? ''}
                                                >
                                                  {ink?.gcmiColors ?? ''}
                                                </MenuItem>
                                              ))}
                                          </TextField>
                                        </FormControl>
                                      </Tooltip>
                                    </Grid>

                                    <Grid container item xs={12} sm={6}>
                                      <Grid
                                        item
                                        xs={12}
                                        sx={{
                                          color: '#7F7F7F',
                                          backgroundColor: ratesInks.find(
                                            ink =>
                                              ink.id ===
                                              values?.quoteRevision?.quoteLineItem
                                                ?.ratesInkPanel1Color1?.id
                                          )?.hexCode,
                                          border: '1px solid #7F7F7F'
                                        }}
                                      />
                                    </Grid>
                                  </>
                                )}
                              {(values?.quoteRevision?.quoteLineItem
                                ?.toolingPrintPanelsCount ?? 0) >= 1 &&
                                (values?.quoteRevision?.quoteLineItem
                                  ?.toolingColorsCount ?? 0) >= 2 && (
                                  <>
                                    <Grid container item xs={12} sm={6}>
                                      <Tooltip
                                        title={
                                          values.opportunityLastStatus
                                            ?.specialIdentifier ===
                                          'OPPORTUNITY_STATUS_SOLD'
                                            ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                            : OPPORTUNITIES_ADD_EDIT
                                            ? ''
                                            : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                                        }
                                        placement='bottom'
                                      >
                                        <FormControl fullWidth>
                                          <TextField
                                            disabled={
                                              isSubmitting ||
                                              !OPPORTUNITIES_ADD_EDIT ||
                                              values.opportunityLastStatus
                                                ?.specialIdentifier ===
                                                'OPPORTUNITY_STATUS_SOLD'
                                            }
                                            error={Boolean(
                                              getIn(
                                                touched,
                                                'quoteRevision.quoteLineItem.ratesInkPanel1Color2'
                                              ) &&
                                                getIn(
                                                  errors,
                                                  'quoteRevision.quoteLineItem.ratesInkPanel1Color2'
                                                )
                                            )}
                                            fullWidth
                                            helperText={
                                              getIn(
                                                touched,
                                                'quoteRevision.quoteLineItem.ratesInkPanel1Color2'
                                              ) &&
                                              getIn(
                                                errors,
                                                'quoteRevision.quoteLineItem.ratesInkPanel1Color2'
                                              )
                                            }
                                            label='Ink Panel 1, Color 2: GCMI'
                                            name='quoteRevision.quoteLineItem.ratesInkPanel1Color2'
                                            onBlur={handleBlur}
                                            onChange={e => {
                                              setFieldValue(
                                                'quoteRevision.quoteLineItem.ratesInkPanel1Color2',
                                                JSON.parse(e.target.value)
                                              )
                                            }}
                                            select
                                            value={
                                              values?.quoteRevision?.quoteLineItem
                                                ?.ratesInkPanel1Color2
                                                ? JSON.stringify(
                                                    ratesInks.filter(
                                                      ink =>
                                                        ink?.id ===
                                                        values?.quoteRevision
                                                          ?.quoteLineItem
                                                          ?.ratesInkPanel1Color2?.id
                                                    )[0]
                                                  )
                                                : ''
                                            }
                                          >
                                            {ratesInks
                                              ?.sort((a, b) =>
                                                (a?.gcmiColors ?? '').localeCompare(
                                                  b?.gcmiColors ?? ''
                                                )
                                              )
                                              ?.map(ink => (
                                                <MenuItem
                                                  value={JSON.stringify(ink)}
                                                  key={ink?.id ?? ''}
                                                >
                                                  {ink?.gcmiColors ?? ''}
                                                </MenuItem>
                                              ))}
                                          </TextField>
                                        </FormControl>
                                      </Tooltip>
                                    </Grid>

                                    <Grid container item xs={12} sm={6}>
                                      <Grid
                                        item
                                        xs={12}
                                        sx={{
                                          color: '#7F7F7F',
                                          backgroundColor: ratesInks.find(
                                            ink =>
                                              ink.id ===
                                              values?.quoteRevision?.quoteLineItem
                                                ?.ratesInkPanel1Color2?.id
                                          )?.hexCode,
                                          border: '1px solid #7F7F7F'
                                        }}
                                      />
                                    </Grid>
                                  </>
                                )}
                              {(values?.quoteRevision?.quoteLineItem
                                ?.toolingPrintPanelsCount ?? 0) >= 1 &&
                                (values?.quoteRevision?.quoteLineItem
                                  ?.toolingColorsCount ?? 0) >= 3 && (
                                  <>
                                    <Grid container item xs={12} sm={6}>
                                      <Tooltip
                                        title={
                                          values.opportunityLastStatus
                                            ?.specialIdentifier ===
                                          'OPPORTUNITY_STATUS_SOLD'
                                            ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                            : OPPORTUNITIES_ADD_EDIT
                                            ? ''
                                            : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                                        }
                                        placement='bottom'
                                      >
                                        <FormControl fullWidth>
                                          <TextField
                                            disabled={
                                              isSubmitting ||
                                              !OPPORTUNITIES_ADD_EDIT ||
                                              values.opportunityLastStatus
                                                ?.specialIdentifier ===
                                                'OPPORTUNITY_STATUS_SOLD'
                                            }
                                            error={Boolean(
                                              getIn(
                                                touched,
                                                'quoteRevision.quoteLineItem.ratesInkPanel1Color3'
                                              ) &&
                                                getIn(
                                                  errors,
                                                  'quoteRevision.quoteLineItem.ratesInkPanel1Color3'
                                                )
                                            )}
                                            fullWidth
                                            helperText={
                                              getIn(
                                                touched,
                                                'quoteRevision.quoteLineItem.ratesInkPanel1Color3'
                                              ) &&
                                              getIn(
                                                errors,
                                                'quoteRevision.quoteLineItem.ratesInkPanel1Color3'
                                              )
                                            }
                                            label='Ink Panel 1, Color 3: GCMI'
                                            name='quoteRevision.quoteLineItem.ratesInkPanel1Color3'
                                            onBlur={handleBlur}
                                            onChange={e => {
                                              setFieldValue(
                                                'quoteRevision.quoteLineItem.ratesInkPanel1Color3',
                                                JSON.parse(e.target.value)
                                              )
                                            }}
                                            select
                                            value={
                                              values?.quoteRevision?.quoteLineItem
                                                ?.ratesInkPanel1Color3
                                                ? JSON.stringify(
                                                    ratesInks.filter(
                                                      ink =>
                                                        ink?.id ===
                                                        values?.quoteRevision
                                                          ?.quoteLineItem
                                                          ?.ratesInkPanel1Color3?.id
                                                    )[0]
                                                  )
                                                : ''
                                            }
                                          >
                                            {ratesInks
                                              ?.sort((a, b) =>
                                                (a?.gcmiColors ?? '').localeCompare(
                                                  b?.gcmiColors ?? ''
                                                )
                                              )
                                              ?.map(ink => (
                                                <MenuItem
                                                  value={JSON.stringify(ink)}
                                                  key={ink?.id ?? ''}
                                                >
                                                  {ink?.gcmiColors ?? ''}
                                                </MenuItem>
                                              ))}
                                          </TextField>
                                        </FormControl>
                                      </Tooltip>
                                    </Grid>

                                    <Grid container item xs={12} sm={6}>
                                      <Grid
                                        item
                                        xs={12}
                                        sx={{
                                          color: '#7F7F7F',
                                          backgroundColor: ratesInks.find(
                                            ink =>
                                              ink.id ===
                                              values?.quoteRevision?.quoteLineItem
                                                ?.ratesInkPanel1Color3?.id
                                          )?.hexCode,
                                          border: '1px solid #7F7F7F'
                                        }}
                                      />
                                    </Grid>
                                  </>
                                )}
                              {(values?.quoteRevision?.quoteLineItem
                                ?.toolingPrintPanelsCount ?? 0) >= 1 &&
                                (values?.quoteRevision?.quoteLineItem
                                  ?.toolingColorsCount ?? 0) >= 4 && (
                                  <>
                                    <Grid container item xs={12} sm={6}>
                                      <Tooltip
                                        title={
                                          values.opportunityLastStatus
                                            ?.specialIdentifier ===
                                          'OPPORTUNITY_STATUS_SOLD'
                                            ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                            : OPPORTUNITIES_ADD_EDIT
                                            ? ''
                                            : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                                        }
                                        placement='bottom'
                                      >
                                        <FormControl fullWidth>
                                          <TextField
                                            disabled={
                                              isSubmitting ||
                                              !OPPORTUNITIES_ADD_EDIT ||
                                              values.opportunityLastStatus
                                                ?.specialIdentifier ===
                                                'OPPORTUNITY_STATUS_SOLD'
                                            }
                                            error={Boolean(
                                              getIn(
                                                touched,
                                                'quoteRevision.quoteLineItem.ratesInkPanel1Color4'
                                              ) &&
                                                getIn(
                                                  errors,
                                                  'quoteRevision.quoteLineItem.ratesInkPanel1Color4'
                                                )
                                            )}
                                            fullWidth
                                            helperText={
                                              getIn(
                                                touched,
                                                'quoteRevision.quoteLineItem.ratesInkPanel1Color4'
                                              ) &&
                                              getIn(
                                                errors,
                                                'quoteRevision.quoteLineItem.ratesInkPanel1Color4'
                                              )
                                            }
                                            label='Ink Panel 1, Color 4: GCMI'
                                            name='quoteRevision.quoteLineItem.ratesInkPanel1Color4'
                                            onBlur={handleBlur}
                                            onChange={e => {
                                              setFieldValue(
                                                'quoteRevision.quoteLineItem.ratesInkPanel1Color4',
                                                JSON.parse(e.target.value)
                                              )
                                            }}
                                            select
                                            value={
                                              values?.quoteRevision?.quoteLineItem
                                                ?.ratesInkPanel1Color4
                                                ? JSON.stringify(
                                                    ratesInks.filter(
                                                      ink =>
                                                        ink?.id ===
                                                        values?.quoteRevision
                                                          ?.quoteLineItem
                                                          ?.ratesInkPanel1Color4?.id
                                                    )[0]
                                                  )
                                                : ''
                                            }
                                          >
                                            {ratesInks
                                              ?.sort((a, b) =>
                                                (a?.gcmiColors ?? '').localeCompare(
                                                  b?.gcmiColors ?? ''
                                                )
                                              )
                                              ?.map(ink => (
                                                <MenuItem
                                                  value={JSON.stringify(ink)}
                                                  key={ink?.id ?? ''}
                                                >
                                                  {ink?.gcmiColors ?? ''}
                                                </MenuItem>
                                              ))}
                                          </TextField>
                                        </FormControl>
                                      </Tooltip>
                                    </Grid>

                                    <Grid container item xs={12} sm={6}>
                                      <Grid
                                        item
                                        xs={12}
                                        sx={{
                                          color: '#7F7F7F',
                                          backgroundColor: ratesInks.find(
                                            ink =>
                                              ink.id ===
                                              values?.quoteRevision?.quoteLineItem
                                                ?.ratesInkPanel1Color4?.id
                                          )?.hexCode,
                                          border: '1px solid #7F7F7F'
                                        }}
                                      />
                                    </Grid>
                                  </>
                                )}
                              {(values?.quoteRevision?.quoteLineItem
                                ?.toolingPrintPanelsCount ?? 0) >= 2 &&
                                (values?.quoteRevision?.quoteLineItem
                                  ?.toolingColorsCount ?? 0) >= 1 && (
                                  <>
                                    <Grid container item xs={12} sm={6}>
                                      <Tooltip
                                        title={
                                          values.opportunityLastStatus
                                            ?.specialIdentifier ===
                                          'OPPORTUNITY_STATUS_SOLD'
                                            ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                            : OPPORTUNITIES_ADD_EDIT
                                            ? ''
                                            : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                                        }
                                        placement='bottom'
                                      >
                                        <FormControl fullWidth>
                                          <TextField
                                            disabled={
                                              isSubmitting ||
                                              !OPPORTUNITIES_ADD_EDIT ||
                                              values.opportunityLastStatus
                                                ?.specialIdentifier ===
                                                'OPPORTUNITY_STATUS_SOLD'
                                            }
                                            error={Boolean(
                                              getIn(
                                                touched,
                                                'quoteRevision.quoteLineItem.ratesInkPanel2Color1'
                                              ) &&
                                                getIn(
                                                  errors,
                                                  'quoteRevision.quoteLineItem.ratesInkPanel2Color1'
                                                )
                                            )}
                                            fullWidth
                                            helperText={
                                              getIn(
                                                touched,
                                                'quoteRevision.quoteLineItem.ratesInkPanel2Color1'
                                              ) &&
                                              getIn(
                                                errors,
                                                'quoteRevision.quoteLineItem.ratesInkPanel2Color1'
                                              )
                                            }
                                            label='Ink Panel 2, Color 1: GCMI'
                                            name='quoteRevision.quoteLineItem.ratesInkPanel2Color1'
                                            onBlur={handleBlur}
                                            onChange={e => {
                                              setFieldValue(
                                                'quoteRevision.quoteLineItem.ratesInkPanel2Color1',
                                                JSON.parse(e.target.value)
                                              )
                                            }}
                                            select
                                            value={
                                              values?.quoteRevision?.quoteLineItem
                                                ?.ratesInkPanel2Color1
                                                ? JSON.stringify(
                                                    ratesInks.filter(
                                                      ink =>
                                                        ink?.id ===
                                                        values?.quoteRevision
                                                          ?.quoteLineItem
                                                          ?.ratesInkPanel2Color1?.id
                                                    )[0]
                                                  )
                                                : ''
                                            }
                                          >
                                            {ratesInks
                                              ?.sort((a, b) =>
                                                (a?.gcmiColors ?? '').localeCompare(
                                                  b?.gcmiColors ?? ''
                                                )
                                              )
                                              ?.map(ink => (
                                                <MenuItem
                                                  value={JSON.stringify(ink)}
                                                  key={ink?.id ?? ''}
                                                >
                                                  {ink?.gcmiColors ?? ''}
                                                </MenuItem>
                                              ))}
                                          </TextField>
                                        </FormControl>
                                      </Tooltip>
                                    </Grid>

                                    <Grid container item xs={12} sm={6}>
                                      <Grid
                                        item
                                        xs={12}
                                        sx={{
                                          color: '#7F7F7F',
                                          backgroundColor: ratesInks.find(
                                            ink =>
                                              ink.id ===
                                              values?.quoteRevision?.quoteLineItem
                                                ?.ratesInkPanel2Color1?.id
                                          )?.hexCode,
                                          border: '1px solid #7F7F7F'
                                        }}
                                      />
                                    </Grid>
                                  </>
                                )}
                              {(values?.quoteRevision?.quoteLineItem
                                ?.toolingPrintPanelsCount ?? 0) >= 2 &&
                                (values?.quoteRevision?.quoteLineItem
                                  ?.toolingColorsCount ?? 0) >= 2 && (
                                  <>
                                    <Grid container item xs={12} sm={6}>
                                      <Tooltip
                                        title={
                                          values.opportunityLastStatus
                                            ?.specialIdentifier ===
                                          'OPPORTUNITY_STATUS_SOLD'
                                            ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                            : OPPORTUNITIES_ADD_EDIT
                                            ? ''
                                            : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                                        }
                                        placement='bottom'
                                      >
                                        <FormControl fullWidth>
                                          <TextField
                                            disabled={
                                              isSubmitting ||
                                              !OPPORTUNITIES_ADD_EDIT ||
                                              values.opportunityLastStatus
                                                ?.specialIdentifier ===
                                                'OPPORTUNITY_STATUS_SOLD'
                                            }
                                            error={Boolean(
                                              getIn(
                                                touched,
                                                'quoteRevision.quoteLineItem.ratesInkPanel2Color2'
                                              ) &&
                                                getIn(
                                                  errors,
                                                  'quoteRevision.quoteLineItem.ratesInkPanel2Color2'
                                                )
                                            )}
                                            fullWidth
                                            helperText={
                                              getIn(
                                                touched,
                                                'quoteRevision.quoteLineItem.ratesInkPanel2Color2'
                                              ) &&
                                              getIn(
                                                errors,
                                                'quoteRevision.quoteLineItem.ratesInkPanel2Color2'
                                              )
                                            }
                                            label='Ink Panel 2, Color 2: GCMI'
                                            name='quoteRevision.quoteLineItem.ratesInkPanel2Color2'
                                            onBlur={handleBlur}
                                            onChange={e => {
                                              setFieldValue(
                                                'quoteRevision.quoteLineItem.ratesInkPanel2Color2',
                                                JSON.parse(e.target.value)
                                              )
                                            }}
                                            select
                                            value={
                                              values?.quoteRevision?.quoteLineItem
                                                ?.ratesInkPanel2Color2
                                                ? JSON.stringify(
                                                    ratesInks.filter(
                                                      ink =>
                                                        ink?.id ===
                                                        values?.quoteRevision
                                                          ?.quoteLineItem
                                                          ?.ratesInkPanel2Color2?.id
                                                    )[0]
                                                  )
                                                : ''
                                            }
                                          >
                                            {ratesInks
                                              ?.sort((a, b) =>
                                                (a?.gcmiColors ?? '').localeCompare(
                                                  b?.gcmiColors ?? ''
                                                )
                                              )
                                              ?.map(ink => (
                                                <MenuItem
                                                  value={JSON.stringify(ink)}
                                                  key={ink?.id ?? ''}
                                                >
                                                  {ink?.gcmiColors ?? ''}
                                                </MenuItem>
                                              ))}
                                          </TextField>
                                        </FormControl>
                                      </Tooltip>
                                    </Grid>

                                    <Grid container item xs={12} sm={6}>
                                      <Grid
                                        item
                                        xs={12}
                                        sx={{
                                          color: '#7F7F7F',
                                          backgroundColor: ratesInks.find(
                                            ink =>
                                              ink.id ===
                                              values?.quoteRevision?.quoteLineItem
                                                ?.ratesInkPanel2Color2?.id
                                          )?.hexCode,
                                          border: '1px solid #7F7F7F'
                                        }}
                                      />
                                    </Grid>
                                  </>
                                )}
                              {(values?.quoteRevision?.quoteLineItem
                                ?.toolingPrintPanelsCount ?? 0) >= 2 &&
                                (values?.quoteRevision?.quoteLineItem
                                  ?.toolingColorsCount ?? 0) >= 3 && (
                                  <>
                                    <Grid container item xs={12} sm={6}>
                                      <Tooltip
                                        title={
                                          values.opportunityLastStatus
                                            ?.specialIdentifier ===
                                          'OPPORTUNITY_STATUS_SOLD'
                                            ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                            : OPPORTUNITIES_ADD_EDIT
                                            ? ''
                                            : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                                        }
                                        placement='bottom'
                                      >
                                        <FormControl fullWidth>
                                          <TextField
                                            disabled={
                                              isSubmitting ||
                                              !OPPORTUNITIES_ADD_EDIT ||
                                              values.opportunityLastStatus
                                                ?.specialIdentifier ===
                                                'OPPORTUNITY_STATUS_SOLD'
                                            }
                                            error={Boolean(
                                              getIn(
                                                touched,
                                                'quoteRevision.quoteLineItem.ratesInkPanel2Color3'
                                              ) &&
                                                getIn(
                                                  errors,
                                                  'quoteRevision.quoteLineItem.ratesInkPanel2Color3'
                                                )
                                            )}
                                            fullWidth
                                            helperText={
                                              getIn(
                                                touched,
                                                'quoteRevision.quoteLineItem.ratesInkPanel2Color3'
                                              ) &&
                                              getIn(
                                                errors,
                                                'quoteRevision.quoteLineItem.ratesInkPanel2Color3'
                                              )
                                            }
                                            label='Ink Panel 2, Color 3: GCMI'
                                            name='quoteRevision.quoteLineItem.ratesInkPanel2Color3'
                                            onBlur={handleBlur}
                                            onChange={e => {
                                              setFieldValue(
                                                'quoteRevision.quoteLineItem.ratesInkPanel2Color3',
                                                JSON.parse(e.target.value)
                                              )
                                            }}
                                            select
                                            value={
                                              values?.quoteRevision?.quoteLineItem
                                                ?.ratesInkPanel2Color3
                                                ? JSON.stringify(
                                                    ratesInks.filter(
                                                      ink =>
                                                        ink?.id ===
                                                        values?.quoteRevision
                                                          ?.quoteLineItem
                                                          ?.ratesInkPanel2Color3?.id
                                                    )[0]
                                                  )
                                                : ''
                                            }
                                          >
                                            {ratesInks
                                              ?.sort((a, b) =>
                                                (a?.gcmiColors ?? '').localeCompare(
                                                  b?.gcmiColors ?? ''
                                                )
                                              )
                                              ?.map(ink => (
                                                <MenuItem
                                                  value={JSON.stringify(ink)}
                                                  key={ink?.id ?? ''}
                                                >
                                                  {ink?.gcmiColors ?? ''}
                                                </MenuItem>
                                              ))}
                                          </TextField>
                                        </FormControl>
                                      </Tooltip>
                                    </Grid>

                                    <Grid container item xs={12} sm={6}>
                                      <Grid
                                        item
                                        xs={12}
                                        sx={{
                                          color: '#7F7F7F',
                                          backgroundColor: ratesInks.find(
                                            ink =>
                                              ink.id ===
                                              values?.quoteRevision?.quoteLineItem
                                                ?.ratesInkPanel2Color3?.id
                                          )?.hexCode,
                                          border: '1px solid #7F7F7F'
                                        }}
                                      />
                                    </Grid>
                                  </>
                                )}
                              {(values?.quoteRevision?.quoteLineItem
                                ?.toolingPrintPanelsCount ?? 0) >= 2 &&
                                (values?.quoteRevision?.quoteLineItem
                                  ?.toolingColorsCount ?? 0) >= 4 && (
                                  <>
                                    <Grid container item xs={12} sm={6}>
                                      <Tooltip
                                        title={
                                          values.opportunityLastStatus
                                            ?.specialIdentifier ===
                                          'OPPORTUNITY_STATUS_SOLD'
                                            ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                            : OPPORTUNITIES_ADD_EDIT
                                            ? ''
                                            : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                                        }
                                        placement='bottom'
                                      >
                                        <FormControl fullWidth>
                                          <TextField
                                            disabled={
                                              isSubmitting ||
                                              !OPPORTUNITIES_ADD_EDIT ||
                                              values.opportunityLastStatus
                                                ?.specialIdentifier ===
                                                'OPPORTUNITY_STATUS_SOLD'
                                            }
                                            error={Boolean(
                                              getIn(
                                                touched,
                                                'quoteRevision.quoteLineItem.ratesInkPanel2Color4'
                                              ) &&
                                                getIn(
                                                  errors,
                                                  'quoteRevision.quoteLineItem.ratesInkPanel2Color4'
                                                )
                                            )}
                                            fullWidth
                                            helperText={
                                              getIn(
                                                touched,
                                                'quoteRevision.quoteLineItem.ratesInkPanel2Color4'
                                              ) &&
                                              getIn(
                                                errors,
                                                'quoteRevision.quoteLineItem.ratesInkPanel2Color4'
                                              )
                                            }
                                            label='Ink Panel 2, Color 4: GCMI'
                                            name='quoteRevision.quoteLineItem.ratesInkPanel2Color4'
                                            onBlur={handleBlur}
                                            onChange={e => {
                                              setFieldValue(
                                                'quoteRevision.quoteLineItem.ratesInkPanel2Color4',
                                                JSON.parse(e.target.value)
                                              )
                                            }}
                                            select
                                            value={
                                              values?.quoteRevision?.quoteLineItem
                                                ?.ratesInkPanel2Color4
                                                ? JSON.stringify(
                                                    ratesInks.filter(
                                                      ink =>
                                                        ink?.id ===
                                                        values?.quoteRevision
                                                          ?.quoteLineItem
                                                          ?.ratesInkPanel2Color4?.id
                                                    )[0]
                                                  )
                                                : ''
                                            }
                                          >
                                            {ratesInks
                                              ?.sort((a, b) =>
                                                (a?.gcmiColors ?? '').localeCompare(
                                                  b?.gcmiColors ?? ''
                                                )
                                              )
                                              ?.map(ink => (
                                                <MenuItem
                                                  value={JSON.stringify(ink)}
                                                  key={ink?.id ?? ''}
                                                >
                                                  {ink?.gcmiColors ?? ''}
                                                </MenuItem>
                                              ))}
                                          </TextField>
                                        </FormControl>
                                      </Tooltip>
                                    </Grid>

                                    <Grid container item xs={12} sm={6}>
                                      <Grid
                                        item
                                        xs={12}
                                        sx={{
                                          color: '#7F7F7F',
                                          backgroundColor: ratesInks.find(
                                            ink =>
                                              ink.id ===
                                              values?.quoteRevision?.quoteLineItem
                                                ?.ratesInkPanel2Color4?.id
                                          )?.hexCode,
                                          border: '1px solid #7F7F7F'
                                        }}
                                      />
                                    </Grid>
                                  </>
                                )}
                              {(values?.quoteRevision?.quoteLineItem
                                ?.toolingPrintPanelsCount ?? 0) >= 3 &&
                                (values?.quoteRevision?.quoteLineItem
                                  ?.toolingColorsCount ?? 0) >= 1 && (
                                  <>
                                    <Grid container item xs={12} sm={6}>
                                      <Tooltip
                                        title={
                                          values.opportunityLastStatus
                                            ?.specialIdentifier ===
                                          'OPPORTUNITY_STATUS_SOLD'
                                            ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                            : OPPORTUNITIES_ADD_EDIT
                                            ? ''
                                            : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                                        }
                                        placement='bottom'
                                      >
                                        <FormControl fullWidth>
                                          <TextField
                                            disabled={
                                              isSubmitting ||
                                              !OPPORTUNITIES_ADD_EDIT ||
                                              values.opportunityLastStatus
                                                ?.specialIdentifier ===
                                                'OPPORTUNITY_STATUS_SOLD'
                                            }
                                            error={Boolean(
                                              getIn(
                                                touched,
                                                'quoteRevision.quoteLineItem.ratesInkPanel3Color1'
                                              ) &&
                                                getIn(
                                                  errors,
                                                  'quoteRevision.quoteLineItem.ratesInkPanel3Color1'
                                                )
                                            )}
                                            fullWidth
                                            helperText={
                                              getIn(
                                                touched,
                                                'quoteRevision.quoteLineItem.ratesInkPanel3Color1'
                                              ) &&
                                              getIn(
                                                errors,
                                                'quoteRevision.quoteLineItem.ratesInkPanel3Color1'
                                              )
                                            }
                                            label='Ink Panel 3, Color 1: GCMI'
                                            name='quoteRevision.quoteLineItem.ratesInkPanel3Color1'
                                            onBlur={handleBlur}
                                            onChange={e => {
                                              setFieldValue(
                                                'quoteRevision.quoteLineItem.ratesInkPanel3Color1',
                                                JSON.parse(e.target.value)
                                              )
                                            }}
                                            select
                                            value={
                                              values?.quoteRevision?.quoteLineItem
                                                ?.ratesInkPanel3Color1
                                                ? JSON.stringify(
                                                    ratesInks.filter(
                                                      ink =>
                                                        ink?.id ===
                                                        values?.quoteRevision
                                                          ?.quoteLineItem
                                                          ?.ratesInkPanel3Color1?.id
                                                    )[0]
                                                  )
                                                : ''
                                            }
                                          >
                                            {ratesInks
                                              ?.sort((a, b) =>
                                                (a?.gcmiColors ?? '').localeCompare(
                                                  b?.gcmiColors ?? ''
                                                )
                                              )
                                              ?.map(ink => (
                                                <MenuItem
                                                  value={JSON.stringify(ink)}
                                                  key={ink?.id ?? ''}
                                                >
                                                  {ink?.gcmiColors ?? ''}
                                                </MenuItem>
                                              ))}
                                          </TextField>
                                        </FormControl>
                                      </Tooltip>
                                    </Grid>

                                    <Grid container item xs={12} sm={6}>
                                      <Grid
                                        item
                                        xs={12}
                                        sx={{
                                          color: '#7F7F7F',
                                          backgroundColor: ratesInks.find(
                                            ink =>
                                              ink.id ===
                                              values?.quoteRevision?.quoteLineItem
                                                ?.ratesInkPanel3Color1?.id
                                          )?.hexCode,
                                          border: '1px solid #7F7F7F'
                                        }}
                                      />
                                    </Grid>
                                  </>
                                )}
                              {(values?.quoteRevision?.quoteLineItem
                                ?.toolingPrintPanelsCount ?? 0) >= 3 &&
                                (values?.quoteRevision?.quoteLineItem
                                  ?.toolingColorsCount ?? 0) >= 2 && (
                                  <>
                                    <Grid container item xs={12} sm={6}>
                                      <Tooltip
                                        title={
                                          values.opportunityLastStatus
                                            ?.specialIdentifier ===
                                          'OPPORTUNITY_STATUS_SOLD'
                                            ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                            : OPPORTUNITIES_ADD_EDIT
                                            ? ''
                                            : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                                        }
                                        placement='bottom'
                                      >
                                        <FormControl fullWidth>
                                          <TextField
                                            disabled={
                                              isSubmitting ||
                                              !OPPORTUNITIES_ADD_EDIT ||
                                              values.opportunityLastStatus
                                                ?.specialIdentifier ===
                                                'OPPORTUNITY_STATUS_SOLD'
                                            }
                                            error={Boolean(
                                              getIn(
                                                touched,
                                                'quoteRevision.quoteLineItem.ratesInkPanel3Color2'
                                              ) &&
                                                getIn(
                                                  errors,
                                                  'quoteRevision.quoteLineItem.ratesInkPanel3Color2'
                                                )
                                            )}
                                            fullWidth
                                            helperText={
                                              getIn(
                                                touched,
                                                'quoteRevision.quoteLineItem.ratesInkPanel3Color2'
                                              ) &&
                                              getIn(
                                                errors,
                                                'quoteRevision.quoteLineItem.ratesInkPanel3Color2'
                                              )
                                            }
                                            label='Ink Panel 3, Color 2: GCMI'
                                            name='quoteRevision.quoteLineItem.ratesInkPanel3Color2'
                                            onBlur={handleBlur}
                                            onChange={e => {
                                              setFieldValue(
                                                'quoteRevision.quoteLineItem.ratesInkPanel3Color2',
                                                JSON.parse(e.target.value)
                                              )
                                            }}
                                            select
                                            value={
                                              values?.quoteRevision?.quoteLineItem
                                                ?.ratesInkPanel3Color2
                                                ? JSON.stringify(
                                                    ratesInks.filter(
                                                      ink =>
                                                        ink?.id ===
                                                        values?.quoteRevision
                                                          ?.quoteLineItem
                                                          ?.ratesInkPanel3Color2?.id
                                                    )[0]
                                                  )
                                                : ''
                                            }
                                          >
                                            {ratesInks
                                              ?.sort((a, b) =>
                                                (a?.gcmiColors ?? '').localeCompare(
                                                  b?.gcmiColors ?? ''
                                                )
                                              )
                                              ?.map(ink => (
                                                <MenuItem
                                                  value={JSON.stringify(ink)}
                                                  key={ink?.id ?? ''}
                                                >
                                                  {ink?.gcmiColors ?? ''}
                                                </MenuItem>
                                              ))}
                                          </TextField>
                                        </FormControl>
                                      </Tooltip>
                                    </Grid>

                                    <Grid container item xs={12} sm={6}>
                                      <Grid
                                        item
                                        xs={12}
                                        sx={{
                                          color: '#7F7F7F',
                                          backgroundColor: ratesInks.find(
                                            ink =>
                                              ink.id ===
                                              values?.quoteRevision?.quoteLineItem
                                                ?.ratesInkPanel3Color2?.id
                                          )?.hexCode,
                                          border: '1px solid #7F7F7F'
                                        }}
                                      />
                                    </Grid>
                                  </>
                                )}
                              {(values?.quoteRevision?.quoteLineItem
                                ?.toolingPrintPanelsCount ?? 0) >= 3 &&
                                (values?.quoteRevision?.quoteLineItem
                                  ?.toolingColorsCount ?? 0) >= 3 && (
                                  <>
                                    <Grid container item xs={12} sm={6}>
                                      <Tooltip
                                        title={
                                          values.opportunityLastStatus
                                            ?.specialIdentifier ===
                                          'OPPORTUNITY_STATUS_SOLD'
                                            ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                            : OPPORTUNITIES_ADD_EDIT
                                            ? ''
                                            : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                                        }
                                        placement='bottom'
                                      >
                                        <FormControl fullWidth>
                                          <TextField
                                            disabled={
                                              isSubmitting ||
                                              !OPPORTUNITIES_ADD_EDIT ||
                                              values.opportunityLastStatus
                                                ?.specialIdentifier ===
                                                'OPPORTUNITY_STATUS_SOLD'
                                            }
                                            error={Boolean(
                                              getIn(
                                                touched,
                                                'quoteRevision.quoteLineItem.ratesInkPanel3Color3'
                                              ) &&
                                                getIn(
                                                  errors,
                                                  'quoteRevision.quoteLineItem.ratesInkPanel3Color3'
                                                )
                                            )}
                                            fullWidth
                                            helperText={
                                              getIn(
                                                touched,
                                                'quoteRevision.quoteLineItem.ratesInkPanel3Color3'
                                              ) &&
                                              getIn(
                                                errors,
                                                'quoteRevision.quoteLineItem.ratesInkPanel3Color3'
                                              )
                                            }
                                            label='Ink Panel 3, Color 3: GCMI'
                                            name='quoteRevision.quoteLineItem.ratesInkPanel3Color3'
                                            onBlur={handleBlur}
                                            onChange={e => {
                                              setFieldValue(
                                                'quoteRevision.quoteLineItem.ratesInkPanel3Color3',
                                                JSON.parse(e.target.value)
                                              )
                                            }}
                                            select
                                            value={
                                              values?.quoteRevision?.quoteLineItem
                                                ?.ratesInkPanel3Color3
                                                ? JSON.stringify(
                                                    ratesInks.filter(
                                                      ink =>
                                                        ink?.id ===
                                                        values?.quoteRevision
                                                          ?.quoteLineItem
                                                          ?.ratesInkPanel3Color3?.id
                                                    )[0]
                                                  )
                                                : ''
                                            }
                                          >
                                            {ratesInks
                                              ?.sort((a, b) =>
                                                (a?.gcmiColors ?? '').localeCompare(
                                                  b?.gcmiColors ?? ''
                                                )
                                              )
                                              ?.map(ink => (
                                                <MenuItem
                                                  value={JSON.stringify(ink)}
                                                  key={ink?.id ?? ''}
                                                >
                                                  {ink?.gcmiColors ?? ''}
                                                </MenuItem>
                                              ))}
                                          </TextField>
                                        </FormControl>
                                      </Tooltip>
                                    </Grid>

                                    <Grid container item xs={12} sm={6}>
                                      <Grid
                                        item
                                        xs={12}
                                        sx={{
                                          color: '#7F7F7F',
                                          backgroundColor: ratesInks.find(
                                            ink =>
                                              ink.id ===
                                              values?.quoteRevision?.quoteLineItem
                                                ?.ratesInkPanel3Color3?.id
                                          )?.hexCode,
                                          border: '1px solid #7F7F7F'
                                        }}
                                      />
                                    </Grid>
                                  </>
                                )}
                              {(values?.quoteRevision?.quoteLineItem
                                ?.toolingPrintPanelsCount ?? 0) >= 3 &&
                                (values?.quoteRevision?.quoteLineItem
                                  ?.toolingColorsCount ?? 0) >= 4 && (
                                  <>
                                    <Grid container item xs={12} sm={6}>
                                      <Tooltip
                                        title={
                                          values.opportunityLastStatus
                                            ?.specialIdentifier ===
                                          'OPPORTUNITY_STATUS_SOLD'
                                            ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                            : OPPORTUNITIES_ADD_EDIT
                                            ? ''
                                            : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                                        }
                                        placement='bottom'
                                      >
                                        <FormControl fullWidth>
                                          <TextField
                                            disabled={
                                              isSubmitting ||
                                              !OPPORTUNITIES_ADD_EDIT ||
                                              values.opportunityLastStatus
                                                ?.specialIdentifier ===
                                                'OPPORTUNITY_STATUS_SOLD'
                                            }
                                            error={Boolean(
                                              getIn(
                                                touched,
                                                'quoteRevision.quoteLineItem.ratesInkPanel3Color4'
                                              ) &&
                                                getIn(
                                                  errors,
                                                  'quoteRevision.quoteLineItem.ratesInkPanel3Color4'
                                                )
                                            )}
                                            fullWidth
                                            helperText={
                                              getIn(
                                                touched,
                                                'quoteRevision.quoteLineItem.ratesInkPanel3Color4'
                                              ) &&
                                              getIn(
                                                errors,
                                                'quoteRevision.quoteLineItem.ratesInkPanel3Color4'
                                              )
                                            }
                                            label='Ink Panel 3, Color 4: GCMI'
                                            name='quoteRevision.quoteLineItem.ratesInkPanel3Color4'
                                            onBlur={handleBlur}
                                            onChange={e => {
                                              setFieldValue(
                                                'quoteRevision.quoteLineItem.ratesInkPanel3Color4',
                                                JSON.parse(e.target.value)
                                              )
                                            }}
                                            select
                                            value={
                                              values?.quoteRevision?.quoteLineItem
                                                ?.ratesInkPanel3Color4
                                                ? JSON.stringify(
                                                    ratesInks.filter(
                                                      ink =>
                                                        ink?.id ===
                                                        values?.quoteRevision
                                                          ?.quoteLineItem
                                                          ?.ratesInkPanel3Color4?.id
                                                    )[0]
                                                  )
                                                : ''
                                            }
                                          >
                                            {ratesInks
                                              ?.sort((a, b) =>
                                                (a?.gcmiColors ?? '').localeCompare(
                                                  b?.gcmiColors ?? ''
                                                )
                                              )
                                              ?.map(ink => (
                                                <MenuItem
                                                  value={JSON.stringify(ink)}
                                                  key={ink?.id ?? ''}
                                                >
                                                  {ink?.gcmiColors ?? ''}
                                                </MenuItem>
                                              ))}
                                          </TextField>
                                        </FormControl>
                                      </Tooltip>
                                    </Grid>

                                    <Grid container item xs={12} sm={6}>
                                      <Grid
                                        item
                                        xs={12}
                                        sx={{
                                          color: '#7F7F7F',
                                          backgroundColor: ratesInks.find(
                                            ink =>
                                              ink.id ===
                                              values?.quoteRevision?.quoteLineItem
                                                ?.ratesInkPanel3Color4?.id
                                          )?.hexCode,
                                          border: '1px solid #7F7F7F'
                                        }}
                                      />
                                    </Grid>
                                  </>
                                )}
                              {(values?.quoteRevision?.quoteLineItem
                                ?.toolingPrintPanelsCount ?? 0) >= 4 &&
                                (values?.quoteRevision?.quoteLineItem
                                  ?.toolingColorsCount ?? 0) >= 1 && (
                                  <>
                                    <Grid container item xs={12} sm={6}>
                                      <Tooltip
                                        title={
                                          values.opportunityLastStatus
                                            ?.specialIdentifier ===
                                          'OPPORTUNITY_STATUS_SOLD'
                                            ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                            : OPPORTUNITIES_ADD_EDIT
                                            ? ''
                                            : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                                        }
                                        placement='bottom'
                                      >
                                        <FormControl fullWidth>
                                          <TextField
                                            disabled={
                                              isSubmitting ||
                                              !OPPORTUNITIES_ADD_EDIT ||
                                              values.opportunityLastStatus
                                                ?.specialIdentifier ===
                                                'OPPORTUNITY_STATUS_SOLD'
                                            }
                                            error={Boolean(
                                              getIn(
                                                touched,
                                                'quoteRevision.quoteLineItem.ratesInkPanel4Color1'
                                              ) &&
                                                getIn(
                                                  errors,
                                                  'quoteRevision.quoteLineItem.ratesInkPanel4Color1'
                                                )
                                            )}
                                            fullWidth
                                            helperText={
                                              getIn(
                                                touched,
                                                'quoteRevision.quoteLineItem.ratesInkPanel4Color1'
                                              ) &&
                                              getIn(
                                                errors,
                                                'quoteRevision.quoteLineItem.ratesInkPanel4Color1'
                                              )
                                            }
                                            label='Ink Panel 4, Color 1: GCMI'
                                            name='quoteRevision.quoteLineItem.ratesInkPanel4Color1'
                                            onBlur={handleBlur}
                                            onChange={e => {
                                              setFieldValue(
                                                'quoteRevision.quoteLineItem.ratesInkPanel4Color1',
                                                JSON.parse(e.target.value)
                                              )
                                            }}
                                            select
                                            value={
                                              values?.quoteRevision?.quoteLineItem
                                                ?.ratesInkPanel4Color1
                                                ? JSON.stringify(
                                                    ratesInks.filter(
                                                      ink =>
                                                        ink?.id ===
                                                        values?.quoteRevision
                                                          ?.quoteLineItem
                                                          ?.ratesInkPanel4Color1?.id
                                                    )[0]
                                                  )
                                                : ''
                                            }
                                          >
                                            {ratesInks
                                              ?.sort((a, b) =>
                                                (a?.gcmiColors ?? '').localeCompare(
                                                  b?.gcmiColors ?? ''
                                                )
                                              )
                                              ?.map(ink => (
                                                <MenuItem
                                                  value={JSON.stringify(ink)}
                                                  key={ink?.id ?? ''}
                                                >
                                                  {ink?.gcmiColors ?? ''}
                                                </MenuItem>
                                              ))}
                                          </TextField>
                                        </FormControl>
                                      </Tooltip>
                                    </Grid>

                                    <Grid container item xs={12} sm={6}>
                                      <Grid
                                        item
                                        xs={12}
                                        sx={{
                                          color: '#7F7F7F',
                                          backgroundColor: ratesInks.find(
                                            ink =>
                                              ink.id ===
                                              values?.quoteRevision?.quoteLineItem
                                                ?.ratesInkPanel4Color1?.id
                                          )?.hexCode,
                                          border: '1px solid #7F7F7F'
                                        }}
                                      />
                                    </Grid>
                                  </>
                                )}
                              {(values?.quoteRevision?.quoteLineItem
                                ?.toolingPrintPanelsCount ?? 0) >= 4 &&
                                (values?.quoteRevision?.quoteLineItem
                                  ?.toolingColorsCount ?? 0) >= 2 && (
                                  <>
                                    <Grid container item xs={12} sm={6}>
                                      <Tooltip
                                        title={
                                          values.opportunityLastStatus
                                            ?.specialIdentifier ===
                                          'OPPORTUNITY_STATUS_SOLD'
                                            ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                            : OPPORTUNITIES_ADD_EDIT
                                            ? ''
                                            : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                                        }
                                        placement='bottom'
                                      >
                                        <FormControl fullWidth>
                                          <TextField
                                            disabled={
                                              isSubmitting ||
                                              !OPPORTUNITIES_ADD_EDIT ||
                                              values.opportunityLastStatus
                                                ?.specialIdentifier ===
                                                'OPPORTUNITY_STATUS_SOLD'
                                            }
                                            error={Boolean(
                                              getIn(
                                                touched,
                                                'quoteRevision.quoteLineItem.ratesInkPanel4Color2'
                                              ) &&
                                                getIn(
                                                  errors,
                                                  'quoteRevision.quoteLineItem.ratesInkPanel4Color2'
                                                )
                                            )}
                                            fullWidth
                                            helperText={
                                              getIn(
                                                touched,
                                                'quoteRevision.quoteLineItem.ratesInkPanel4Color2'
                                              ) &&
                                              getIn(
                                                errors,
                                                'quoteRevision.quoteLineItem.ratesInkPanel4Color2'
                                              )
                                            }
                                            label='Ink Panel 4, Color 2: GCMI'
                                            name='quoteRevision.quoteLineItem.ratesInkPanel4Color2'
                                            onBlur={handleBlur}
                                            onChange={e => {
                                              setFieldValue(
                                                'quoteRevision.quoteLineItem.ratesInkPanel4Color2',
                                                JSON.parse(e.target.value)
                                              )
                                            }}
                                            select
                                            value={
                                              values?.quoteRevision?.quoteLineItem
                                                ?.ratesInkPanel4Color2
                                                ? JSON.stringify(
                                                    ratesInks.filter(
                                                      ink =>
                                                        ink?.id ===
                                                        values?.quoteRevision
                                                          ?.quoteLineItem
                                                          ?.ratesInkPanel4Color2?.id
                                                    )[0]
                                                  )
                                                : ''
                                            }
                                          >
                                            {ratesInks
                                              ?.sort((a, b) =>
                                                (a?.gcmiColors ?? '').localeCompare(
                                                  b?.gcmiColors ?? ''
                                                )
                                              )
                                              ?.map(ink => (
                                                <MenuItem
                                                  value={JSON.stringify(ink)}
                                                  key={ink?.id ?? ''}
                                                >
                                                  {ink?.gcmiColors ?? ''}
                                                </MenuItem>
                                              ))}
                                          </TextField>
                                        </FormControl>
                                      </Tooltip>
                                    </Grid>

                                    <Grid container item xs={12} sm={6}>
                                      <Grid
                                        item
                                        xs={12}
                                        sx={{
                                          color: '#7F7F7F',
                                          backgroundColor: ratesInks.find(
                                            ink =>
                                              ink.id ===
                                              values?.quoteRevision?.quoteLineItem
                                                ?.ratesInkPanel4Color2?.id
                                          )?.hexCode,
                                          border: '1px solid #7F7F7F'
                                        }}
                                      />
                                    </Grid>
                                  </>
                                )}
                              {(values?.quoteRevision?.quoteLineItem
                                ?.toolingPrintPanelsCount ?? 0) >= 4 &&
                                (values?.quoteRevision?.quoteLineItem
                                  ?.toolingColorsCount ?? 0) >= 3 && (
                                  <>
                                    <Grid container item xs={12} sm={6}>
                                      <Tooltip
                                        title={
                                          values.opportunityLastStatus
                                            ?.specialIdentifier ===
                                          'OPPORTUNITY_STATUS_SOLD'
                                            ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                            : OPPORTUNITIES_ADD_EDIT
                                            ? ''
                                            : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                                        }
                                        placement='bottom'
                                      >
                                        <FormControl fullWidth>
                                          <TextField
                                            disabled={
                                              isSubmitting ||
                                              !OPPORTUNITIES_ADD_EDIT ||
                                              values.opportunityLastStatus
                                                ?.specialIdentifier ===
                                                'OPPORTUNITY_STATUS_SOLD'
                                            }
                                            error={Boolean(
                                              getIn(
                                                touched,
                                                'quoteRevision.quoteLineItem.ratesInkPanel4Color3'
                                              ) &&
                                                getIn(
                                                  errors,
                                                  'quoteRevision.quoteLineItem.ratesInkPanel4Color3'
                                                )
                                            )}
                                            fullWidth
                                            helperText={
                                              getIn(
                                                touched,
                                                'quoteRevision.quoteLineItem.ratesInkPanel4Color3'
                                              ) &&
                                              getIn(
                                                errors,
                                                'quoteRevision.quoteLineItem.ratesInkPanel4Color3'
                                              )
                                            }
                                            label='Ink Panel 4, Color 3: GCMI'
                                            name='quoteRevision.quoteLineItem.ratesInkPanel4Color3'
                                            onBlur={handleBlur}
                                            onChange={e => {
                                              setFieldValue(
                                                'quoteRevision.quoteLineItem.ratesInkPanel4Color3',
                                                JSON.parse(e.target.value)
                                              )
                                            }}
                                            select
                                            value={
                                              values?.quoteRevision?.quoteLineItem
                                                ?.ratesInkPanel4Color3
                                                ? JSON.stringify(
                                                    ratesInks.filter(
                                                      ink =>
                                                        ink?.id ===
                                                        values?.quoteRevision
                                                          ?.quoteLineItem
                                                          ?.ratesInkPanel4Color3?.id
                                                    )[0]
                                                  )
                                                : ''
                                            }
                                          >
                                            {ratesInks
                                              ?.sort((a, b) =>
                                                (a?.gcmiColors ?? '').localeCompare(
                                                  b?.gcmiColors ?? ''
                                                )
                                              )
                                              ?.map(ink => (
                                                <MenuItem
                                                  value={JSON.stringify(ink)}
                                                  key={ink?.id ?? ''}
                                                >
                                                  {ink?.gcmiColors ?? ''}
                                                </MenuItem>
                                              ))}
                                          </TextField>
                                        </FormControl>
                                      </Tooltip>
                                    </Grid>

                                    <Grid container item xs={12} sm={6}>
                                      <Grid
                                        item
                                        xs={12}
                                        sx={{
                                          color: '#7F7F7F',
                                          backgroundColor: ratesInks.find(
                                            ink =>
                                              ink.id ===
                                              values?.quoteRevision?.quoteLineItem
                                                ?.ratesInkPanel4Color3?.id
                                          )?.hexCode,
                                          border: '1px solid #7F7F7F'
                                        }}
                                      />
                                    </Grid>
                                  </>
                                )}
                              {(values?.quoteRevision?.quoteLineItem
                                ?.toolingPrintPanelsCount ?? 0) >= 4 &&
                                (values?.quoteRevision?.quoteLineItem
                                  ?.toolingColorsCount ?? 0) >= 4 && (
                                  <>
                                    <Grid container item xs={12} sm={6}>
                                      <Tooltip
                                        title={
                                          values.opportunityLastStatus
                                            ?.specialIdentifier ===
                                          'OPPORTUNITY_STATUS_SOLD'
                                            ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                            : OPPORTUNITIES_ADD_EDIT
                                            ? ''
                                            : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                                        }
                                        placement='bottom'
                                      >
                                        <FormControl fullWidth>
                                          <TextField
                                            disabled={
                                              isSubmitting ||
                                              !OPPORTUNITIES_ADD_EDIT ||
                                              values.opportunityLastStatus
                                                ?.specialIdentifier ===
                                                'OPPORTUNITY_STATUS_SOLD'
                                            }
                                            error={Boolean(
                                              getIn(
                                                touched,
                                                'quoteRevision.quoteLineItem.ratesInkPanel4Color4'
                                              ) &&
                                                getIn(
                                                  errors,
                                                  'quoteRevision.quoteLineItem.ratesInkPanel4Color4'
                                                )
                                            )}
                                            fullWidth
                                            helperText={
                                              getIn(
                                                touched,
                                                'quoteRevision.quoteLineItem.ratesInkPanel4Color4'
                                              ) &&
                                              getIn(
                                                errors,
                                                'quoteRevision.quoteLineItem.ratesInkPanel4Color4'
                                              )
                                            }
                                            label='Ink Panel 4, Color 4: GCMI'
                                            name='quoteRevision.quoteLineItem.ratesInkPanel4Color4'
                                            onBlur={handleBlur}
                                            onChange={e => {
                                              setFieldValue(
                                                'quoteRevision.quoteLineItem.ratesInkPanel4Color4',
                                                JSON.parse(e.target.value)
                                              )
                                            }}
                                            select
                                            value={
                                              values?.quoteRevision?.quoteLineItem
                                                ?.ratesInkPanel4Color4
                                                ? JSON.stringify(
                                                    ratesInks.filter(
                                                      ink =>
                                                        ink?.id ===
                                                        values?.quoteRevision
                                                          ?.quoteLineItem
                                                          ?.ratesInkPanel4Color4?.id
                                                    )[0]
                                                  )
                                                : ''
                                            }
                                          >
                                            {ratesInks
                                              ?.sort((a, b) =>
                                                (a?.gcmiColors ?? '').localeCompare(
                                                  b?.gcmiColors ?? ''
                                                )
                                              )
                                              ?.map(ink => (
                                                <MenuItem
                                                  value={JSON.stringify(ink)}
                                                  key={ink?.id ?? ''}
                                                >
                                                  {ink?.gcmiColors ?? ''}
                                                </MenuItem>
                                              ))}
                                          </TextField>
                                        </FormControl>
                                      </Tooltip>
                                    </Grid>

                                    <Grid container item xs={12} sm={6}>
                                      <Grid
                                        item
                                        xs={12}
                                        sx={{
                                          color: '#7F7F7F',
                                          backgroundColor: ratesInks.find(
                                            ink =>
                                              ink.id ===
                                              values?.quoteRevision?.quoteLineItem
                                                ?.ratesInkPanel4Color4?.id
                                          )?.hexCode,
                                          border: '1px solid #7F7F7F'
                                        }}
                                      />
                                    </Grid>
                                  </>
                                )}
                            </>
                          )}
                          <Grid item xs={12}>
                            <ExplanationAccordion>
                              This card loads fields dynamically based on the number of
                              print panels and the number of inks. There is a max of 17
                              fields here (Coverage percentage and up to 4 inks per print
                              panel).||If users select Colors (Count) = 2 and Print panels
                              (Count) = 2, there would be fields for "Ink Panel 1, Color
                              1", "Ink Panel 1, Color 2", "Ink Panel 2, Color 1", and "Ink
                              Panel 2, Color 2".
                            </ExplanationAccordion>
                          </Grid>
                        </Grid>
                      </DetailsForm>
                    </Grid>

                    <Grid item xs={12}>
                      <DetailsForm>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            container
                            alignItems='center'
                            justifyContent='space-between'
                          >
                            <Grid item xs={12}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#2780E3', fontWeight: 600 }}
                              >
                                Inks Cost
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              Setup Cost
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              {clearWhenDirty(
                                format(
                                  values.quoteRevision?.quoteLineItem?.inksCostSetupCost,
                                  formats.currency
                                )
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Subtotal:{' '}
                              {clearWhenDirty(
                                format(
                                  values.quoteRevision?.quoteLineItem?.inksCostSubtotal,
                                  formats.currency
                                )
                              )}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <ExplanationAccordion>
                              Ink cost = Ink Cost's "Setup Cost" from the Rates - Global
                              tab.
                            </ExplanationAccordion>
                          </Grid>
                        </Grid>
                      </DetailsForm>
                    </Grid>

                    <Grid item xs={12}>
                      <DetailsForm>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            container
                            alignItems='center'
                            justifyContent='space-between'
                          >
                            <Grid item xs={12}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#2780E3', fontWeight: 600 }}
                              >
                                End Cost Adders Cost
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Item
                            </Typography>
                          </Grid>

                          <Grid container item xs={12} sm={8}>
                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Setup Fee
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Cost per MSF
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Cost per MPCS
                              </Typography>
                            </Grid>
                          </Grid>

                          {values.quoteRevision?.quoteLineItem?.quoteEndCostAdders?.map(
                            endCostAdder => (
                              <>
                                <Grid item xs={12} sm={4}>
                                  <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                    {clearWhenDirty(format(endCostAdder.adder))}
                                  </Typography>
                                </Grid>

                                <Grid container item xs={12} sm={8}>
                                  <Grid item xs={12} sm={4}>
                                    <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                      {clearWhenDirty(
                                        format(endCostAdder.adderSetup, formats.currency)
                                      )}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={12} sm={4}>
                                    <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                      {endCostAdder.costPerMsf
                                        ? clearWhenDirty(
                                            format(
                                              endCostAdder.costPerMsf,
                                              formats.currency
                                            )
                                          )
                                        : 'N/A'}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={12} sm={4}>
                                    <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                      {endCostAdder.costPerMpcs
                                        ? clearWhenDirty(
                                            format(
                                              endCostAdder.costPerMpcs,
                                              formats.currency
                                            )
                                          )
                                        : 'N/A'}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </>
                            )
                          )}

                          <Grid item xs={12} sm={4}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Subtotal
                            </Typography>
                          </Grid>

                          <Grid container item xs={12} sm={8}>
                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.endCostAddersCostSubtotalSetupFee,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.endCostAddersCostSubtotalCostPerMsf,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.endCostAddersCostSubtotalCostPerMpcs,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <ExplanationAccordion>
                              Setup Fee = Adder Setup for the selected End Cost Adder from
                              Rates. || Cost per MSF = Adder Per MSF for the selected End
                              Cost Adder from Rates. || Cost per MPCS = Cost per MSF *
                              Gross SQFT from Sheet Dimensions card.
                            </ExplanationAccordion>
                          </Grid>
                        </Grid>
                      </DetailsForm>
                    </Grid>

                    <Grid item xs={12}>
                      <DetailsForm>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            container
                            alignItems='center'
                            justifyContent='space-between'
                          >
                            <Grid item xs={12}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#2780E3', fontWeight: 600 }}
                              >
                                Variable Costs
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Item
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Input
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Cost
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Cost per MSF
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Cost per MPCS
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              Labor Cost
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              N/A
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              {clearWhenDirty(
                                format(
                                  values.quoteRevision?.quoteLineItem
                                    ?.variableCostsLaborCost,
                                  formats.currency
                                )
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              {clearWhenDirty(
                                format(
                                  values.quoteRevision?.quoteLineItem
                                    ?.variableCostsLaborCostPerMsf,
                                  formats.currency
                                )
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              {clearWhenDirty(
                                format(
                                  values.quoteRevision?.quoteLineItem
                                    ?.variableCostsLaborCostPerMpcs,
                                  formats.currency
                                )
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              Pieces Per Hour
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <TextField
                              disabled={
                                isSubmitting ||
                                !OPPORTUNITIES_ADD_EDIT ||
                                values.opportunityLastStatus?.specialIdentifier ===
                                  'OPPORTUNITY_STATUS_SOLD'
                              }
                              error={Boolean(
                                getIn(
                                  touched,
                                  'quoteRevision.quoteLineItem.variableCostsJobCenterPiecePerHour'
                                ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.variableCostsJobCenterPiecePerHour'
                                  )
                              )}
                              fullWidth
                              helperText={
                                getIn(
                                  touched,
                                  'quoteRevision.quoteLineItem.variableCostsJobCenterPiecePerHour'
                                ) &&
                                getIn(
                                  errors,
                                  'quoteRevision.quoteLineItem.variableCostsJobCenterPiecePerHour'
                                )
                              }
                              label='Pieces Per Hour'
                              name='quoteRevision.quoteLineItem.variableCostsJobCenterPiecePerHour'
                              onBlur={handleBlur}
                              onChange={e => {
                                if (e.target.value) {
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.variableCostsJobCenterPiecePerHour',
                                    e.target.value
                                  )
                                } else {
                                  setFieldValue(
                                    'quoteRevision.quoteLineItem.variableCostsJobCenterPiecePerHour',
                                    undefined
                                  )
                                }
                              }}
                              InputProps={{
                                inputComponent: DecimalFormat as any
                              }}
                              value={
                                values.quoteRevision?.quoteLineItem
                                  ?.variableCostsJobCenterPiecePerHour || ''
                              }
                            />
                            {/* <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              {clearWhenDirty(
                                values.quoteRevision?.quoteLineItem
                                  ?.variableCostsJobCenterPiecePerHour
                              )}
                            </Typography> */}
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              N/A
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              N/A
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              N/A
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              Bander Labor
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              N/A
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              {clearWhenDirty(
                                format(
                                  values.quoteRevision?.quoteLineItem
                                    ?.variableCostsBanderLaborCost,
                                  formats.currency
                                )
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              {clearWhenDirty(
                                format(
                                  values.quoteRevision?.quoteLineItem
                                    ?.variableCostsBanderLaborCostPerMsf,
                                  formats.currency
                                )
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              {clearWhenDirty(
                                format(
                                  values.quoteRevision?.quoteLineItem
                                    ?.variableCostsBanderLaborCostPerMpcs,
                                  formats.currency
                                )
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              Material Handling
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              N/A
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              {clearWhenDirty(
                                format(
                                  values.quoteRevision?.quoteLineItem
                                    ?.variableCostsMaterialHandlingCost,
                                  formats.currency
                                )
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              {clearWhenDirty(
                                format(
                                  values.quoteRevision?.quoteLineItem
                                    ?.variableCostsMaterialHandlingCostPerMsf,
                                  formats.currency
                                )
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              {clearWhenDirty(
                                format(
                                  values.quoteRevision?.quoteLineItem
                                    ?.variableCostsMaterialHandlingCostPerMpcs,
                                  formats.currency
                                )
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              Pallet Cost (Bundle)
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              {clearWhenDirty(
                                format(
                                  values.quoteRevision?.quoteLineItem
                                    ?.variableCostsPalletCostBundleInput,
                                  formats.currency
                                )
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              {clearWhenDirty(
                                format(
                                  values.quoteRevision?.quoteLineItem
                                    ?.variableCostsPalletCostBundleCost,
                                  formats.currency
                                )
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              N/A
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              {clearWhenDirty(
                                format(
                                  values.quoteRevision?.quoteLineItem
                                    ?.variableCostsPalletCostBundleCostPerMpcs,
                                  formats.currency
                                )
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              Penalty
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.variableCostsPenaltyInput'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.variableCostsPenaltyInput'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.variableCostsPenaltyInput'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.variableCostsPenaltyInput'
                                  )
                                }
                                label='Penalty'
                                name='quoteRevision.quoteLineItem.variableCostsPenaltyInput'
                                onBlur={handleBlur}
                                onChange={e => {
                                  if (e.target.value) {
                                    setFieldValue(
                                      'quoteRevision.quoteLineItem.variableCostsPenaltyInput',
                                      e.target.value
                                    )
                                  } else {
                                    setFieldValue(
                                      'quoteRevision.quoteLineItem.variableCostsPenaltyInput',
                                      undefined
                                    )
                                  }
                                }}
                                InputProps={{
                                  inputComponent: DecimalFormat as any
                                }}
                                value={
                                  values.quoteRevision?.quoteLineItem
                                    ?.variableCostsPenaltyInput || ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              {clearWhenDirty(
                                format(
                                  values.quoteRevision?.quoteLineItem
                                    ?.variableCostsPenaltyCost,
                                  formats.currency
                                )
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              N/A
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              {clearWhenDirty(
                                format(
                                  values.quoteRevision?.quoteLineItem
                                    ?.variableCostsPenaltyCostPerMpcs,
                                  formats.currency
                                )
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              Warehousing %
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.variableCostsWarehousingPercentInput'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.variableCostsWarehousingPercentInput'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.variableCostsWarehousingPercentInput'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.variableCostsWarehousingPercentInput'
                                  )
                                }
                                label='Warehousing %'
                                name='quoteRevision.quoteLineItem.variableCostsWarehousingPercentInput'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: DecimalFormat as any
                                }}
                                value={
                                  values.quoteRevision?.quoteLineItem
                                    ?.variableCostsWarehousingPercentInput || ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              {clearWhenDirty(
                                format(
                                  values.quoteRevision?.quoteLineItem
                                    ?.variableCostsWarehousingPercentCost,
                                  formats.currency
                                )
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              N/A
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              {clearWhenDirty(
                                format(
                                  values.quoteRevision?.quoteLineItem
                                    ?.variableCostsWarehousingPercentCostPerMpcs,
                                  formats.currency
                                )
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              Misc. Cost
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.variableCostsMiscellaneousCostInput'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.variableCostsMiscellaneousCostInput'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.variableCostsMiscellaneousCostInput'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.variableCostsMiscellaneousCostInput'
                                  )
                                }
                                label='Misc. Cost'
                                name='quoteRevision.quoteLineItem.variableCostsMiscellaneousCostInput'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: DecimalFormat as any
                                }}
                                value={
                                  values.quoteRevision?.quoteLineItem
                                    ?.variableCostsMiscellaneousCostInput || ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              {clearWhenDirty(
                                format(
                                  values.quoteRevision?.quoteLineItem
                                    ?.variableCostsMiscellaneousCostCost,
                                  formats.currency
                                )
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              N/A
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              {clearWhenDirty(
                                format(
                                  values.quoteRevision?.quoteLineItem
                                    ?.variableCostsMiscellaneousCostCostPerMpcs,
                                  formats.currency
                                )
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              Freight
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <Tooltip
                              title={
                                values.opportunityLastStatus?.specialIdentifier ===
                                'OPPORTUNITY_STATUS_SOLD'
                                  ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                  : OPPORTUNITIES_ADD_EDIT
                                  ? ''
                                  : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                              }
                              placement='bottom'
                            >
                              <TextField
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                error={Boolean(
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.variableCostsFreightInput'
                                  ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.variableCostsFreightInput'
                                    )
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    'quoteRevision.quoteLineItem.variableCostsFreightInput'
                                  ) &&
                                  getIn(
                                    errors,
                                    'quoteRevision.quoteLineItem.variableCostsFreightInput'
                                  )
                                }
                                label='Freight'
                                name='quoteRevision.quoteLineItem.variableCostsFreightInput'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                InputProps={{
                                  inputComponent: DecimalFormat as any
                                }}
                                value={
                                  values.quoteRevision?.quoteLineItem
                                    ?.variableCostsFreightInput || ''
                                }
                              />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              {clearWhenDirty(
                                format(
                                  values.quoteRevision?.quoteLineItem
                                    ?.variableCostsFreightCost,
                                  formats.currency
                                )
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              N/A
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                              {clearWhenDirty(
                                format(
                                  values.quoteRevision?.quoteLineItem
                                    ?.variableCostsFreightCostPerMpcs,
                                  formats.currency
                                )
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <Divider />
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Subtotal
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              N/A
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              {clearWhenDirty(
                                format(
                                  values.quoteRevision?.quoteLineItem
                                    ?.variableCostsSubtotalCost,
                                  formats.currency
                                )
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              {clearWhenDirty(
                                format(
                                  values.quoteRevision?.quoteLineItem
                                    ?.variableCostsSubtotalCostPerMsf,
                                  formats.currency
                                )
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              {clearWhenDirty(
                                format(
                                  values.quoteRevision?.quoteLineItem
                                    ?.variableCostsSubtotalCostPerMpcs,
                                  formats.currency
                                )
                              )}
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <ExplanationAccordion>
                              || Piece Per Hour input field is auto populated upon saving
                              when the Job Center dropdown has been changed. The value
                              that is auto populated is the “Piece Per Hour” value from
                              Global Rates for the selected Job Center (Big Jack or Little
                              Jack). “Piece Per Hour” is impacted by difficulty of running
                              a particular piece of corrugate through a job center and
                              this field is an input for tribal knowledge data entry. ||
                              Labor cost for job = ((Customer order quantity / (pieces per
                              hour * number of outs on the assembly card)) * cost per hour
                              from global rates for the assigned job center) + setup cost.
                              || Bander cost = (Customer order quantity / finished good
                              unit quantity per bundle) * bander "cost" in rates under
                              global tab. || Material handling cost = Material handing
                              cost in rates under global tab. || Pallet cost (for this
                              quote) = Selected bale type's cost from global rates *
                              number of bundles from bundle configuration on assembly
                              card. || Penalty cost = Input value. || Warehousing cost =
                              Warehousing % * warehousing cost in rates under global tab.
                              || Misc. cost = Input value. || Freight = Input value. ||
                              “Customer Order Quantity” and “Finished good unit quantity
                              per bundle” are input fields on the Assembly card. || Cost
                              per MSF = (Cost / (Customer order quantity * gross SQFT)) *
                              1000. || Cost per MPCS = (Cost / Customer order quantity) *
                              1000. || Fields with N/A in the Cost per MSF column will
                              always have this label (N/A).
                            </ExplanationAccordion>
                          </Grid>
                        </Grid>
                      </DetailsForm>
                    </Grid>

                    <Grid item xs={12}>
                      <DetailsForm>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            container
                            alignItems='center'
                            justifyContent='space-between'
                          >
                            <Grid item xs={12}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#2780E3', fontWeight: 600 }}
                              >
                                Summary
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid container item xs={12}>
                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Item
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Setup Fee
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Cost per MSF
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Cost per MPCS
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid container item xs={12}>
                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                Paper Cost
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.paperCostSubtotalSetupFee,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.paperCostSubtotalCostPerMsf,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.paperCostSubtotalCostPerMpcs,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid container item xs={12}>
                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                Paper Adders Cost
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.paperAddersCostSubtotalSetupFee,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.paperAddersCostSubtotalCostPerMsf,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.paperAddersCostSubtotalCostPerMpcs,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid container item xs={12}>
                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                Sesame Tape ($
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.sesameTapeSubtotal,
                                    formats.currencyFourDecimalPlaces
                                  )
                                )}{' '}
                                each)
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                N/A
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.sesameTapeSubtotalCostPerMsf,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.sesameTapeSubtotalCostPerMpcs,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid container item xs={12}>
                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                Blend Cost
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.blendCostSubtotalSetupFee,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.blendCostSubtotalCostPerMsf,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.blendCostSubtotalCostPerMpcs,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid container item xs={12}>
                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                Assembly Cost
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.assemblyCostSubtotalSetupFee,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.assemblyCostSubtotalCostPerMsf,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.assemblyCostSubtotalCostPerMpcs,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid container item xs={12}>
                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                Scoring Cost
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.scoringCostSubtotalSetupFee,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.scoringCostSubtotalCostPerMsf,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.scoringCostSubtotalCostPerMpcs,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid container item xs={12}>
                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                End Cost Adders Cost
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.endCostAddersCostSubtotalSetupFee,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.endCostAddersCostSubtotalCostPerMsf,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.endCostAddersCostSubtotalCostPerMpcs,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid container item xs={12}>
                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Paper Subtotal
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.summaryPaperSubtotalSetupFee,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.summaryPaperSubtotalCostPerMsf,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.summaryPaperSubtotalCostPerMpcs,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <Divider />
                          </Grid>

                          <Grid container item xs={12}>
                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Paper Totals
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                N/A
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                N/A
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.summaryPaperTotalsCostPerMpcs,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <Divider />
                          </Grid>

                          <Grid container item xs={12}>
                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Item
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Setup Fee
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Cost per MSF
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Cost per MPCS
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid container item xs={12}>
                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                Inks Cost
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem?.inksCostSubtotal,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                N/A
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                N/A
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid container item xs={12}>
                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                Variable Cost
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                N/A
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                N/A
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.variableCostsSubtotalCostPerMpcs,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid container item xs={12}>
                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Manufacturing Subtotal
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.summaryManufacturingSubtotalSetupFee,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                N/A
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.summaryManufacturingSubtotalCostPerMpcs,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <Divider />
                          </Grid>

                          <Grid container item xs={12}>
                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Variable Totals
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                N/A
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                N/A
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.summaryVariableTotalsCostPerMpcs,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <Divider />
                          </Grid>

                          <Grid container item xs={12}>
                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Paper & Variable Totals
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                N/A
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                N/A
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.summaryPaperAndVariableTotalsCostPerMpcs,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <Divider />
                          </Grid>

                          <Grid container item xs={12}>
                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Item
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Setup Fee
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Cost per MSF
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Cost per MPCS
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid container item xs={12}>
                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                Waste{' '}
                                {'(' +
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.summaryWastePercentInput,
                                    formats.percent
                                  ) +
                                  ')'}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                N/A
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                N/A
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.summaryWastePercentCostPerMpcs,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid container item xs={12}>
                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Waste Subtotal
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                N/A
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                N/A
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.summaryWastePercentSubtotalCostPerMpcs,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <Divider />
                          </Grid>

                          <Grid container item xs={12}>
                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Paper, Variable & Waste Totals
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                N/A
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                N/A
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.summaryPaperVariableAndWasteTotalsCostPerMpcs,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <Divider />
                          </Grid>

                          <Grid container item xs={12}>
                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                SGA{' '}
                                {'(' +
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.summarySgaPercentInput,
                                    formats.percent
                                  ) +
                                  ')'}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                N/A
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                N/A
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.summarySgaPercentCostPerMpcs,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid container item xs={12}>
                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                Markup
                              </Typography>
                            </Grid>

                            <Grid container item xs={12} sm={3}>
                              <Tooltip
                                title={
                                  values.opportunityLastStatus?.specialIdentifier ===
                                  'OPPORTUNITY_STATUS_SOLD'
                                    ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                    : OPPORTUNITIES_ADD_EDIT
                                    ? ''
                                    : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                                }
                                placement='bottom'
                              >
                                <TextField
                                  disabled={
                                    isSubmitting ||
                                    !OPPORTUNITIES_ADD_EDIT ||
                                    values.opportunityLastStatus?.specialIdentifier ===
                                      'OPPORTUNITY_STATUS_SOLD'
                                  }
                                  error={Boolean(
                                    getIn(
                                      touched,
                                      'quoteRevision.quoteLineItem.summaryMarkupPercentInput'
                                    ) &&
                                      getIn(
                                        errors,
                                        'quoteRevision.quoteLineItem.summaryMarkupPercentInput'
                                      )
                                  )}
                                  fullWidth
                                  helperText={
                                    getIn(
                                      touched,
                                      'quoteRevision.quoteLineItem.summaryMarkupPercentInput'
                                    ) &&
                                    getIn(
                                      errors,
                                      'quoteRevision.quoteLineItem.summaryMarkupPercentInput'
                                    )
                                  }
                                  label='Markup'
                                  name='quoteRevision.quoteLineItem.summaryMarkupPercentInput'
                                  onBlur={handleBlur}
                                  onChange={e => {
                                    if (e.target.value) {
                                      setFieldValue(
                                        'quoteRevision.quoteLineItem.summaryMarkupPercentInput',
                                        e.target.value
                                      )
                                    } else {
                                      setFieldValue(
                                        'quoteRevision.quoteLineItem.summaryMarkupPercentInput',
                                        undefined
                                      )
                                    }
                                  }}
                                  InputProps={{
                                    inputComponent: DecimalFormat as any
                                  }}
                                  value={
                                    values.quoteRevision?.quoteLineItem
                                      ?.summaryMarkupPercentInput || ''
                                  }
                                />
                              </Tooltip>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                N/A
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.summaryMarkupPercentCostPerMpcs,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid container item xs={12}>
                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                Total Sell Price
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                N/A
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                N/A
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', fontWeight: 600 }}
                              >
                                {clearWhenDirty(
                                  format(
                                    values.quoteRevision?.quoteLineItem
                                      ?.summaryTotalSellPriceCostPerMpcs,
                                    formats.currency
                                  )
                                )}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <ExplanationAccordion>
                              ||Please note that the values in the Summary table are
                              calculated using formulas with numerical precision extending
                              to four decimal places, including intermediate calculations.
                              However, the values displayed in the user interface are
                              rounded to two decimal places. || “Sesame Tape” row values
                              are calculated using different formulas from any other row
                              on this card: || - Sesame Tape ($X.XX each) = Subtotal from
                              the Sesame Tape card. || - Sesame Tape Setup Fee = Always
                              N/A. || - Sesame Tape’s Cost Per MPCS = Sesame Tape ($X.XX
                              each) * 1000 || - Sesame Tape’s Cost Per MSF = Sesame Tape’s
                              Cost Per MPCS / Gross SQFT. || Paper Totals = Paper
                              Subtotal’s Setup Fee + Paper Subtotal’s Cost per MPCS. ||
                              Variable Totals = Manufacturing Subtotal's Setup Fee +
                              Manufacturing Subtotal’s Cost per MPCS. || Paper & Variable
                              Totals = Paper Totals + Variable Totals. || Waste % is
                              pulled from Global Rates. It includes paper waste and
                              manufacturing waste. || Waste = Paper & Variable Totals *
                              Waste % from Global Rates. || Paper, Variable & Waste Totals
                              = Paper & Variable Totals + Waste Subtotal. || SGA = Paper,
                              Variable & Waste Totals * SGA % from Global Rates. || Markup
                              = (Paper, Variable & Waste Totals + SGA Amount) * entered
                              Markup percentage. || Total Sell Price = Paper, Variable &
                              Waste Totals + SGA + Markup.
                            </ExplanationAccordion>
                          </Grid>
                        </Grid>
                      </DetailsForm>
                    </Grid>

                    <Grid item xs={12}>
                      <DetailsForm>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            container
                            alignItems='center'
                            justifyContent='space-between'
                          >
                            <Grid item xs={12}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#2780E3', fontWeight: 600 }}
                              >
                                Check Values
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid container item xs={12}>
                            <Grid container item xs={12}>
                              <Grid item xs={12} sm={3}>
                                <Typography
                                  variant='body1'
                                  sx={{ color: '#7F7F7F', fontWeight: 600 }}
                                >
                                  Order Value
                                </Typography>
                              </Grid>

                              <Grid item xs={12} sm={3}>
                                <Typography
                                  variant='body1'
                                  sx={{ color: '#7F7F7F', fontWeight: 600 }}
                                >
                                  X Board
                                </Typography>
                              </Grid>

                              <Grid item xs={12} sm={3}>
                                <Typography
                                  variant='body1'
                                  sx={{ color: '#7F7F7F', fontWeight: 600 }}
                                >
                                  Total Contributions $
                                </Typography>
                              </Grid>

                              <Grid item xs={12} sm={3}>
                                <Typography
                                  variant='body1'
                                  sx={{ color: '#7F7F7F', fontWeight: 600 }}
                                >
                                  Contributions %
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid container item xs={12}>
                              <Grid item xs={12} sm={3}>
                                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                  {clearWhenDirty(
                                    format(
                                      values.quoteRevision?.quoteLineItem
                                        ?.checkValuesOrderValue,
                                      formats.currency
                                    )
                                  )}
                                </Typography>
                              </Grid>

                              <Grid item xs={12} sm={3}>
                                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                  {clearWhenDirty(
                                    format(
                                      values.quoteRevision?.quoteLineItem
                                        ?.checkValuesXBoard,
                                      formats.currency
                                    )
                                  )}
                                </Typography>
                              </Grid>

                              <Grid item xs={12} sm={3}>
                                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                  {clearWhenDirty(
                                    format(
                                      values.quoteRevision?.quoteLineItem
                                        ?.checkValuesTotalContributions,
                                      formats.currency
                                    )
                                  )}
                                </Typography>
                              </Grid>

                              <Grid item xs={12} sm={3}>
                                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                  {clearWhenDirty(
                                    format(
                                      values.quoteRevision?.quoteLineItem
                                        ?.checkValuesContributionsPercent,
                                      formats.percent
                                    )
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid container item xs={12}>
                            <Grid container item xs={12}>
                              <Grid item xs={12} sm={3}>
                                <Typography
                                  variant='body1'
                                  sx={{ color: '#7F7F7F', fontWeight: 600 }}
                                >
                                  Original Cost Per Piece
                                </Typography>
                              </Grid>

                              <Grid item xs={12} sm={3}>
                                <Typography
                                  variant='body1'
                                  sx={{ color: '#7F7F7F', fontWeight: 600 }}
                                >
                                  Cost Per Piece
                                </Typography>
                              </Grid>

                              <Grid item xs={12} sm={3}>
                                <Typography
                                  variant='body1'
                                  sx={{ color: '#7F7F7F', fontWeight: 600 }}
                                >
                                  Original Extended Cost
                                </Typography>
                              </Grid>

                              <Grid item xs={12} sm={3}>
                                <Typography
                                  variant='body1'
                                  sx={{ color: '#7F7F7F', fontWeight: 600 }}
                                >
                                  Extended Cost
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid container item xs={12}>
                              <Grid item xs={12} sm={3}>
                                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                  {clearWhenDirty(
                                    format(
                                      values.quoteRevision?.quoteLineItem
                                        ?.checkValuesOriginalCostPerPiece,
                                      formats.currency,
                                      'N/A'
                                    )
                                  )}
                                </Typography>
                              </Grid>

                              <Grid item xs={12} sm={3}>
                                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                  {clearWhenDirty(
                                    format(
                                      values.quoteRevision?.quoteLineItem
                                        ?.checkValuesCostPerPiece,
                                      formats.currency
                                    )
                                  )}
                                </Typography>
                              </Grid>

                              <Grid item xs={12} sm={3}>
                                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                  {clearWhenDirty(
                                    format(
                                      values.quoteRevision?.quoteLineItem
                                        ?.checkValuesOriginalExtendedCost,
                                      formats.currency,
                                      'N/A'
                                    )
                                  )}
                                </Typography>
                              </Grid>

                              <Grid item xs={12} sm={3}>
                                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                  {clearWhenDirty(
                                    format(
                                      values.quoteRevision?.quoteLineItem
                                        ?.checkValuesExtendedCost,
                                      formats.currency
                                    )
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid container item xs={12}>
                            <Grid container item xs={12}>
                              <Grid item xs={12} sm={3}>
                                <Typography
                                  variant='body1'
                                  sx={{ color: '#7F7F7F', fontWeight: 600 }}
                                >
                                  Sell Price % Board
                                </Typography>
                              </Grid>

                              <Grid item xs={12} sm={3}>
                                <Typography
                                  variant='body1'
                                  sx={{ color: '#7F7F7F', fontWeight: 600 }}
                                >
                                  Vendor Order Quantity
                                </Typography>
                              </Grid>

                              <Grid item xs={12} sm={3}>
                                <Typography
                                  variant='body1'
                                  sx={{ color: '#7F7F7F', fontWeight: 600 }}
                                >
                                  Total Paper Cost Per Out
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid container item xs={12}>
                              <Grid item xs={12} sm={3}>
                                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                  {clearWhenDirty(
                                    format(
                                      values.quoteRevision?.quoteLineItem
                                        ?.checkValuesSellPricePercentBoard,
                                      formats.percent
                                    )
                                  )}
                                </Typography>
                              </Grid>

                              <Grid item xs={12} sm={3}>
                                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                  {clearWhenDirty(
                                    format(
                                      values.quoteRevision?.quoteLineItem
                                        ?.checkValuesVendorOrderQuantity
                                    )
                                  )}
                                </Typography>
                              </Grid>

                              <Grid item xs={12} sm={3}>
                                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                  {clearWhenDirty(
                                    format(
                                      values.quoteRevision?.quoteLineItem
                                        ?.checkValuesTotalPaperCostPerOut,
                                      formats.currency
                                    )
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <ExplanationAccordion>
                              || Order Value = Customer Order Quantity * (Total Sell Price
                              / 1000) / Number of Outs. || X Board = Total Sell Price /
                              Total Paper Post per MPCS. || Total Contribution $ = Total
                              Sale Price – Paper, Variable & Waste Totals. ||
                              Contributions % = Total Contribution $ / Total Sale Price.
                              || Sell Price % Board = Paper Costs Cost Per MPCS subtotal /
                              Total Sell Price. || Cost per Piece = (Total Sell Price /
                              1000) / Outs. || Extended Cost = Cost Per Piece * Customer
                              Order Quantity. || Vendor Order Quantity = Customer Order
                              Quantity / Outs. || Total Paper Cost per Out = Total Paper
                              Cost / Outs.
                            </ExplanationAccordion>
                          </Grid>
                        </Grid>
                      </DetailsForm>
                    </Grid>

                    <Grid item xs={12}>
                      <DetailsForm>
                        <DialogActions sx={{ mb: 2, mr: 2 }}>
                          <Button
                            variant='text'
                            color='secondary'
                            onClick={() => {
                              navigate('/opportunities')
                            }}
                          >
                            BACK
                          </Button>

                          <Tooltip
                            title={
                              values.opportunityLastStatus?.specialIdentifier ===
                              'OPPORTUNITY_STATUS_SOLD'
                                ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                                : OPPORTUNITIES_ADD_EDIT
                                ? ''
                                : EDIT_PERMISSION_UNEDITABLE_MESSAGE
                            }
                            placement='bottom'
                          >
                            <span>
                              <Button
                                disabled={
                                  isSubmitting ||
                                  !OPPORTUNITIES_ADD_EDIT ||
                                  values.opportunityLastStatus?.specialIdentifier ===
                                    'OPPORTUNITY_STATUS_SOLD'
                                }
                                onClick={() => {
                                  // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
                                  // Otherwise, sometimes the field errors won't display even though the validation throws them
                                  validateForm().then(errors => {
                                    const errorKeys = Object.keys(errors)
                                    if (errorKeys.length === 0) {
                                      submitForm()
                                    } else {
                                      setTouched(setNestedObjectValues(errors, true))
                                      showFormErrorsPrompt(errors)
                                    }
                                  })
                                }}
                                variant='contained'
                              >
                                SAVE
                              </Button>
                            </span>
                          </Tooltip>
                        </DialogActions>

                        <Grid item xs={12}>
                          <ExplanationAccordion development>
                            Clicking save or cancel takes user back to the top of this tab
                          </ExplanationAccordion>
                        </Grid>
                      </DetailsForm>
                    </Grid>
                  </Grid>
                </DetailsTab>
              </Details>

              <UnsavedChangesPrompt when={dirty} />
            </>
          )
        }}
      </Formik>
    </Layout>
  )
}
